// import { VideoRoomMonitor } from '@twilio/video-room-monitor';
import {
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useRef,
    useState,
  } from 'react';
  import { useDispatch } from 'react-redux';
  import { useHistory } from 'react-router-dom';
  import { toast } from 'react-toastify';
  import Video, {
    Participant,
    RemoteParticipant,
    Room
  } from 'twilio-video';
  import {
    AppConstants,
    AppRouteUi,
    CallStatusType,
    CallType,
    VideoViewType
  } from '../../configs';
  import {
    ActionUi,
    ActionVideoCall,
    ChannelContext,
    StorageAuth,
    useAppSelector,
  } from '../../core';
//   import { twilioParticipantHelper } from '../../helpers';
  import { IParticipantsInfo, ISelectedParticipantInfo } from '../../models';
  import { logger } from '../../utils';
  import { useCountdown, useFunctionalityCalls } from '../hooks';
  import { VRIAppStateType } from '../../core';
  
//   const { stopAllTrack } = twilioParticipantHelper();
  
  interface IUseVideoCallProps {
    roomId: string;
    callType: CallType.Video | CallType.VideoConference;
    isVideoConferenceCall: boolean;
    isOperatorRequired: boolean;
    operatorId: string;
  }
  
  export function useVideoCall({
    callType,
    roomId,
    isOperatorRequired,
    isVideoConferenceCall,
    operatorId,
  }: IUseVideoCallProps) {
    const [room, setRoom] = useState<Room | null>(null);
    const [remoteParticipants, setRemoteParticipants] = useState<
      RemoteParticipant[]
    >([]);
    const [showChat, setShowChat] = useState(false);
    const [viewType, setViewType] = useState(VideoViewType.GridView);
    const [showHangupModal, setShowHangupModal] = useState(false);
    const [isSharing, setIsSharing] = useState(false);
    const [isVideoEnabled, setIsVideoEnabled] = useState(true);
    const [isAudioEnabled, setIsAudioEnabled] = useState(true);
    const [participantsInfo, setParticipantsInfo] = useState(
      {} as IParticipantsInfo
    );
    const [selectedParticipantInfo, setSelectedParticipantInfo] =
      useState<ISelectedParticipantInfo | null>(null);
    const appData = useAppSelector((state: VRIAppStateType) => ({
      VRIToken: state.auth.VRItoken,
      callStatus: state.videoCall.CallStatus,
      userRole: state.auth.profile?.userRole,
      addParticipant: state.auth.menuSettings?.callSettings?.addParticipant,
      callerId: state.ui.callerId,
      receiverId: state.ui.receiverId,
      chatSid: state.ui.chatSid,
      twilioDeviceStatus: state.common,
      callSummaryInfo: state.videoCall.callSummary,
      tracks: state.videoCall.tracks,
    }));
    const { setVideoChatMessageCount } = useContext(ChannelContext);
    const { onUpdate } = useFunctionalityCalls();
    const dispatch = useDispatch();
    const history = useHistory();
    const {
      seconds: countdownSeconds,
      isRunning: isCountdownRunning,
      startCountdown,
      isFinished: isCountdownFinished,
      endCountdown,
    } = useCountdown();
    const havePendingCountdownRef = useRef(false);
    const haveOngoingCallRef = useRef(false);
  
    const homePath = useMemo(() => {
      if (appData.userRole === AppConstants.UserRoles.Operator) {
        return AppRouteUi.CallLog.Root;
      } else if (appData.userRole === AppConstants.UserRoles.Consumer) {
        return AppRouteUi.Consumer.Root;
      } else {
        return AppRouteUi.Home.Root;
      }
    }, [appData.userRole]);
  
    const resetCallStatus = useCallback(() => {
      //some of these need to be deleted since they are twilio related
      dispatch(ActionUi.SetCallerId(''));
      // dispatch(ActionUi.SetRoomId(''));
      dispatch(ActionUi.SetReceiverId(''));
      dispatch(ActionUi.SetChatSid(''));
      dispatch(ActionUi.SetReceiverDeviceId(''));
      dispatch(ActionUi.SetLanguageName(''));
      dispatch(ActionUi.SetCallerDeviceId(''));
      dispatch(ActionUi.SetCallType(CallType.None));

      //added for Zoom
      dispatch(ActionUi.SetjwtToken(''));
      dispatch(ActionUi.SetSessionName(''));

      setVideoChatMessageCount(0);
      setShowHangupModal(false);
      dispatch(ActionVideoCall.setOperatorStatus(false));
    }, [dispatch, setVideoChatMessageCount]);
  
    const getRedirectionPath = useCallback(() => {
      if (appData.userRole === AppConstants.UserRoles.Consumer) {
        return AppRouteUi.VideoConference.Rejoin.Load(roomId);
      } else if (appData.userRole === AppConstants.UserRoles.Operator) {
        return AppRouteUi.CallLog.Root;
      } else {
        return AppRouteUi.Home.Root;
      }
    }, [appData.userRole, roomId]);
  
    
    /**************************************here */
    const callHangupHandler = useCallback(() => {
      logger({ message: '[Room]: Hanging Call' });

      const { userRole } = appData;
      const { Consumer, User, CompanyAdmin, CompanyFinance } =
        AppConstants.UserRoles;

      console.log('consoling from callHangupHandler');
  
      //   stopAllTrack(room!);
        room?.disconnect();
      resetCallStatus();

      console.log('consoling from callHangupHandler');
      dispatch(
        ActionVideoCall.SetCallStatus({
          prev: CallStatusType.Established,
          current: CallStatusType.Completed,
        })
      );

      console.log('consoling from callHangupHandler');
      if (
        isVideoConferenceCall &&
        !isOperatorRequired &&
        (userRole === Consumer ||
          userRole === User ||
          userRole === CompanyAdmin ||
          userRole === CompanyFinance)
      ) {
        console.log('consoling from callHangupHandler - we are one of the specified user roles (consumer, user, companyAdmin, CompanyFinance');
        console.log('the redirectionPath is: '+ getRedirectionPath());
        history.push({
          pathname: getRedirectionPath(),
          state: {
            callId: userRole !== Consumer ? roomId : null,
          },
        });
      }
    }, [
      appData,
      dispatch,
      history,
      isOperatorRequired,
      isVideoConferenceCall,
      room,
      roomId,
      resetCallStatus,
      getRedirectionPath,
    ]);
  
    const callOperator = () => {
      logger({
        message: '[Room]: Trying to invite predefined operator: ',
        data: operatorId,
      });
      onUpdate(roomId, {
        callStatus: CallStatusType.ParticipantInvited,
        callType: callType,
        eventTime: new Date().toISOString(),
        isCallStarted: false,
        newParticipantId: operatorId,
        callerUserId: appData.callerId,
        chatSid: appData.chatSid,
      }).then((data) => {
        logger({
          message: '[Room]: Inviting Operator API response: ',
          data: data,
        });
        if (!data?.isSuccess) {
          toast.error(data?.message);
          return;
        }
        toast.info('Inviting Operator');
      });
    };
  
    // const handleParticipantClick = useCallback(
    //   (participant: Participant, type: 'Remote' | 'Local') => {
    //     setSelectedParticipantInfo({ participant, type });
    //   },
    //   []
    // );
  
    const connectToRoom = useCallback(
      async ({ token, roomId }: { token: string; roomId: string }) => {
        try {
          logger({ message: '[Room]: Creating Room' });
          const room = await Video.connect(token, {
            name: roomId,
            networkQuality: { local: 1, remote: 1 },
            tracks: appData.tracks!,
          });
          // VideoRoomMonitor.registerVideoRoom(room);
          logger({ message: `[Room]: Call Id: ${roomId}` });
          logger({ message: `[Room]: Call sid: ${room.sid}` });
          setRoom(room);
        } catch (error) {
          logger({ message: '[Room]: Failed To Create Room: ', data: error });
          toast.error('An error have occurred during joining');
          history.push(homePath);
        }
      },
      [history, homePath]
    );
  
    useEffect(() => {

      console.log('--------------useEffect 1 triggered');
      if (
        (appData.callStatus?.current === CallStatusType.Completed &&
          appData.userRole !== AppConstants.UserRoles.Consumer) ||
        (appData.callStatus?.current === CallStatusType.Zero &&
          appData.userRole !== AppConstants.UserRoles.Consumer)
      ) {
        logger({ message: '[Room]: Disconnecting Room' });
        if (room) {
          // stopAllTrack(room!);
          room?.disconnect();
        }
        resetCallStatus();
        history.push(homePath);
      }
    }, [appData.callStatus, appData.userRole, history, room, resetCallStatus, homePath]);
  
    useEffect(() => {
      console.log('--------------useEffect 2 triggered');

      if (
        appData.callStatus?.current === CallStatusType.PreparingCallSummary &&
        appData.callSummaryInfo.roomId === roomId
      ) {
        callHangupHandler();
      }
    }, [appData.callStatus, appData.callSummaryInfo, callHangupHandler, roomId]);
  
    useEffect(() => {
      console.log('--------------useEffect 3 triggered');

      if (appData.VRIToken && roomId && !haveOngoingCallRef.current) {
        connectToRoom({ roomId: roomId, token: appData.VRIToken });
        haveOngoingCallRef.current = true;
      }
    }, [roomId, appData.VRIToken, connectToRoom]);
  
    useEffect(() => {
      console.log('--------------useEffect 4 triggered');

      if (remoteParticipants) {
        try {
          window.history.pushState(null, document.title, window.location.href);
          window.addEventListener('popstate', function () {
            window.history.pushState(null, document.title, window.location.href);
          });
        } catch (err) {
          console.error(err);
        }
      }
    }, [remoteParticipants]);
  
    useEffect(() => {
      console.log('--------------useEffect 5 triggered');

      logger({ message: 'Call Status: ', data: appData.callStatus });
    }, [appData.callStatus]);
  
    useEffect(() => {
      console.log('--------------useEffect 6 triggered');

      if (
        isVideoConferenceCall &&
        appData.userRole !== AppConstants.UserRoles.Operator
      ) {
        return;
      }
      let timeoutId: number | null = null;
      if (!room?.participants.size && !havePendingCountdownRef.current) {
        havePendingCountdownRef.current = true;
        logger({ message: '[Room]: Starting pending auto call end countdown' });
        timeoutId = window.setTimeout(() => {
          if (room?.participants.size) {
            clearTimeout(timeoutId!);
            logger({
              message: '[Room]: Clearing pending auto call end countdown',
            });
            havePendingCountdownRef.current = false;
          } else {
            logger({ message: '[Room]: Starting auto call end countdown' });
            startCountdown(60);
          }
        }, 5000);
      }
      if (
        room?.participants.size &&
        havePendingCountdownRef.current &&
        isCountdownRunning
      ) {
        endCountdown();
        logger({ message: '[Room]: Clearing auto call end countdown' });
        havePendingCountdownRef.current = false;
      }
    }, [
      startCountdown,
      isCountdownRunning,
      room?.participants.size,
      endCountdown,
      isVideoConferenceCall,
      appData.userRole,
    ]);
  
    useEffect(() => {
      console.log('--------------useEffect 7 triggered');

      if (isCountdownFinished) {
        logger({
          message: '[Room]: Another participant not found. Ending call.',
        });
        callHangupHandler();
      }
    }, [isCountdownFinished, callHangupHandler]);
  
    const VideoCallContextValue = {
      room,
      userRole: appData?.userRole!,
      viewType,
      setViewType,
      callType,
      remoteParticipants,
      participantsInfo,
      selectedParticipantInfo,
      setRemoteParticipants,
      setIsSharing,
      isSharing,
      isVideoEnabled,
      isAudioEnabled,
      setIsVideoEnabled,
      setIsAudioEnabled,
      setParticipantsInfo,
      setSelectedParticipantInfo,
    //   handleParticipantClick,
    };
  
    return {
      appData,
      room,
      showChat,
      setShowChat,
      showHangupModal,
      VideoCallContextValue,
      callHangupHandler,
      setShowHangupModal,
      callOperator,
      selectedParticipantInfo,
      setSelectedParticipantInfo,
      countdownSeconds,
      isCountdownRunning,
    };
  }
  