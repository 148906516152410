import { AdminPageTemplate, ColumnToolTip } from "../../features";
import React, { useEffect, useState } from "react";
import { AppRouteUi, AuditLogActivityTypeOptions, ExportTableType, PaginationSettings, } from "../../configs";
import { useFunctionalityAuditLogs } from "../hooks/functionalityAuditLogs";
import LoadingBar from "react-top-loading-bar";
import ReactDOM from "react-dom";
import { ColumnSelectionsModel, TableColumnExtend } from "../../models";
import { AuditLogsColumns } from "../../configs/AppJson/AuditLogsColumns";
import { ApiSchemaFilter } from "../../models/api/filter";
import { ApiSchemaAuditLogsData } from "../../models/api/AuditLogs";
import moment from "moment";
import { Link } from 'react-router-dom';
import { useAppSelector } from "../../core";
import { VRIAppStateType } from "../../core";

export function PageAuditLogs(): JSX.Element {
  const companyName = useAppSelector((state: VRIAppStateType) => state.auth.profile?.companyName);
  const [loaderProgress, setLoaderProgress] = useState(0);
  const [searchText, setSearchText] = useState<string>("");
  const [pageIndex, setPageIndex] = useState(PaginationSettings.initialPageIndex);
  const [pageSize, setPageSize] = useState(PaginationSettings.initialPageSize);
  const [hasPagingData, setHasPagingData] = useState<boolean>(false);
  const [isShowCommonFilter, setIsShowCommonFilter] = useState<boolean>(false);
  const [columnSelection, setColumnSelection] = useState<ColumnSelectionsModel[]>(AuditLogsColumns);
  const [columnSelectionModalShow, setColumnSelectionModalShow] = React.useState(false);

  let initialFilterData: ApiSchemaFilter = {
    filterQueries: [],
    sortQuery: {},
  };
  const [filterData, setFilterData] =
    useState<ApiSchemaFilter>(initialFilterData);
  const [filterDataChange, setFilterDataChange] = useState<number>(0);

  const { pagedAuditLogsList, pagedAuditLogsListCount } =
    useFunctionalityAuditLogs({
      pageSize: pageSize,
      pageNo: pageIndex,
      searchText: searchText?.trimStart(),
      setLoaderProgress,
      filterData: filterData,
      filterDataChange: filterDataChange,
    });

  useEffect(() => {
    if (pagedAuditLogsListCount) {
      setHasPagingData(true);
    } else {
      setHasPagingData(false);
    }
  }, [pagedAuditLogsList]);

  const handleSearch = (e: any) => {
    ReactDOM.unstable_batchedUpdates(() => {
      setSearchText(e.target.value);
      setPageIndex(1);
    });
  };
  const onChangePageIndex = (index: number) => {
    setPageIndex(index);
  };
  const onChangePageSize = (size: number) => {
    setPageSize(size);
  };

  const auditLogTableColumns: TableColumnExtend<ApiSchemaAuditLogsData>[] = [
    {
      name: <ColumnToolTip data="Who" />,
      sortable: true,
      sortField: "Actor.Name",
      selector: (row: ApiSchemaAuditLogsData) => {
        return (
          <Link to={AppRouteUi.User.Profile.Load(row.actorId ?? "")}>
            <ColumnToolTip data={row.who ?? "-"} />
          </Link>
        );
      },
      omit: columnSelection?.find((eachColumn) => eachColumn.propertyName === "Who")?.isEnabled ? false : true,
    },
    {
      name: <ColumnToolTip data="Role" />,
      selector: (row: ApiSchemaAuditLogsData) => {
        return <ColumnToolTip data={row.role ?? "-"} />;
      },
      omit: columnSelection?.find((eachColumn) => eachColumn.propertyName === "Role")?.isEnabled ? false : true,
    },
    {
      name: <ColumnToolTip data="When" />,
      sortable: true,
      sortField: "CreatedAt",
      selector: (row: ApiSchemaAuditLogsData) => {
        return (
          <ColumnToolTip
            data={
              row.when ? moment(row.when).format("MMM DD, YYYY HH:mm:ss") : "-"
            }
          />
        );
      },
      omit: columnSelection?.find((eachColumn) => eachColumn.propertyName === "When")?.isEnabled ? false : true,
    },
    {
      name: <ColumnToolTip data="Action" />,
      selector: (row: ApiSchemaAuditLogsData) =>
        AuditLogActivityTypeOptions.find(
          (name: any) => name.value === row?.action
        )?.label ?? "-",
      omit: columnSelection?.find((eachColumn) => eachColumn.propertyName === "Action")?.isEnabled ? false : true,
    },
    {
      name: <ColumnToolTip data="Acted Upon" />,
      sortable: true,
      sortField: "ActedUpon.Name",
      selector: (row: ApiSchemaAuditLogsData) => {
        return <ColumnToolTip data={row.actedUpon ?? "-"} />;
      },
      omit: columnSelection?.find((eachColumn) => eachColumn.propertyName === "ActedUpon")?.isEnabled ? false : true,
    },
    {
      name: <ColumnToolTip data="Company" />,
      sortable: true,
      sortField: "Company.Name",
      selector: (row: ApiSchemaAuditLogsData) => {
        return (
          <>
            {row.company !== companyName ? (
              <Link to={AppRouteUi.Company.Profile.Load(row.companyId ?? "")}>
                <ColumnToolTip data={row.company ?? "-"} />
              </Link>
            ) : (
              <ColumnToolTip data={row.company ?? "-"} />
            )}
          </>
        );
      },
      omit: columnSelection?.find((eachColumn) => eachColumn.propertyName === "Company")?.isEnabled ? false : true,
    },
  ];
  return (
    <>
      <LoadingBar
        progress={loaderProgress}
        onLoaderFinished={() => setLoaderProgress(0)}
      />
      <AdminPageTemplate
        loaderProgress={loaderProgress}
        pageTitle="Audit Logs"
        tableHeaders={auditLogTableColumns}
        tableData={pagedAuditLogsList ?? []}
        searchText={searchText}
        handleSearch={handleSearch}
        pageIndex={pageIndex}
        setPageIndex={setPageIndex}
        pageSize={pageSize}
        count={pagedAuditLogsListCount}
        onChangePageIndex={onChangePageIndex}
        onChangePageSize={onChangePageSize}
        showExportOptions={true}
        showFilter={true}
        filterPanel={"commonAuditActivityFilterPanel"}
        hasPagingData={hasPagingData}
        setHasPagingData={setHasPagingData}
        columnSelectionModalShow={columnSelectionModalShow}
        setColumnSelectionModalShow={setColumnSelectionModalShow}
        columnSelection={columnSelection}
        setColumnSelection={setColumnSelection}
        setFilterDataForAnyTable={setFilterData}
        setFilterDataChangeForAnyTable={setFilterDataChange}
        pageName="auditLogsPage"
        tableType={ExportTableType.AuditLog}
        downloadedFileName={"auditlog Report"}
        filterData={filterData}
        setLoaderProgress={setLoaderProgress}
        setIsShowCommonFilter={setIsShowCommonFilter}
        isShowCommonFilter={isShowCommonFilter}
      />
    </>
  );
}
