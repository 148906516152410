import React, { createContext, useContext, useMemo } from 'react';
// import { useSelector, useDispatch } from 'react-redux';
// import { VRIAppStore } from '../core';
import { b64DecodeUnicode, generateVideoToken } from "../utils/zoomUtil"

// const state= VRIAppStore.getState();

// Define the shape of the context value (better: put them into a separate file and import them here and wherever else they are needed)
interface MeetingArgs {
  sdkKey: string; //req  
  topic: string; //req
  signature: string; //req
  name: string; //req
  password?: string;
  webEndpoint?: string;
  enforceGalleryView?: number;
  enforceVB?: number;
  customerJoinId?: string;
  lang?: string;
  useVideoPlayer?: number;
  role?: number;
  cloud_recording_option?: number;
  cloud_recording_election?: number;
  telemetry_tracking_id?: string;
}

// Default value for MeetingArgs - these are here just for initialization. They won't be used in a real call. A real call (session) once started, will get its parameters from the websocket. 
const defaultMeetingArgs: MeetingArgs = {
  sdkKey: '',
  topic: '',
  signature: '',
  name: '',
  enforceGalleryView: 0,
  enforceVB: 0,
  useVideoPlayer: 1,
  role: 1,
};

// Create the context
const MeetingArgsContext = createContext<MeetingArgs>(defaultMeetingArgs);

// Create a custom hook to use the MeetingArgsContext
export const useMeetingArgs = () => useContext(MeetingArgsContext);

// MeetingArgsProvider component
interface MeetingArgsProviderProps {
  children: React.ReactNode;
  devConfig: Partial<MeetingArgs>;
}

export const MeetingArgsProvider: React.FC<MeetingArgsProviderProps> = ({ children, devConfig }) => {
  const meetingArgs = useMemo(() => {
    let args: any = Object.fromEntries(new URLSearchParams(window.location.search));

    // Merge with devConfig if necessary
    if (!args.sdkKey || !args.topic || !args.name || !args.signature) {
      args = { ...devConfig, ...args };
    }

    if (args.web && args.web !== '0') {
      ['topic', 'name', 'password', 'sessionKey', 'userIdentity'].forEach((field) => {
        if (Object.hasOwn(args, field)) {
          try {
            args[field] = b64DecodeUnicode(args[field]);
          } catch (e) {
            console.log('Ignore base64 decode error:', field, args[field]);
          }
        }
      });
      args.role = args.role ? parseInt(args.role, 10) : 1;
    }

    // Set default values
    args.useVideoPlayer = 1;

    ['enforceGalleryView', 'enforceVB', 'cloud_recording_option', 'cloud_recording_election'].forEach((field) => {
      if (Object.hasOwn(args, field)) {
        try {
          args[field] = Number(args[field]);
        } catch (e) {
          args[field] = 0;
        }
      }
    });

    if (args?.telemetry_tracking_id) {
      try {
        args.telemetry_tracking_id = b64DecodeUnicode(args.telemetry_tracking_id);
      } catch (e) {
        args.telemetry_tracking_id = '';
      }
    } else {
      args.telemetry_tracking_id = '';
    }

    // Generate signature if missing
    if (!args.signature && args.sdkSecret && args.topic) {
      args.signature = generateVideoToken(
        args.sdkKey,
        args.sdkSecret,
        args.topic,
        args.sessionKey,
        args.userIdentity,
        Number(args.role ?? 1),
        args.cloud_recording_option,
        args.cloud_recording_election,
        args.telemetry_tracking_id
      );
    }

    return args as MeetingArgs;
  }, [devConfig]);

  return <MeetingArgsContext.Provider value={meetingArgs}>{children}</MeetingArgsContext.Provider>;
};
