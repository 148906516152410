import { useEffect, useState } from 'react';
import useTimer from 'easytimer-react-hook';
import { ModalHangupCall } from '../../features';
import { AppConstants, DefaultImageType, FileBucketURL } from '../../configs';
import {
  useFunctionalityImageView,
  useFunctionalityUserGetUserById,
} from '../../pages/hooks';
import { useAppSelector } from '../../core';
import { NetworkConnection } from '../common';
import { VRIAppStateType } from "../../core";

type TypeOngoingCallScreenProp = {
  handleHangup: Function;
};

export function OngoingCallScreen({ handleHangup }: TypeOngoingCallScreenProp) {
  const [timer] = useTimer();
  const [showHangupModal, setShowHangupModal] = useState(false);
  const { defaultImageLoad, imageView } = useFunctionalityImageView();
  const data = useAppSelector((state: VRIAppStateType) => ({
    receiverId: state.ui.receiverId,
    companyId: state.auth.profile?.companyId,
  }));
  const { userData } = useFunctionalityUserGetUserById({
    companyId: data?.companyId,
    userId: data?.receiverId,
  });
  const isCalleeOperator =
    userData.userRole === AppConstants.UserRoles.Operator;

  useEffect(() => {
    timer.start();
  }, []);

  return (
    <div className="site-call-page">
      <div className="site-call-page-wrapper">
        <div className="container-fluid h-100">
          <div className="row justify-content-center align-items-center h-100 Page-call">
            <div className="col-12 text-center d-flex align-items-center justify-content-center pt-4 pb-4">
              <ul className="babelCallAnimation bg-white caller-image">
                <img
                  src={`${FileBucketURL.Root}${data?.receiverId}?${imageView.fileTimeStamp}`}
                  alt="profile"
                  onError={(event) => {
                    defaultImageLoad(event, DefaultImageType.UserProfile);
                  }}
                  className="img-fluid"
                />
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
              </ul>
            </div>
            <div className="col-12 text-center text-white p-3">
              <p className="mb-3 pb-3 h5">{timer.getTimeValues().toString()}</p>
              <h4 className="pb-4">{userData.name}</h4>
              {!isCalleeOperator && (
                <h5 className="mb-3 mt-0 h5 font-500">
                  {userData.companyName}
                </h5>
              )}
            </div>
            <div className="h-15 w-100">
              <NetworkConnection hangupHandler={handleHangup} />
            </div>
            <div className="col-12 text-center p-3">
              <div className="d-flex align-items-center justify-content-center container">
                <button
                  className="btn mx-4 callPage-controlBtn btn-red"
                  onClick={() => {
                    setShowHangupModal(true);
                  }}
                >
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                    <path d="M511.2 387l-23.25 100.8c-3.266 14.25-15.79 24.22-30.46 24.22C205.2 512 0 306.8 0 54.5c0-14.66 9.969-27.2 24.22-30.45l100.8-23.25C139.7-2.602 154.7 5.018 160.8 18.92l46.52 108.5c5.438 12.78 1.77 27.67-8.98 36.45L144.5 207.1c33.98 69.22 90.26 125.5 159.5 159.5l44.08-53.8c8.688-10.78 23.69-14.51 36.47-8.975l108.5 46.51C506.1 357.2 514.6 372.4 511.2 387z" />
                  </svg>
                </button>
                <ModalHangupCall
                  handleShow={setShowHangupModal}
                  onSubmit={() => {
                    handleHangup();
                  }}
                  show={showHangupModal}
                  header={'Hangup Call'}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
