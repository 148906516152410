import { useDispatch } from "react-redux";
import { Link, useHistory } from 'react-router-dom';
import { toast } from "react-toastify";
import {
  AppRouteUi,
  CallInitiateTabs,
  CallStatusType,
  CallType,
} from "../../../../configs";
import { ActionAudioCall, ActionVideoCall, useAppSelector } from "../../../../core";
import {
  AudioCallInitiateParameter,
  videoCallInitiateParameter,
} from "../../../../models";
import { ApiSchemaFilterOperatorRequest } from "../../../../models/api/Operator";
import { MediaPermission, UserMediaUtils, UtilsJQuery } from "../../../../utils";
import { VRIAppStateType } from "../../../../core";

type Props = {
  videoCallInitiateParam?: videoCallInitiateParameter;
  audioCallInitiateParam?: AudioCallInitiateParameter;
  operatorFilterParam?: ApiSchemaFilterOperatorRequest;
  callInitiateTab: string;
  directCallSelectedUserId?: string;
  totalRequiredFieldCount?: any;
  matchedRequiredFieldCount?: any;
  companyAccountStatus?: boolean;
  error?: string;
  setError?: any;
  // isMediaAllowed?: boolean,
  // setIsMediaAllowed?: any,
};

export default function CallInitiateButton(props: Props): JSX.Element {
  const {
    operatorFilterParam,
    callInitiateTab,
    videoCallInitiateParam,
    directCallSelectedUserId,
    audioCallInitiateParam,
    companyAccountStatus,
    setError
    // isMediaAllowed,
    // setIsMediaAllowed,
  } = props;

  const dispatch = useDispatch();
  const { twilioDeviceStatus, isBackdatedVersion } = useAppSelector((state: VRIAppStateType) => state.common);

  const history = useHistory();

  const btnLoading = UtilsJQuery.Ladda(".direct-call-btn");

  const onClickHandler = async () => {
    if (twilioDeviceStatus === 'offline') {
      toast.error(
        "Couldn't connect. Please, check your connection and try later."
      );
      return;
    }
    if(!directCallSelectedUserId) {
      setError('This field is required');
      return ;
    } else {
      btnLoading.start?.();
      const tracks = await UserMediaUtils.startMedia();
      tracks && dispatch(ActionVideoCall.setTracks(tracks));
      btnLoading.stop?.();
      if (companyAccountStatus && tracks) {
        dispatch(
            ActionVideoCall.SetCallStatus({
              prev: CallStatusType.Zero,
              current: CallStatusType.Searching,
            })
        )
        history.push({
          pathname: AppRouteUi.VideoOutgoingCall.Root,
          state: {
            operatorFilterParam: operatorFilterParam,
            callInitiateTab: callInitiateTab,
            videoCallInitiateParam: videoCallInitiateParam,
            directCallSelectedUserId: directCallSelectedUserId,
            callType: CallType.Video
          }
        })
      }
      else if (!companyAccountStatus) {
        history.push({
          pathname: AppRouteUi.OutgoingCallFailed.Root
        })
      } else {
        history.push({
          pathname: AppRouteUi.OutgoingCallFailedForMedia.Root
        })
      }
    }
  }

  const handlePhoneCall = async () => {
    if (!companyAccountStatus) {
      history.push(AppRouteUi.OutgoingCallFailed.Root);
      return;
    }
    if (twilioDeviceStatus === 'offline') {
      toast.error(
        "Couldn't connect. Please, check your connection and try later."
      );
      return;
    }
    const permission = await MediaPermission.getStatus({ audio: true });
    if (permission) {
      dispatch(
        ActionAudioCall.SetAudioCallStatus({
          prev: CallStatusType.Zero,
          current: CallStatusType.Searching,
        })
      );
      history.push({
        pathname: AppRouteUi.OutgoingCall.Root,
        state: {
          operatorFilterParam: operatorFilterParam,
          callInitiateTab: callInitiateTab,
          audioCallInitiateParam: audioCallInitiateParam,
          directCallSelectedUserId: directCallSelectedUserId,
          callType: CallType.Audio,
        },
      });
    } else {
      history.push(AppRouteUi.OutgoingCallFailedForMic.Root);
    }
  };


  {/* <Link
        to={companyAccountStatus && localMediaState? {
          pathname: AppRouteUi.VideoOutgoingCall.Root,
          state: {
            operatorFilterParam: operatorFilterParam,
            callInitiateTab: callInitiateTab,
            videoCallInitiateParam: videoCallInitiateParam,
            directCallSelectedUserId: directCallSelectedUserId,
            callType: CallType.Video
          },
        } : !companyAccountStatus ? {
          pathname: AppRouteUi.OutgoingCallFailed.Root
        } : 
        {
          pathname: AppRouteUi.OutgoingCallFailedForMedia.Root
        }
      } */}

  return (
    <div className="row  justify-content-center  flex-column flex-sm-row flex-md-row flex-lg-row flex-xl-row  mx-0">
      <div className="width-content mx-auto">
      <button
        onClick={(e) => {
          e.preventDefault()
          onClickHandler()

        }}
        className="btn video-call-btn-ctl mx-xl-3 mx-lg-3 mx-md-3 mx-sm-3 mx-0 my-xl-0 my-lg-0 my-md-0 my-sm-2 my-2  p-0 direct-call-btn"
        disabled={isBackdatedVersion}
      >
        <div className="d-flex align-items-center justify-content-center ">
              <div className="bg-purple text-white">
              <svg   x="0px" y="0px" viewBox="0 0 2400 2400">
                  <g>
	                  <polygon fill="currentColor" points="2395,627.8 1800.1,1021.9 1800.1,1471.8 2395,1816 	"/>
	                <path fill="currentColor" d="M1535.9,1791.8c0,40.9-33.1,74-74,74H79c-40.9,0-74-33.1-74-74V606.7c0-40,32.4-72.5,72.5-72.5 c0.5,0,1,0,1.5,0h1383c40-0.8,73.1,30.9,74,70.9c0,0.5,0,1,0,1.5V1791.8z"/>
                </g>
              </svg>

              </div>
              <p className="ml-2 my-0"> Video Call </p>
          </div>
      </button>
      </div>
      

      {callInitiateTab !== CallInitiateTabs.directCall && (
        <Link
          to="#"
          onClick={handlePhoneCall}
          className="btn phone-call-btn-ctl d-flex justify-content-center align-items-center my-xl-0 my-lg-0 my-md-0 my-sm-2 my-2 mx-0 mx-sm-3 mx-md-3 width-fit-content"
          style={isBackdatedVersion ? {pointerEvents: 'none'} : {}}
        >
          <div className="d-flex width-fit-content">
            <div>
            <svg version="1.1" id="Layer_1"  x="0px" y="0px" viewBox="0 0 2400 2400">
              <path fill="currentColor" d="M104.2,154.8l71.3-84.4C227.5,6.8,320.5-4,385.6,46l531,407.2c103.3,78.2,123.7,225.3,45.5,328.5
	              c-1.4,1.8-2.8,3.6-4.2,5.4L941,809.6c-76.9,95.7-131.3,258.9-41.3,343.4l424,394c114.5,106.9,223.3-48.8,335.9-152
	              c73.2-72.7,191.4-72.7,264.6,0l405.3,405.3c206.4,206.4-122,546-334,583.5c-128.9,9.5-257.2-24.9-364-97.6
	              c-410.8-222.3-781.8-511.4-1097.6-855.6C248.7,1115.5-194.1,603.2,104.2,154.8z"/>
            </svg>

            </div>
            <p className="ml-2 my-0"> Phone Call </p>
          </div>
        </Link>
      )}
    </div>
  );
}
