import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { shallowEqual } from "react-redux";
import { Link } from 'react-router-dom';
import LoadingBar from "react-top-loading-bar";
import { AssetSvg } from "../../assets";
import { AppRouteUi, CompanyType, CreateCompanyType, PaginationSettings, StepNumber, } from "../../configs";
import { useAppSelector } from "../../core";
import { AdminPageTemplate, ColumnToolTip, ModalCreateCompany } from "../../features";
import { ApiSchemaCompanyData } from "../../models";
import { useFunctionalityPagedReseller } from "../hooks/functionalityReseller";
import { VRIAppStateType } from "../../core";

export function PageReseller(): JSX.Element {
  const [createModalShow, setCreateModalShow] = React.useState(false);
  const [stepCounter, setStepCounter] = React.useState(StepNumber.firstStep);
  const [loaderProgress, setLoaderProgress] = useState(0);
  const [pageIndex, setPageIndex] = useState(PaginationSettings.initialPageIndex);
  const [pageSize, setPageSize] = useState(PaginationSettings.initialPageSize);
  const [searchText, setSearchText] = useState<string>("");
  const [hasPagingData, setHasPagingData] = useState<boolean>(false);

  const handleSearch = (e: any) => {
    ReactDOM.unstable_batchedUpdates(() => {
      setSearchText(e.target.value);
      setPageIndex(1);
    });
  };
  const onChangePageIndex = (index: number) => {
    setPageIndex(index);
  };
  const onChangePageSize = (size: number) => {
    setPageSize(size);
  };
  const currentUserInfo = useAppSelector(
    (state: VRIAppStateType) => ({
      companyId: state.auth.profile?.companyId || "",
    }),
    shallowEqual
  );

  const { pagedCompanyListCount, pagedCompanyList } =
    useFunctionalityPagedReseller({
      parentCompanyId: currentUserInfo.companyId,
      pageSize: pageSize,
      pageNo: pageIndex,
      searchText: searchText?.trimStart(),
      setLoaderProgress,
    });

  useEffect(() => {
    if (pagedCompanyList?.length > 0) {
      setHasPagingData(true);
    } else {
      setHasPagingData(false);
    }
  }, [pagedCompanyList]);

  const resellerTableColumns = [
    {
      name: <ColumnToolTip data="Code" />,
      selector: (row: ApiSchemaCompanyData) => {
        return <ColumnToolTip data={row.code} />;
      },
    },
    {
      name: <ColumnToolTip data="Name" />,
      selector: (row: ApiSchemaCompanyData) => {
        return <ColumnToolTip data={row.name} />;
      },
    },
    {
      name: <ColumnToolTip data="Actions" />,
      allowOverflow: true,
      selector: (row: ApiSchemaCompanyData) => {
        return (
          <>
            <Link
              to={AppRouteUi.Reseller.Profile.Load(row.id ?? "")}
              className="btn"
            >
              <div className="btn svg-color" title="View">
                <span >
                  <AssetSvg.ViewProfile />
                </span>
              </div>
            </Link>
          </>
        );
      },
    },
  ];

  return (
    <>
      <LoadingBar
        progress={loaderProgress}
        onLoaderFinished={() => setLoaderProgress(0)}
      />
      <AdminPageTemplate
        loaderProgress={loaderProgress}
        pageTitle="Reseller"
        modalControlBtnLabel="Create Reseller"
        tableHeaders={resellerTableColumns}
        tableData={pagedCompanyList}
        setCreateModalShow={setCreateModalShow}
        setStepCounter={setStepCounter}
        searchText={searchText}
        handleSearch={handleSearch}
        pageIndex={pageIndex}
        setPageIndex={setPageIndex}
        pageSize={pageSize}
        count={pagedCompanyListCount}
        onChangePageIndex={onChangePageIndex}
        onChangePageSize={onChangePageSize}
        hasPagingData={hasPagingData}
        setHasPagingData={setHasPagingData}
      />
      <ModalCreateCompany
        show={createModalShow}
        title={CreateCompanyType.Reseller}
        handleShow={setCreateModalShow}
        setStepCounter={setStepCounter}
        stepCounter={stepCounter}
        parentId={currentUserInfo.companyId}
        companyType={CompanyType.Reseller}
      />
    </>
  );
}
