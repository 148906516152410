import React, { useState } from "react";
import { shallowEqual } from "react-redux";
import { UserGeneralInfo, UserNotificationSetting } from ".";
import { useAppSelector } from "../../../../core";
import {
  FaceToFaceAssignment,
  LanguageRates,
  SupportedPlatform,
  Skills,
  Ratings,
  MedicalHistory,
} from "../../../OperatorProfile";
import { VRIAppStateType } from "../../../../core";

type Props = {
  profile: any;
  userDetailsData: any;
  onUpdateHandler: any;
  editModalShow: boolean;
  setEditModalShow: any;
};
export function CommonUserProfile(props: Props) {
  const {
    profile,
    userDetailsData,
    onUpdateHandler,
    editModalShow,
    setEditModalShow,
  } = props;
  const [isOnsiteEnabled, setIsOnsiteEnabled] = useState(false);
  const data = useAppSelector(
    (state: VRIAppStateType) => ({
      operatorId: state.auth.profile?.operatorId,
    }),
    shallowEqual
  );

  return (
    <>
      <UserGeneralInfo
        profile={userDetailsData}
        onUpdateHandler={onUpdateHandler}
        userDetailsData={userDetailsData}
        editModalShow={editModalShow}
        setEditModalShow={setEditModalShow}
      />
      {/* <UserNotificationSetting  userDetailsData={userDetailsData} /> */}
      {data.operatorId ? (
        <div className="row">
          <div className="col-12 col-sm-6 pr-sm-0">
            <SupportedPlatform
              setIsOnsiteEnabled={setIsOnsiteEnabled}
              readonly={false}
              operatorId={data.operatorId}
            />
            <FaceToFaceAssignment
              readonly={false}
              operatorId={data.operatorId}
            />
            <LanguageRates readonly={false} operatorId={data.operatorId} />
            <Skills readonly={false} operatorId={data.operatorId} />
          </div>
          <div className="col-12 col-sm-6">
            {/* <Ratings
              readonly={false}
              operatorId={data.operatorId}
              userDetails={userDetailsData}
            /> */}
            <MedicalHistory readonly={true} operatorId={data.operatorId} />
          </div>
        </div>
      ) : null}
    </>
  );
}
