import { useState, useEffect, useCallback, useReducer, useMemo, useContext } from 'react';
import ZoomVideo, { ConnectionState, ReconnectReason } from '@zoom/videosdk';
import { message, Modal } from 'antd';
import produce from 'immer';
import ZoomContext from '../../../contexts/zoom-context';
import { MediaStream } from '../../../types';

// Initial media state shape
const mediaShape = {
  audio: { encode: false, decode: false },
  video: { encode: false, decode: false },
  share: { encode: false, decode: false },
};

// Reducer to manage media state
const mediaReducer = produce((draft: any, action: any) => {
  switch (action.type) {
    case 'audio-encode':
      draft.audio.encode = action.payload;
      break;
    case 'audio-decode':
      draft.audio.decode = action.payload;
      break;
    case 'video-encode':
      draft.video.encode = action.payload;
      break;
    case 'video-decode':
      draft.video.decode = action.payload;
      break;
    case 'share-encode':
      draft.share.encode = action.payload;
      break;
    case 'share-decode':
      draft.share.decode = action.payload;
      break;
    case 'reset-media':
      Object.assign(draft, { ...mediaShape });
      break;
    default:
      break;
  }
}, mediaShape);

// useZoomVideo hook
export function useZoomVideo({
  sdkKey,
  topic,
  signature,
  name,
  password,
  webEndpoint: webEndpointArg,
  enforceGalleryView,
  enforceVB,
  useVideoPlayer,
}: any) {
  const [loading, setIsLoading] = useState(true);
  const [loadingText, setLoadingText] = useState('');
  const [isFailover, setIsFailover] = useState<boolean>(false);
  const [status, setStatus] = useState<string>('closed');
  const [mediaState, mediaDispatch] = useReducer(mediaReducer, mediaShape);
  const [mediaStream, setMediaStream] = useState<MediaStream | null>(null);
  const [isSupportGalleryView, setIsSupportGalleryView] = useState<boolean>(true);

  // Use ZoomContext to get zmClient, which was created inside index.tsx
  const zmClient = useContext(ZoomContext);

  const webEndpoint = webEndpointArg || (window?.webEndpoint ?? 'zoom.us');
  const galleryViewWithoutSAB = Number(enforceGalleryView) === 1 && !window.crossOriginIsolated;
  const vbWithoutSAB = Number(enforceVB) === 1 && !window.crossOriginIsolated;
  const galleryViewWithAttach = Number(useVideoPlayer) === 1 && (window.crossOriginIsolated || galleryViewWithoutSAB);

  // Initialize Zoom client
  useEffect(() => {

    // console.log("useZoomVideo init+join was triggered, ZZZ");
    const init = async () => {
      try{
        
      await zmClient.init('en-US', 'Global', {
        webEndpoint,
        enforceMultipleVideos: galleryViewWithoutSAB,
        enforceVirtualBackground: vbWithoutSAB,
        stayAwake: true,
        patchJsMedia: true,
        leaveOnPageUnload: false,
      });
      // console.error("init try passed");
    }catch(error){
      console.error("init error", error);
    };
      try {
        setLoadingText('Joining the session...');
        await zmClient.join(topic, signature, name, password);
        const stream = zmClient.getMediaStream();
        setMediaStream(stream);

        setIsSupportGalleryView(stream.isSupportMultipleVideos());
        // if
        //   (stream) {
        //   try {
        //     await
        //       stream.
        //         startVideo
        //         ({
        //           hd
        //             : true,
        //           fullHd
        //             : true,
        //         }); console.
        //           log
        //           (
        //             'Video started successfully'
        //           );
        //   }
        //   catch
        //   (error) {
        //     console.
        //       error
        //     (
        //       'Error starting video:'
        //       , error); message.
        //         error
        //         (
        //           'Failed to start video'
        //         );
        //   }
        // }
        setIsLoading(false);
      } catch (e: any) {
        setIsLoading(false);
        message.error(e.reason);
      }
    };
    init();
    return () => {
      ZoomVideo.destroyClient();
    };
    //still gave duplication error with name and password added to the last array below.
    // }, [zmClient, topic, signature, name, password, webEndpoint, galleryViewWithoutSAB, vbWithoutSAB]);
  }, [topic, signature]);


  // Handle connection changes
  const onConnectionChange = useCallback(
    (payload: any) => {
      if (payload.state === ConnectionState.Reconnecting) {
        setIsLoading(true);
        setIsFailover(true);
        setStatus('connecting');
        const { reason, subsessionName } = payload;
        setLoadingText(
          reason === ReconnectReason.Failover
            ? 'Session Disconnected, Try to reconnect'
            : reason === ReconnectReason.JoinSubsession || reason === ReconnectReason.MoveToSubsession
              ? `Joining ${subsessionName}...`
              : 'Returning to Main Session...'
        );
      } else if (payload.state === ConnectionState.Connected) {
        setStatus('connected');
        if (isFailover) {
          setIsLoading(false);
        }

        window.zmClient = zmClient;
        window.mediaStream = zmClient.getMediaStream();
      } else if (payload.state === ConnectionState.Closed) {
        setStatus('closed');
        //@ts-ignore
        mediaDispatch({ type: 'reset-media' });
        if (payload.reason === 'ended by host') {
          Modal.warning({
            title: 'Meeting ended',
            content: 'This meeting has been ended by host',
          });
        }
      }
    },
    [zmClient, isFailover]
  );

  // Handle media SDK changes
  const onMediaSDKChange = useCallback(
    (payload: any) => {
      //@ts-ignore
      mediaDispatch({ type: `${payload.type}-${payload.action}`, payload: payload.result === 'success' });
    },
    []
  );

  // Set up event listeners
  useEffect(() => {
    zmClient.on('connection-change', onConnectionChange);
    zmClient.on('media-sdk-change', onMediaSDKChange);
    return () => {
      zmClient.off('connection-change', onConnectionChange);
      zmClient.off('media-sdk-change', onMediaSDKChange);
    };
  }, [zmClient, onConnectionChange, onMediaSDKChange]);

  // Handle session join/leave - this is not needed so it was commented out. This is specific to the Zoom SDK project; we are properly leaving sessions from within video-footer.
  // const onLeaveOrJoinSession = useCallback(async () => {
  //   if (status === 'closed') {
  //     setIsLoading(true); 
  //     await zmClient.join(topic, signature, name, password);
  //     setIsLoading(false);
  //   } else if (status === 'connected') {
  //     await zmClient.leave();
  //     message.warn('You have left the session.');
  //   }
  // }, [zmClient, status, topic, signature, name, password]);


  // Media context with memoization
  const mediaContext = useMemo(() => ({ ...mediaState, mediaStream }), [mediaState, mediaStream]);

  return {
    loading,
    loadingText,
    isSupportGalleryView,
    galleryViewWithAttach,
    mediaContext,
  };
}
