import { useEffect, useContext, useState, useCallback, useReducer, useMemo } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import ZoomVideo, { ConnectionState, ReconnectReason } from '@zoom/videosdk';
import { message, Modal } from 'antd';
import 'antd/dist/antd.min.css';
import produce from 'immer';

import Video from '../../features/video/video';
import VideoSingle from '../../features/video/video-single';
import VideoAttach from '../../features/video/video-attach';
import ZoomContext from '../../contexts/zoom-context';
import ZoomMediaContext from '../../contexts/media-context';

import { MediaStream } from '../../types';
import { useMeetingArgs } from '../../contexts/meetingArgs-context'; //this is a context created by Ghadir, probably a bad idea

import { useSelector } from 'react-redux';
import { TSliceUi } from '../../core';
import { useZoomVideo } from '../../components/VideoProvider/useZoomVideo/useZoomVideo';
import { getExploreName } from '../../utils/platform';

import { useZoomCall } from '../../contexts/zoomCall-context';




export function VideoCallPageV2() {

  const { loading, loadingText, isSupportGalleryView, galleryViewWithAttach, mediaContext } = useZoomCall();
  //zoom call is no longer being init+join here. It should be done from an upper level component (we did through app, through the useZoomVideo hook implemented inside the zoomCall-context).
  //The reason is because this is a low level component that gets re-rerendered, and that will cause the call to be reinitiated again and again. 
  //By putting it inside App, we will already have an initialized empty session as soon as a user accesses our app. This session won't matter since it is empty.
  //Once the user initiated a call, the call parameters are sent in the websocket, received by our redux store, and then the zoomCall context will get updated with them. 
  //This will cause a now "proper" session to be initialized, and we won't have an issue of duplication because it is being performed only once at our top level component, APP. 


  if (loading) {
    return <div>{loadingText || 'Loading...'}</div>;
  }

  console.log("VideoCallPage has been rerendered, the value of mediaContext is:");
  console.log(mediaContext);
  

  return (
  // <VideoSingle />
  <ZoomMediaContext.Provider value={mediaContext}>
  <Route
  path="/room/:id"
  component={isSupportGalleryView ? (galleryViewWithAttach ? VideoAttach : Video) : VideoSingle}
  // component={VideoSingle}
/>
 </ZoomMediaContext.Provider>
);
}

