import { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { ColumnSelectionsModel } from '../../models';

type Props = {
  show?: boolean;
  handleShow?: (value: boolean) => void;
  columns?: ColumnSelectionsModel[];
  setColumns: (value: any[]) => void;
  modalTitle: string;
  setModalTitle?: (value: string) => void;
};

export function ModalColumnSelection(props: Props): JSX.Element {
  const { show, handleShow, columns, setColumns, modalTitle } = props;
  const [renderedColumns, setRenderedColumns] =
    useState<ColumnSelectionsModel[]>();
  const [enabled, setEnabled] = useState<boolean>(false);
  const [renderedEnabled, setRenderedEnabled] = useState<boolean>(false);

  const onToggleHandler = (column: ColumnSelectionsModel, index: number) => {
    let newCloumns = [...columns!];
    let modifiedNewColum;
    if (column.isEnabled) {
      modifiedNewColum = {
        name: column.name,
        propertyName: column.propertyName,
        isEnabled: false,
      };
    } else {
      modifiedNewColum = {
        name: column.name,
        propertyName: column.propertyName,
        isEnabled: true,
      };
    }
    newCloumns[index] = modifiedNewColum;
    setColumns(newCloumns);
  };

  useEffect(() => {
    const filteredColumns = columns?.filter(
      (column: ColumnSelectionsModel) => column.isEnabled === true
    );
    if (columns?.length && filteredColumns?.length === columns?.length) {
      setEnabled(true);
    } else {
      setEnabled(false);
    }
    setRenderedColumns(columns);
  }, [columns]);

  const handleEnableDisable = () => {
    if (enabled) {
      setEnabled(false);
      const modifiedColumns: ColumnSelectionsModel[] = columns?.length
        ? columns?.map((column: ColumnSelectionsModel) => {
            return {
              name: column.name,
              propertyName: column.propertyName,
              isEnabled: false,
            };
          })
        : [];
      setColumns(modifiedColumns);
    } else {
      setEnabled(true);
      const modifiedColumns: ColumnSelectionsModel[] = columns?.length
        ? columns?.map((column: ColumnSelectionsModel) => {
            return {
              name: column.name,
              propertyName: column.propertyName,
              isEnabled: true,
            };
          })
        : [];
      setColumns(modifiedColumns);
    }
  };
  useEffect(() => {
    if (enabled) {
      setRenderedEnabled(true);
    } else {
      setRenderedEnabled(false);
    }
  }, [enabled]);

  return (
    <Modal
      show={show}
      onHide={() => handleShow && handleShow(false)}
      centered
      id="markAsPaidModal"
    >
              {/*@ts-ignore */}
        <Modal.Header closeButton>
        <Modal.Title>{modalTitle}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="my-3">
          Please select the columns you want to view:
          <span className="float-right">
            <button
              type="button"
              data-toggle="button"
              className={
                renderedEnabled ? 'btn btn-toggle active' : 'btn btn-toggle'
              }
              onClick={() => {
                handleEnableDisable();
              }}
            >
              <div className="handle"></div>
            </button>
            <span className="toggle-button-column-selection">All</span>
          </span>
        </div>
        <div className="row flex-wrap px-3">
          {renderedColumns?.map((column: ColumnSelectionsModel, index) => (
            <div 
              key = {index}
              className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-4 px-0 my-3"
            >
              <button
                type="button"
                data-toggle="button"
                className={
                  column.isEnabled ? 'btn btn-toggle active' : 'btn btn-toggle'
                }
                onClick={() => {
                  onToggleHandler(column, index);
                }}
              >
                <div className="handle"></div>
              </button>
              <span className="toggle-button-label">{column?.name}</span>
            </div>
          ))}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          className="btn btn-primary align-items-center"
          onClick={() => handleShow && handleShow(false)}
        >
          Ok
        </button>
      </Modal.Footer>
    </Modal>
  );
}
