import moment from "moment";
import { ITypeNotificationContent } from ".";
import { AppConstants, BookingNotificationType, SocketNotificationType } from "../../configs";
import { useAppSelector } from "../../core";
import { ApiSchemaNotificationData } from "../../models/api/notification";
import { timeSpanCalculator } from "../../utils";
import { shallowEqual } from 'react-redux';
import { VRIAppStateType } from "../../core";

interface PropsType {
  notification: ApiSchemaNotificationData;
  readNorificaiton: Function;
}

export function SingleNotification({ notification, readNorificaiton }: PropsType): JSX.Element {

  const userProfile = useAppSelector(
    (state: VRIAppStateType) => state.auth.profile,
    shallowEqual
  );

  const mainContent: ITypeNotificationContent = JSON.parse(notification.content!)
  let notificationContent;
  if (notification.type === SocketNotificationType.Booking) {
    if (mainContent.BookingNotificationType === BookingNotificationType.BookingStarted) {
      if (userProfile?.userRole === AppConstants.UserRoles.User || userProfile?.userRole === AppConstants.UserRoles.CompanyAdmin || userProfile?.userRole === AppConstants.UserRoles.CompanyFinance) {
        notificationContent = `#${mainContent.SerialNo} booking will be started at ${moment(mainContent.Schedule).format('hh:mm A')}.`
      }
    } else if (mainContent.BookingNotificationType === BookingNotificationType.OperatorAssigned) {
      if (userProfile?.userRole === AppConstants.UserRoles.User || userProfile?.userRole === AppConstants.UserRoles.CompanyAdmin || userProfile?.userRole === AppConstants.UserRoles.CompanyFinance) {
        notificationContent = `#${mainContent.SerialNo} booking is assigned to a operator.`
      } else if (userProfile?.userRole === AppConstants.UserRoles.Operator) {
        notificationContent = `#${mainContent.SerialNo} job is assigned to you.`
      }
    } else if (mainContent.BookingNotificationType === BookingNotificationType.OperatorConfirmed) {
      if (userProfile?.userRole === AppConstants.UserRoles.User || userProfile?.userRole === AppConstants.UserRoles.CompanyAdmin || userProfile?.userRole === AppConstants.UserRoles.CompanyFinance) {
        notificationContent = `#${mainContent.SerialNo} booking is confirmed by an operator.`
      }
    } else if (mainContent.BookingNotificationType === BookingNotificationType.OfferBooking) {
      if (userProfile?.userRole === AppConstants.UserRoles.Operator) {
        notificationContent = `#${mainContent.SerialNo} job is offered to you.`
      }
    } else if (mainContent.BookingNotificationType === BookingNotificationType.Updated) {
      // if (userProfile?.userRole === AppConstants.UserRoles.Operator) {
        notificationContent = `#${mainContent.SerialNo} job is updated.`
      // }
    }
  }
  return (
    <>
      <div className="d-flex flex-wrap notification-thread  align-items-center" onClick={() => readNorificaiton(notification, mainContent)}>
        <div className="col-fixed-width notification-msg flex-shrink-0 ">
          <p className={`${notification.isRead ? "text-muted mb-0 pl-3 pt-3" : "text-dark mb-0 pl-3 pt-3"}`}>
            {notificationContent}
          </p>
          <div className="notification-time">
            <p className="mb-0">
              <small className="text-secondary pl-3">
                {`${timeSpanCalculator(notification.createdAt.toString(), userProfile?.timeZone) ?? ''} ago`}
              </small>
            </p>
          </div>
        </div>
        {/* <div className="flex-shrink-0 flex-grow-0 ml-md-auto">
          <a className="notification-status btn">
            <svg viewBox="0 0 64 64" aria-labelledby="title"
              aria-describedby="desc" role="img" >
              <path data-name="layer2"
                fill="none" stroke="currentColor" stroke-miterlimit="10" stroke-width="3" d="M6 10h52m-36 0V5.9A3.9 3.9 0 0 1 25.9 2h12.2A3.9 3.9 0 0 1 42 5.9V10m10.5 0l-2.9 47.1a5 5 0 0 1-4.9 4.9H19.3a5 5 0 0 1-4.9-4.9L11.5 10"
                stroke-linejoin="round" stroke-linecap="round"></path>
              <path data-name="layer1" fill="none" stroke="currentColor" stroke-miterlimit="10"
                stroke-width="2" d="M32 18v36M22 18l2 36m18-36l-2 36" stroke-linejoin="round"
                stroke-linecap="round"></path>
            </svg>
          </a>
        </div> */}
      </div>

    </>

  )
}