import { Redirect, Route, RouteProps } from 'react-router-dom';
import { AppConstants, AppRouteUi } from "../../configs";
import { useAppSelector } from "../../core";
import { PageNotFound } from "./pageNotFound";
import { VRIAppStateType } from "../../core";

type RoleType = keyof typeof AppConstants.UserRoles;

const RoleValueToKeyMap: { [key: string]: string | undefined } = Object.keys(
  AppConstants.UserRoles
).reduce((p, n) => ({ ...p, [AppConstants.UserRoles[n as RoleType]]: n }), {});

type PrivateRouteProps = RouteProps & {
  privateConfig?: {
    allowedRoles: RoleType[];
  };
};

export function PrivateRoute({
  privateConfig,
  ...rest
}: PrivateRouteProps): JSX.Element {
  const userRole = useAppSelector((state: VRIAppStateType) => state.auth.profile?.userRole || "");

  if (!userRole) return <Redirect to={AppRouteUi.Login.Root} />;

  if (privateConfig) {
    if (
      Array.isArray(privateConfig.allowedRoles) &&
      !privateConfig?.allowedRoles.includes(
        RoleValueToKeyMap[userRole] as RoleType
      )
    ) {
      const { ...fallbackProps } = rest;
      return <Route {...fallbackProps} component={PageNotFound} />;
    }
  }

  return <Route {...rest} />;
}
