import React, { useEffect, useState } from "react";
import { shallowEqual } from "react-redux";
import { ChatUI } from "./ChatUI";
import { FileBucketURL, TwilioApi } from "../../../configs/appRoute";
import { ChannelContext, useAppSelector } from "../../../core";
import { AxiosAuth } from "../../../core/Axios/AxiosInstance";
import { useFunctionalityChat, useFunctionalityImageView } from "../../../pages/hooks";
import { useFunctionalityUser, useFunctionalityUserGetNames, useFunctionalityUserGetNamesWithActiveStatus } from '../../../pages/hooks/functionalityUser';
import { ApiResponseParticipant, ApiSchemaCreateConversation } from '../../../models/api/chat';
import { ConversationType, DefaultImageType } from "../../../configs";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { ContanctUserDetail } from "./contactUserDetails";
import { OperatorStatusType } from "../../../models/api/Operator";
import { VRIAppStateType } from "../../../core";

interface contactType {
  searchValue: string;
  setCreate: any,
  editGroup?: boolean,
  groupParticipants?: ApiResponseParticipant[],
}

export function CreateGroupContactList(props: contactType): JSX.Element {
  const { setCreate, editGroup, groupParticipants } = props;
  const { selectedChannel, setSelectedChannel } = React.useContext(ChannelContext);
  const currentUserCompanyId = useAppSelector((state: VRIAppStateType) => state.auth.profile?.companyId);
  // const { userNames: contactList } = useFunctionalityUserGetNames({ companyId: currentUserCompanyId });
  const { userNames: contactList } = useFunctionalityUserGetNamesWithActiveStatus({ companyId: currentUserCompanyId });


  const { onCreateConversation, onAddParticipants } = useFunctionalityChat();
  const { defaultImageLoad, imageView } = useFunctionalityImageView();

  const currentUser = useAppSelector(
    (state: VRIAppStateType) => ({
      uid: state.auth.profile?.id,
    }),
    shallowEqual
  );

  const [selecteUserIdList, setSelectedUserIdList] = useState<any>([])
  const [groupName, setGroupName] = useState<any>()
  const showSearchedContacts = (substring: string) => {
    substring = substring.toLowerCase();

    let returningList: any[] = [];

    if (contactList.length > 0) {
      contactList?.map((contact) => {
        if (contact.value !== currentUser.uid) {
          let lowerLabel = contact.label?.toLocaleLowerCase();
          if (lowerLabel?.includes(substring)) {
            returningList.push(contact);
          }
        }
      });
    }


    if (editGroup && groupParticipants) {
      groupParticipants.map((niceParticipant: ApiResponseParticipant) => {
        returningList.splice(returningList.findIndex(a => a.value === niceParticipant.userId), 1)
      })
    }
    return returningList;
  };

  const checkboxHandler = (user: any) => {

    const newArray: any = [...selecteUserIdList];
    let index = newArray.findIndex((x: any) => x.value === user.value);
    if (index == -1) {
      newArray.push(user);
    }
    else {
      newArray.splice(index, 1)
    }
    setSelectedUserIdList(newArray)
  }

  const handleGroupName = (e: any) => {
    setGroupName(e.target.value)
  }

  const concatNames = () => {
    let name = ""
    selecteUserIdList.forEach((user: any) => {
      name += user.label + ","
    });

    return name
  }

  const idExtractor = () => {
    const userId: string[] = [];
    selecteUserIdList.forEach((user: any) => {
      userId.push(user.value)
    });

    return userId
  }

  const onCreateHandler = () => {

    let name = concatNames();
    const ids = idExtractor()

    if (groupName) name = groupName

    if (ids && ids.length > 0) {
      const data: ApiSchemaCreateConversation = {
        conversationName: name,
        participantIds: ids,
        conversationType: ConversationType.Group
      }

      onCreateConversation(data)
        .then(() => {
          setCreate(false)
        })
    }
  }
  const [onHover, setOnHover] = useState(false)
  const [userId, setUserId] = useState("")

  const hoverHandler = (id: string) => {
    setUserId(id)
    setOnHover(true)
  }
  const onAddParticipantHandler = () => {
    const ids = idExtractor()
    onAddParticipants(selectedChannel?.sid, ids)
      .then(() => {
        setCreate(false)
      })
  }
  const popover = (
    <Popover id="button-tooltip">
      <ContanctUserDetail userId={userId} />
    </Popover>
  );


  return (
    <>
      {!editGroup && <div className="col-12 pb-2">
        <div className="pt-2">Group Name</div>
        <div className="mb-2"> <small> (you can change it later in your group-chat Title)</small></div>
        <input
          type="text"
          className="form-control"
          aria-label="search-input"
          placeholder="Group Name (Optional)"
          onBlur={(e) => handleGroupName(e)}
        />
      </div >}


      <div className="col-12 contacts-chat-list group-contact-list-height pb-2">
        {showSearchedContacts(props.searchValue)?.map((user) => (
          <div
            key={user.value}
            className="row contacts-chat-list-item align-items-center px-3 pb-1"
            onClick={() => {
              // getChannel(user.value);
            }}
            onMouseOver={() => hoverHandler(user.value)}
            onMouseOut={() => setOnHover(false)}
          >
            {selectedChannel && !editGroup ? <ChatUI /> : null}
            <div className="my-auto">
              <input
                type="checkbox"
                name="profileName"
                // value={result.SITE_NAME}
                checked={selecteUserIdList.some((selectedUser: any) => selectedUser.value === user.value)}
                onChange={() => { checkboxHandler(user) }}

              />
            </div>
            <div className="header-profile-image incoming-chat-image col">
              {/* <img
                src="https://images.pexels.com/photos/771742/pexels-photo-771742.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260"
                alt=""
                className="img-fluid "
              /> */}
              <OverlayTrigger
                placement="right"
                delay={{ show: 250, hide: 400 }}
                overlay={popover}
              >
                <img
                  src={`${FileBucketURL?.Root}${user.value}?${imageView?.fileTimeStamp}`}
                  alt=""
                  onError={(ev) => { defaultImageLoad(ev, DefaultImageType.UserProfile, "?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260") }}
                  className="img-fluid "
                />
              </OverlayTrigger>
              {
                user.isOnline ? (
                  <div className="opr-status-lg status-online">
                  </div>
                )
                  :
                  (
                    <div className="opr-status-lg status-offline">
                    </div>
                  )

                // user.isOnline == OperatorStatusType["1"] ? (
                //   <div className="opr-status-lg status-online">

                //   </div>
                // )
                //   :
                //   user.isOnline == OperatorStatusType["2"] ? (
                //     <div className="opr-status-lg status-offline">

                //     </div>
                //   )

                //     :
                //     user.isOnline == OperatorStatusType["3"] ? (
                //       <div className="opr-status-lg status-incall">

                //       </div>
                //     ) :
                //     user.isOnline == OperatorStatusType["4"] ?
                //       (
                //         <div className="opr-status-lg status-busy">

                //         </div>
                //       ) 
                //       : null
              }
            </div>

            <div className="col col-contact-name">
              <div className="row ">
                <div className="col-12 pl-0 ">
                  <p className="mb-0 text-truncate">{user.label}</p>
                  {/* <div className="row border-bottom "></div> */}
                </div>
              </div>
            </div>
            <div className="col-12">
              <div className="row">
                <div className="empty-image-width"></div>
                <div className="col">
                  <div className="border-bottom row mt-1"></div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>

      <div className="col-12 pt-2 border-top text-center">
        <button className="btn btn-primary add-participant-btn"
          type="button"
          onClick={editGroup ? onAddParticipantHandler : onCreateHandler}
        >
          {editGroup ? "Ok" : "Create"}
        </button>
        <button className="btn btn-link ml-3 text-site font-500"
          type="button"
          onClick={() => setCreate(false)}
        >
          Cancel
        </button>
      </div>


    </>

  );
}
