import React, { useEffect, useState } from "react";
import { OutgoingText, IncomingText } from ".";
import { ChannelContext, useAppSelector } from "../../../../core";
import { shallowEqual } from "react-redux";
import { useFunctionalityUser } from '../../../../pages/hooks/functionalityUser';
import { AssetSvg } from "../../../../assets";
import { ApiResponseConversation, ApiResponseParticipant } from "../../../../models";
import { ConversationType, DefaultImageType, FileBucketURL } from "../../../../configs";
import { useFunctionalityChat, useFunctionalityImageView } from "../../../../pages/hooks";
import { ModalConfirmDelete } from "../../..";
import { AllContactList, ChatHome } from "..";
import { CreateGroupContactList } from "../createGroupContactList";
import { VRIAppStateType } from "../../../../core";

type Props = {
    deleteModalShow: any
    setDeleteModalShow: any
    addParticipant: any
    setAddParticipant: any
    groupParticipantList: any
    selectGroupParticipantList: any
}
export function GroupChatDetail(props: Props): JSX.Element {
    const currentUserCompanyId = useAppSelector((state: VRIAppStateType) => state.auth.profile?.companyId);
        const {selectedChannel} = React.useContext(ChannelContext);
        const { imageView , defaultImageLoad} = useFunctionalityImageView();

        const {
            deleteModalShow,  setDeleteModalShow,  addParticipant, setAddParticipant, groupParticipantList, selectGroupParticipantList
        } = props

    const [deleteUserId, setDeleteUserID] = useState<string>("")
    const [deleteMsg, setDeleteMsg] = useState<string>("")
    const [left, setLetft] = useState<boolean>(false)

    const { onRemoveParticipant } = useFunctionalityChat()

    const currentUser = useAppSelector(
        (state: VRIAppStateType) => ({
            uid: state.auth.profile?.id,
        }),
        shallowEqual
    );

   
    const onAddParticipantHandler = () => {
        console.log("Load all contact list")
        setAddParticipant(true)

    }

    const onRemoveParticipantHandler = () => {
        onRemoveParticipant(selectedChannel.sid, deleteUserId, currentUser.uid??"")
            .then(() => {
                setDeleteModalShow(false)
            })
    }

    return (
        <>
            {!addParticipant ?
                <div className="col-12">
                    <div className="row position-sticky sticky-top bg-white">
                        <div className="col-12 text-center py-3 addParticipantblock">
                            <a className="d-flex align-items-center create-new text-decoration-none  justify-content-center my-1 newChatBtn"
                                onClick={onAddParticipantHandler}
                            >
                               <svg width="24" height="24" viewBox="0 0 24 24"><path d="M19,13H13V19H11V13H5V11H11V5H13V11H19V13Z" fill="currentColor" /></svg>
                                <span className=" font-600">Add Participant</span>
                            </a>
                        </div>
                    </div>
                    <div className="row">
                    <div className="col groupMemberMenu">                    
                    {
                        groupParticipantList && groupParticipantList.map((cuteParticipant: ApiResponseParticipant) => {
                            if (cuteParticipant.userId !== currentUser.uid)
                                return <div className="row contacts-chat-list-item align-items-center pb-2"
                                >
                                    <div className="header-profile-image incoming-chat-image col ">
                                        <img
                                           src=  {`${FileBucketURL?.Root}${cuteParticipant?.userId}?${imageView?.fileTimeStamp}`}
                                            alt=""
                                            className="img-fluid "
                                            onError={(ev) => { defaultImageLoad(ev, DefaultImageType.UserProfile, "?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260") }}

                                        />
                                    </div>
                                    <div className="col col-contact-name">
                                        <div className="d-flex">
                                            <div className="flex-grow-1">
                                                <p className="mb-0 text-truncate">
                                                    {cuteParticipant.participantName}
                                                </p>
                                                {/* <p className="mb-0 font-sz-10 text-muted">
                                                    Oct 01, 2021
                                                </p> */}
                                            </div>
                                            <div className="">                                             
                                                <button className="btn btn-link text-site btn-sm text-uppercase" onClick={() => {
                                                    setDeleteUserID(cuteParticipant.userId)
                                                    setDeleteMsg("remove")
                                                    setDeleteModalShow(true)
                                                }}>
                                                    <AssetSvg.Trash />
                                                </button>
                                            </div>
                                            <div className="">
                                                
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="row">
                                            <div className="empty-image-width">

                                            </div>
                                            <div className="col">
                                                <div className="border-bottom row mt-1"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                        })

                    }
                    </div>
                    </div>
                    
                    <div className="row position-sticky sticky-top bg-white">
                        <div className="col-12 text-center py-2 ">
                            <a className="btn btn-primary d-flex align-items-center create-new text-decoration-none justify-content-center" onClick={() => {
                                        setDeleteUserID(currentUser?.uid || "")
                                        setDeleteMsg("leave")
                                        setDeleteModalShow(true)

                                    }} >
                                LEAVE GROUP 
                            </a>
                        </div>
                    </div>

                </div> :
                <CreateGroupContactList searchValue="" setCreate={setAddParticipant} editGroup={true} groupParticipants={groupParticipantList} />
            }
            <ModalConfirmDelete
                show={deleteModalShow} handleShow={setDeleteModalShow}
                deleteHandler={onRemoveParticipantHandler} msg={deleteMsg}
            />
        </>
    );
}
