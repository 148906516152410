import React from "react";
import { shallowEqual } from "react-redux";
import { useAppSelector } from "../../core";
import { CommonUser } from "../../features";
import { VRIAppStateType } from "../../core";

export function PageUsers(): JSX.Element {
  const { companyId, adminRole } = useAppSelector(
    (state: VRIAppStateType) => ({
      companyId: state.auth.profile?.companyId || "",
      adminRole: state.auth.profile?.userRole || ""
    }),
    shallowEqual
  );
   
  return (
    <>
      <CommonUser
        pageTitle="User"
        modalControlBtnLabel="Add User"
        companyId={companyId}
        adminRole={adminRole}
      />
    </>
  );
}
