import React, { useState } from "react";
import { toast } from "react-toastify";
import useSWR, { mutate } from "swr";
import {
  AppConstants,
  AppRouteUi,
  CompanyType,
  InvoiceType,
  PlatformApi,
  topLoaderProgress,
  TransactionType,
} from "../../configs";
import { AxiosAuth, useAppSelector } from "../../core";
import {
  ApiResponse,
  MarkAsPaidRequestModel,
  PagedResponse,
  TransactionResponseModel,
  WithPagination,
} from "../../models";
import { OptionMapper, UtilsJQuery } from "../../utils";
import { VRIAppStateType } from "../../core";

type Props = {
  pageSize?: number;
  pageNo?: number;
  searchText?: string;
  setLoaderProgress?: any;
  onDataLoadCallBack?: any;
  id?: string;
  invoiceType?: InvoiceType;
};

let pagedTransactionUrl: string;

export function useFunctionalityPagedTransaction(props: Props) {
  const { profile } = useAppSelector((state: VRIAppStateType) => state.auth);
  const {
    pageSize,
    pageNo,
    searchText,
    setLoaderProgress,
    onDataLoadCallBack,
    id,
    invoiceType,
  } = props;
  const [pagedTransactionListCount, setPagedTransactionListCount] =
    useState<number>(0);

  if (id && invoiceType)
    pagedTransactionUrl = PlatformApi.Finance.Transaction.PagedGetAllById(
      id,
      invoiceType,
      pageSize,
      pageNo,
      searchText
    );
  else
    pagedTransactionUrl = PlatformApi.Finance.Transaction.PagedGetAll(
      pageSize,
      pageNo,
      searchText,
      profile?.companyId
    );
  // cache.clear();
  const { data: apiPagedTransactionList } = useSWR<TransactionResponseModel[]>(
    pagedTransactionUrl,
    () => {
      setLoaderProgress(topLoaderProgress.start);
      return AxiosAuth.get<
        ApiResponse<PagedResponse<TransactionResponseModel>>
      >(pagedTransactionUrl)
        .then((r) => {
          setLoaderProgress(topLoaderProgress.complete);
          setPagedTransactionListCount(r.data.data?.count);
          if (onDataLoadCallBack) onDataLoadCallBack(r.data.data.data);
          return r.data.data?.data;
        })
        .catch((e) => {
          setLoaderProgress(topLoaderProgress.complete);
          console.error(e);
          return e;
        });
    }
  );
  // const pagedTransactionList = React.useMemo(() => {
  //   const data = Array.isArray(apiPagedTransactionList)
  //     ? apiPagedTransactionList
  //     : [];
  //   return data;
  // }, [apiPagedTransactionList]);
  return {
    pagedTransactionList: apiPagedTransactionList,
    pagedTransactionListCount,
  };
}
type TransactionProps = {
  transactionType: TransactionType;
};
export function useFunctionalityTransaction(props: TransactionProps) {
  // ##### Never Used

  // const { transactionType } = props;
  // const locationUrl = PlatformApi.Finance.Transaction.GetAll();

  // const { data: apiTransactionList } = useSWR<TransactionResponseModel[]>(
  //   locationUrl,
  //   () =>
  //     AxiosAuth.get<ApiResponse<WithPagination<TransactionResponseModel[]>>>(
  //       locationUrl
  //     )
  //       .then((r) => r.data.data?.data)
  //       .catch((e) => {
  //         console.error(e);
  //         return e;
  //       })
  // );

  // const optionMappedTransactionList = React.useMemo(() => {
  //   const data = Array.isArray(apiTransactionList)
  //     ? OptionMapper.mapperFunc(apiTransactionList)
  //     : [];
  //   return data;
  // }, [apiTransactionList]);

  let markAsPaidUrl = "";
  markAsPaidUrl = PlatformApi.Finance.Transaction.MarkAsPaid();
  const onMarkAsPaid = React.useCallback(
    async (form: MarkAsPaidRequestModel) => {
      const btnLoading = UtilsJQuery.Ladda(".btn-mark-as-paid");
      btnLoading.start?.();
      try {
        const response = await AxiosAuth.post(markAsPaidUrl, form);

        if (response.status === AppConstants.Api.StatusOK) {
          toast.success("Transaction added successfully");
          // mutate(locationUrl); --> Never Used
          mutate(pagedTransactionUrl);
        }
      } catch (e) {
        toast.error("Failed to Save Transaction.");
        btnLoading.stop?.();
        return Promise.reject(e);
      }
    },
    []
  );

  return {
    // optionMappedTransactionList, --> Never Used
    onMarkAsPaid,
  };
}
