import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useRef, useState } from 'react';
import { ActionVideoCall, useAppDispatch, useAppSelector } from '../../../core';
import { UserMediaUtils } from '../../../utils';
import { VRIAppStateType } from "../../../core";

export function MediaPreviewer() {
  const dispatch = useAppDispatch();
  const videoCallTracks = useAppSelector((state: VRIAppStateType) => state.videoCall.tracks);
  const videoTrack = videoCallTracks?.filter(
    (track) => track.kind === 'video'
  )[0];
  const videoElementRef = useRef<HTMLVideoElement | null>(null);
  const [showPreview, setShowPreview] = useState(false);

  const handlePreviewClick = async () => {
    if (!videoCallTracks) {
      const tracks = await UserMediaUtils.startMedia();
      tracks && dispatch(ActionVideoCall.setTracks(tracks));
    }
    setShowPreview((previous) => !previous);
  };

  useEffect(() => {
    if (!videoTrack) {
      return;
    }
    videoElementRef.current!.srcObject = new MediaStream([videoTrack]);
  }, [videoTrack]);

  return (
    <div id="media-previewer">
      <button
        onClick={handlePreviewClick}
        className="btn btn-secondary"
        title="Preview Camera"
      >
        <FontAwesomeIcon icon={faInfoCircle} />
      </button>
      <div className={`media-container ${showPreview ? 'd-block' : 'd-none'}`}>
        <video autoPlay ref={videoElementRef} />
        <p className="text-muted">
          If your camera isn't showing, close other apps using it and ensure
          your microphone is connected.
        </p>
      </div>
    </div>
  );
}
