import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import LoadingBar from "react-top-loading-bar";
import { mutate } from "swr";
import { AssetSvg } from "../../assets";
import { PaginationSettings } from "../../configs";
import { useAppSelector } from "../../core";
import { AdminPageTemplate, ColumnToolTip, ModalConfirmDelete, ModalLanguagePack, } from "../../features";
import { ModalLanguagePackV2 } from "../../features/modals/languagePackModalV2";
import { ApiSchemaLanguagePackData } from "../../models";
import { useFunctionalityLanguagePack, useFunctionalityPagedLanguagePack, } from "../hooks/functionalityLanguagePack";
import { VRIAppStateType } from "../../core";

type Props = {
  parentCompanyId?: string;
};
export function PageLanguagePacks(props: Props): JSX.Element {
  const { parentCompanyId } = props;
  const [show, setShow] = useState(false);
  const [deleteModalShow, setDeleteModalShow] = useState(false);
  let companyId = useAppSelector((state: VRIAppStateType) => state.auth.profile?.companyId);
  companyId = parentCompanyId ?? companyId;
  const [loaderProgress, setLoaderProgress] = useState(0);
  const [dataToEdit, setDataToEdit] = React.useState<ApiSchemaLanguagePackData>(Object);
  const [dataToDelete, setDataToDelete] = useState<string[]>([]);
  const [searchText, setSearchText] = useState("");
  const [pageIndex, setPageIndex] = useState(PaginationSettings.initialPageIndex);
  const [pageSize, setPageSize] = useState(PaginationSettings.initialPageSize);
  const [isCopyLanguagePack, setIsCopyLanguagePack] = useState(false);
  const [modalKey, setModalKey] = useState(Date.now() + "");
  const [hasPagingData, setHasPagingData] = useState<boolean>(false);
  const [onUpdateSuccess, setOnUpdateSuccess] = useState(false);

  const handleSearch = (e: any) => {
    ReactDOM.unstable_batchedUpdates(() => {
      setSearchText(e.target.value);
      setPageIndex(1);
    });
  };
  const onChangePageIndex = (index: number) => {
    setPageIndex(index);
  };
  const onChangePageSize = (size: number) => {
    setPageSize(size);
  };

  const { onDelete } = useFunctionalityLanguagePack({
    companyId: companyId ?? "",
  });

  const {
    pagedLanguagePackList,
    pagedLanguagePackListCount,
    pagedLanguagePackUrl,
  } = useFunctionalityPagedLanguagePack({
    pageSize: pageSize,
    pageNo: pageIndex,
    searchText: searchText?.trimStart(),
    setLoaderProgress,
    companyId: companyId ?? "",
  });

  const deleteHandler = () => {
    onDelete(dataToDelete)
      .then(() => {
        setDeleteModalShow(false);
        setOnUpdateSuccess(!onUpdateSuccess);
      })
      .catch((e) => console.error(e));
  };

  useEffect(() => {
    if (pagedLanguagePackList?.length) {
      setHasPagingData(true);
    } else {
      setHasPagingData(false);
    }
  }, [pagedLanguagePackList]);

  const onCopyHandler = (pack: ApiSchemaLanguagePackData) => {
    const copyLanguagePack = { ...pack, name: "Copy of ".concat(pack.name) };

    setDataToEdit(copyLanguagePack);
    setIsCopyLanguagePack(true);
    setShow(true);
  };

  useEffect(() => {
    if (onUpdateSuccess) {
      mutate(pagedLanguagePackUrl);
      setOnUpdateSuccess(!onUpdateSuccess)
    }
  }, [onUpdateSuccess]);

  const languagePackTableColumns = [
    {
      name: <ColumnToolTip data="Name" />,
      selector: (row: ApiSchemaLanguagePackData) => {
        return <ColumnToolTip data={row.name} />;
      },
    },
    {
      name: <ColumnToolTip data="Languages" />,
      width:"350px",
      selector: (row: ApiSchemaLanguagePackData) => {
        return (
          <ColumnToolTip
            data={row.languageRates
              .map((lang: any) => lang.languageName)
              .join(", ")}
          />
        );
      },
    },
    {
      name: <ColumnToolTip data="Number Of Languages" />,
      selector: (row: ApiSchemaLanguagePackData) => {
        return <ColumnToolTip data={row.languageRates.length} />;
      },
    },
    {
      name: <ColumnToolTip data="Flat Rate" />,
      selector: (row: ApiSchemaLanguagePackData) => {
        return <ColumnToolTip data={row?.flatRate?.toFixed(2)} />;
      },
    },
    {
      name: <ColumnToolTip data="Actions" />,
      allowOverflow: true,
      right:true,
      selector: (row: ApiSchemaLanguagePackData) => {
        return (
          <>
            <div className="btn">
              <div
                className="btn svg-color"
                title="languagePackEdit"
                onClick={() => {
                  setDataToEdit(row);
                  setIsCopyLanguagePack(false);
                  setShow?.(true);
                }}
              >
                <span >
                  <AssetSvg.Edit />
                </span>
              </div>
            </div>
            <div
              className="btn svg-color"
              title="languagePackCopy"
              onClick={() => {
                setIsCopyLanguagePack(true);
                onCopyHandler(row);
              }}
            >
              <span >
                <AssetSvg.CopePaste />
              </span>
            </div>

            <div className="btn">
              <div
                className="btn svg-color"
                title="Delete"
                onClick={() => {
                  setDeleteModalShow?.(true);
                  setDataToDelete((prev) => [...prev, row.id]);
                }}
              >
                <span >
                  <AssetSvg.Delete />
                </span>
              </div>
            </div>
          </>
        );
      },
    },
  ];

  return (
    <>
      <LoadingBar
        progress={loaderProgress}
        onLoaderFinished={() => setLoaderProgress(0)}
      />
      <AdminPageTemplate
        loaderProgress={loaderProgress}
        pageTitle="Language Pack"
        modalControlBtnLabel="Add a Package"
        tableHeaders={languagePackTableColumns}
        tableData={pagedLanguagePackList ?? []}
        setCreateModalShow={(value) => {
          setDataToEdit(Object.assign({}));
          setShow(value);
          setModalKey(Date.now() + "");
        }}
        searchText={searchText}
        handleSearch={handleSearch}
        pageIndex={pageIndex}
        setPageIndex={setPageIndex}
        pageSize={pageSize}
        count={pagedLanguagePackListCount}
        onChangePageIndex={onChangePageIndex}
        onChangePageSize={onChangePageSize}
        hasPagingData={hasPagingData}
        setHasPagingData={setHasPagingData}
        isHideAddButton={true}
      />
      {show && (
        <ModalLanguagePackV2
          key={modalKey}
          show={show}
          handleShow={(value) => {
            setShow(value);
            setModalKey(Date.now() + "");
          }}
          dataToEdit={dataToEdit}
          companyId={companyId ?? ""}
          onUpdateSuccess={onUpdateSuccess}
          setOnUpdateSuccess={setOnUpdateSuccess}
          //setDataToEdit={setDataToEdit}
          isCopyLanguagePack={isCopyLanguagePack}
        />
      )}
      <ModalConfirmDelete
        show={deleteModalShow}
        handleShow={setDeleteModalShow}
        deleteHandler={deleteHandler}
      />
    </>
  );
}
