import React from "react";
import { createRoot } from "react-dom/client";
import ZoomVideo from '@zoom/videosdk';

import * as Sentry from "@sentry/react";
import "react-datepicker/dist/react-datepicker.css";
import { Provider } from "react-redux";
import { SWRConfig } from "swr";
import App from "./App";
import { VRIAppStore } from "./core";
import reportWebVitals from "./reportWebVitals";
import "./scss/style.scss";
import AppStateProvider, { useAppState } from "./state";

import ZoomContext from "./contexts/zoom-context";
import { MeetingArgsProvider } from "./contexts/meetingArgs-context";

// import { useZoomVideo } from "./components/VideoProvider/useZoomVideo/useZoomVideo";
import { getExploreName } from "./utils/platform";


Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_API,
  integrations: [Sentry.browserTracingIntegration()],
  tracesSampleRate: 1.0,
});

const devConfig = {
  sdkKey: '',
  sdkSecret: '',
  webEndpoint: 'zoom.us', // zfg use www.zoomgov.com
  topic: '',
  name: `${getExploreName()}-${Math.floor(Math.random() * 1000)}`,
  password: '',
  signature: '',
  sessionKey: '',
  userIdentity: '',
  // The user role. 1 to specify host or co-host. 0 to specify participant, Participants can join before the host. The session is started when the first user joins. Be sure to use a number type.
  role: 1
};


const VriApp = () => {
  // const { setError } = useAppState();
  // const connectionOptions = useConnectionOptions();

  return (
    <Provider store={VRIAppStore}>
      <MeetingArgsProvider devConfig={devConfig}> {/* devConfig here is merely an initialization = we can keep it, but it shouldn't be used once we enter a real call. A real call gets its parameters from the websocket. */}
        <App />
      </MeetingArgsProvider>
    </Provider>
  );
};


const swrOptions = {
  revalidateIfStale: false,
  revalidateOnFocus: false,
  revalidateOnReconnect: false,
  shouldRetryOnError: false,
};

const zmClient = ZoomVideo.createClient(); 
console.log("GTA-logging from inside src/index.tsx:");
console.log(zmClient);

const container = document.getElementById("root");
const root = createRoot(container!);

root.render(
  <React.StrictMode>
    <AppStateProvider>
    <ZoomContext.Provider value={zmClient}>
      <SWRConfig value={swrOptions}>
        <VriApp />
      </SWRConfig>
      </ZoomContext.Provider>
    </AppStateProvider>
  </React.StrictMode>
);
reportWebVitals();