import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from 'react-router-dom';
import { toast } from "react-toastify";
import { AssetSvg } from "../../../../assets";
import {
  AppRouteUi,
  CallInitiateTabs,
  CallStatusType,
  CallType,
} from "../../../../configs";
import { ActionAudioCall, ActionVideoCall, useAppSelector } from "../../../../core";
import {
  AudioCallInitiateParameter,
  PlatformType,
  videoCallInitiateParameter,
} from "../../../../models";
import { ApiSchemaFilterOperatorRequest } from "../../../../models/api/Operator";
import { logger, MediaPermission } from "../../../../utils";
import { VRIAppStateType } from "../../../../core";

type Props = {
  videoCallInitiateParam?: videoCallInitiateParameter;
  audioCallInitiateParam?: AudioCallInitiateParameter;
  operatorFilterParam?: ApiSchemaFilterOperatorRequest;
  callInitiateTab: string;
  directCallSelectedUserId?: string;
  isFormSubmitSuccess: boolean;
  initiateTab?: any;
  companyAccountStatus?: boolean;
  isMediaAllowed?: boolean;
};

export default function CallInitiateButtonSearchInterpreter(
  props: Props
): JSX.Element {
  const {
    operatorFilterParam,
    callInitiateTab,
    videoCallInitiateParam,
    directCallSelectedUserId,
    audioCallInitiateParam,
    isFormSubmitSuccess,
    initiateTab,
    companyAccountStatus,
    isMediaAllowed,
  } = props;
  const [initiateCallType, setInitiateCallType] = useState<CallType>(0);
  const { twilioDeviceStatus, isBackdatedVersion } = useAppSelector((state: VRIAppStateType) => state.common);
  const dispatch = useDispatch();
  const history = useHistory();
  const handlePhoneCall = async() => {
    if (twilioDeviceStatus === 'offline') {
      toast.error(
        'Please, try again after few seconds'
      );
      return;
    }
    if (!(await MediaPermission.getStatus({audio: true}))) {
      toast.error("You have no microphone access");
    } else {
      history.push({
        pathname: AppRouteUi.IVROutgoingCall.Root,
        state: {
          isValidCallFlow: true,
        },
      });
    }
  };

  useEffect(() => {
    if (isFormSubmitSuccess) {
      if (twilioDeviceStatus === 'offline') {
        logger({ message: 'Twilio device is offline. Returning to home.'});
        toast.error(
          'Please, try again after few seconds'
        );
        return;
      }
      if (!companyAccountStatus) {
        history.push({
          pathname: AppRouteUi.VideoOutgoingCall.Root,
          state: {
            operatorFilterParam: operatorFilterParam,
            callInitiateTab: callInitiateTab,
            videoCallInitiateParam: videoCallInitiateParam,
            directCallSelectedUserId: directCallSelectedUserId,
            callType: CallType.Video,
          },
        });
      } else if (!isMediaAllowed) {
        history.push({
          pathname: AppRouteUi.OutgoingCallFailedForMedia.Root,
        });
      } else {
        logger({ message: 'Searching Operator'});
        if (initiateCallType === CallType.Video) {
          dispatch(
            ActionVideoCall.SetCallStatus({
              prev: CallStatusType.Zero,
              current: CallStatusType.Searching,
            })
          );

          history.push({
            pathname: AppRouteUi.VideoOutgoingCall.Root,
            state: {
              operatorFilterParam: operatorFilterParam,
              callInitiateTab: callInitiateTab,
              videoCallInitiateParam: videoCallInitiateParam,
              directCallSelectedUserId: directCallSelectedUserId,
              callType: CallType.Video,
            },
          });
        } else if (initiateCallType === CallType.Audio) {
          dispatch(
            ActionAudioCall.SetAudioCallStatus({
              prev: CallStatusType.Zero,
              current: CallStatusType.Searching,
            })
          );

          history.push({
            pathname: AppRouteUi.IVROutgoingCall.Root,
            state: {
              operatorFilterParam: operatorFilterParam,
              callInitiateTab: callInitiateTab,
              audioCallInitiateParam: audioCallInitiateParam,
              directCallSelectedUserId: directCallSelectedUserId,
              callType: CallType.Audio,
              isValidCallFlow: true,
            },
          });
        }
      }
    }
  }, [isFormSubmitSuccess, isMediaAllowed]);

  return (
    <div className="row search-interpreter-button-wrapper">
      {callInitiateTab !== CallInitiateTabs.directCall &&
        initiateTab === PlatformType.OPI && (
          <div className="col width-content phone-call m-auto">
            <button
              className="btn phone-call-btn-ctl mx-xl-3 mx-lg-3 mx-md-3 mx-sm-3 mx-0 my-xl-0 my-lg-0 my-md-0 my-sm-2 my-2 vri-call-btn-submit p-0"
              onClick={(event)=> {
                event.preventDefault();
                handlePhoneCall();
              }}
              disabled={isBackdatedVersion}
            >
              <div className="d-flex align-items-center justify-content-center">
                <div className="text-white bg-green-light">
                  <AssetSvg.PhoneCall />
                </div>
                <p className="ml-2 my-0">Phone Call</p>
              </div>
            </button>
          </div>
        )}

      {initiateTab === PlatformType.VRI && (
        <div className="width-content mx-auto">
          <button
            type="submit"
            className="btn video-call-btn-ctl mx-xl-3 mx-lg-3 mx-md-3 mx-sm-3 mx-0 my-xl-0 my-lg-0 my-md-0 my-sm-2 my-2 vri-call-btn-submit p-0"
            onClick={() => {
              setInitiateCallType(CallType.Video);
            }}
            disabled={isBackdatedVersion}
          >
            <div className="d-flex align-items-center justify-content-center ">
              <div className="bg-purple text-white">
                <AssetSvg.VideoCamera />
              </div>
              <p className="ml-2 my-0"> Video Call </p>
            </div>
          </button>
        </div>
      )}
    </div>
  );
}
