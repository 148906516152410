import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import Select from "react-select";
import { FormErrorMessage } from "../../components";
import { ApiSchemaUpdateUser, ApiSchemaUserDetailsData, Option } from "../../models";
import { useFunctionalityCountry, useFunctionalityState, useFunctionalityZipcodeValidation } from "../../pages/hooks";
import { timeZones } from "./jobFormData";
import { useAppSelector } from "../../core";
import { AppConstants, DefaultCountry, InputFieldValidationErrorMessage, USAPhoneNumberValidationRegex } from "../../configs";
import { useFunctionalityInputChecker } from "../../pages/hooks/functionalityInputChecker";
import { useFunctionalityAddressFieldValidation } from "../../utils";
import { education } from "../../configs/AppJson/EducationJson";
import { VRIAppStateType } from "../../core";

type Props = {
  show: boolean;
  userDetailsData?: ApiSchemaUserDetailsData;
  handleShow: (value: boolean) => void;
  onUpdateHandler: any;
  companyId?: string;
  optionMappedLocationList?: any[];
  optionMappedDepartmentList?: any[];
  changeCountry?: (country: string) => void;
  initialStateList?: any;
  genders?: any;
};

export function ModalEditUserInfo(props: Props): JSX.Element {
  // @ts-ignore
  const { register, handleSubmit, formState: { errors }, setValue } = useForm<ApiSchemaUpdateUser>();
  const { genders, show, handleShow, userDetailsData, onUpdateHandler, optionMappedLocationList, optionMappedDepartmentList, initialStateList } = props;
  const { countryList } = useFunctionalityCountry(show);
  const [CountryFieldkey, setCountryFieldKey] = useState(Date.now());
  const [StateFieldkey, setStateFieldKey] = useState(Date.now());
  const [selectedCountry, setSelectedCountry] = React.useState(userDetailsData?.country ? userDetailsData?.country : DefaultCountry.USA);
  const { handleSpecialCharacterWhiteSpaces, useFunctionlityInputFieldValidation } = useFunctionalityInputChecker();
  const { stateList } = useFunctionalityState(selectedCountry, show);
  const [numberErrMsg, setNumberErrMsg] = React.useState("");
  const [alterNumberErrMsg, setAlterNumberErrMsg] = React.useState("");
  const [selectedZipcode, setSelectZipcode] = React.useState<string>(userDetailsData?.zip ? userDetailsData?.zip : "");
  const stateName = initialStateList.find((x: any) => x.value === userDetailsData?.state);
  const [selectedState, setSelectedState] = React.useState<string>(userDetailsData?.state ?? "");
  const [selectedStateName, setSelectedStateName] = React.useState<string>(stateName?.label);
  useEffect(() => {
    setStateFieldKey(Date.now())
  }, [stateList]);
  useEffect(() => {
    setCountryFieldKey(Date.now())
  }, [countryList]);
  const userRole = useAppSelector((state: VRIAppStateType) => state.auth.profile?.userRole);
  const isOperator = userRole === AppConstants.UserRoles.Operator;

  const onCountryChangeHandler = (value: Option | null) => {
    setSelectedCountry(value ? value.value : "");
    setValue("countryId", value?.value, {
      shouldValidate: true,
    });

    if (value?.value) props.changeCountry?.(value?.value);
  };

  const validateTeNumber = (e: any, type: number) => {
    let phoneNumber = e.target.value;
    let phoneNumberForRegexCheck = phoneNumber.replace(/\s+/g, "");
    if (phoneNumber === "" && type == 0) {
      setNumberErrMsg("");
      return;
    } else if (phoneNumber === "" && type == 1) {
      setAlterNumberErrMsg("");
      return;
    }
    if (phoneNumberForRegexCheck?.match(USAPhoneNumberValidationRegex)) {
      if (type == 0) {
        setNumberErrMsg("");
      } else {
        setAlterNumberErrMsg("");
      }
    } else {
      if (type == 0) {
        setNumberErrMsg("Please enter a valid contact number");
        return;
      } else {
        setAlterNumberErrMsg("Please enter a valid contact number");
        return;
      }
    }

    const phoneNo = document?.getElementById("contactNumber") as HTMLInputElement;
    const phoneNoValue = phoneNo.value;
    const altPhnNo = document?.getElementById(
      "alternativePhoneNumber"
    ) as HTMLInputElement;
    var altPhnNoValue = altPhnNo.value;

    if (phoneNoValue == altPhnNoValue) {
      setAlterNumberErrMsg("Please enter two different number");
    } else setAlterNumberErrMsg("");
  };

  const handleState = (event: any) => {
    setSelectedState(event.value);
    setSelectedStateName(event?.label);
  };

  const handleZip = (event: any) => {
    setSelectZipcode(event.target?.value);
    handleZipCode();
  };
  const { handleZipCode, zipCodeError } = useFunctionalityZipcodeValidation({
    selectedCountry,
    selectedState: selectedState ? selectedStateName : stateName?.label,
    selectedZipcode,
  });
  const { handleAddressLength, addressLengthError } =
    useFunctionalityAddressFieldValidation();

  useEffect(() => {
    handleZipCode();
  }, [selectedState, selectedZipcode]);

  useEffect(() => { }, [stateList]);
  return (
    <Modal show={show} size="lg" onHide={() => handleShow(false)} centered>
      <form action="" onSubmit={handleSubmit(onUpdateHandler)}>
        {/* @ts-ignore */}
                {/*@ts-ignore */}
        <Modal.Header closeButton>
          <Modal.Title>Edit General Information</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="row">
            <div className="col-12">
              <div className="form-group">
                <label htmlFor="" className="position-relative required">
                  Email Address
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Email"
                  {...register('email', { required: true })}
                  defaultValue={userDetailsData?.email}
                  readOnly={true}
                  style={{ border: errors.email ? "1px solid red" : "" }}
                />
                {errors.email && <FormErrorMessage message="required" />}
              </div>
              <div className="form-group">
                <div className="row">
                  <div className="col">
                    <label htmlFor="" className="position-relative required">
                      First Name
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="First Name"
                      {...register('firstName', {
                        required: true,
                        // validate: handleSpecialCharacterWhiteSpaces, // Custom validation function can be used here
                        //@ts-ignore
                        validate: useFunctionlityInputFieldValidation // Custom validation function
                      })}
                      defaultValue={userDetailsData?.firstName}
                      style={{
                        border: errors.firstName ? "1px solid red" : "",
                      }}
                    />
                    {errors.firstName && errors.firstName.type == "validate" && (<FormErrorMessage message={InputFieldValidationErrorMessage.ErrorMessage} />)}
                    {errors.firstName && errors.firstName.type == "required" && (<FormErrorMessage message="required" />)}
                  </div>
                  <div className="col">
                    <label
                      htmlFor="lastName"
                      className="position-relative required"
                    >
                      Last Name
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Last Name"
                      {...register('lastName', {
                        required: true,
                        //@ts-ignore
                        validate: useFunctionlityInputFieldValidation // Custom validation function
                        // validate: handleSpecialCharacterWhiteSpaces,
                      })}
                      defaultValue={userDetailsData?.lastName}
                      style={{ border: errors.lastName ? "1px solid red" : "" }}
                    />
                    {errors.lastName && errors.lastName.type == "validate" && (
                      <FormErrorMessage message={InputFieldValidationErrorMessage.ErrorMessage} />)}
                    {errors.lastName && errors.lastName.type == "required" && (
                      <FormErrorMessage message="required" />)}
                  </div>
                </div>
              </div>
              <div className="form-group">
                <div className="row">
                  {userRole !== AppConstants.UserRoles.Operator &&
                    optionMappedDepartmentList &&
                    optionMappedDepartmentList?.length > 0 && (
                      <div className="col">
                        <label htmlFor="" className="position-relative ">
                          Department
                        </label>
                        <input
                          className="d-none"
                          {...register('departmentId', {})}
                          defaultValue={
                            userDetailsData?.departmentId
                              ? userDetailsData?.departmentId
                              : ""
                          }
                        />
                        <Select
                          placeholder="Select Department"
                          options={optionMappedDepartmentList}
                          defaultValue={
                            userDetailsData?.departmentId
                              ? optionMappedDepartmentList?.find(
                                (x: { value: string | undefined }) =>
                                  x.value === userDetailsData?.departmentId
                              )
                              : " "
                          }
                          components={{
                            IndicatorSeparator: () => null,
                          }}
                          onChange={(value) => {
                            setValue("departmentId", value?.value, {
                              shouldValidate: true,
                            });
                          }}
                        />
                      </div>
                    )}

                  {userRole !== AppConstants.UserRoles.Operator &&
                    optionMappedLocationList &&
                    optionMappedLocationList?.length > 0 && (
                      <div className="col">
                        <label htmlFor="" className="position-relative ">
                          Location
                        </label>
                        <input
                          className="d-none"
                          {...register('locationId', {})}
                          defaultValue={userDetailsData?.locationId}
                        />
                        <Select
                          placeholder="Select Location"
                          options={optionMappedLocationList}
                          defaultValue={
                            userDetailsData?.locationId
                              ? optionMappedLocationList?.find(
                                (x: { value: string | undefined }) =>
                                  x.value === userDetailsData?.locationId
                              )
                              : null
                          }
                          components={{
                            IndicatorSeparator: () => null,
                          }}
                          onChange={(value) => {
                            setValue("locationId", value?.value, {
                              shouldValidate: true,
                            });
                          }}
                        />
                      </div>
                    )}
                </div>
              </div>

              <div className="form-group">
                <label htmlFor="" className="position-relative required">
                  Address
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Address"
                  {...register('address', {
                    required: true,
                    minLength: 2,
                    maxLength: 128,
                    // validate: handleSpecialCharacterWhiteSpaces
                  })}
                  defaultValue={userDetailsData?.address}
                  style={{
                    border:
                      errors.address || addressLengthError
                        ? "1px solid red"
                        : "",
                  }}
                  onChange={handleAddressLength}
                />
                {/* {
                  errors.address && errors.address.type === "validate" && (
                    <FormErrorMessage message="Please enter valid value" />
                  )
                } */}
                {errors.address && errors.address.type === "required" && (
                  <FormErrorMessage message="required" />
                )}
                {addressLengthError && (
                  <FormErrorMessage message={addressLengthError} />
                )}
              </div>
              <div className="form-group">
                <div className="row">
                  <div className="col">
                    <label htmlFor="" className="position-relative required">
                      City
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="City"
                      defaultValue={userDetailsData?.city}
                      {...register('city', {
                        required: true,
                        validate: handleSpecialCharacterWhiteSpaces,
                      })}
                      style={{ border: errors.city ? "1px solid red" : "" }}
                    />
                    {errors.city && errors.city.type == "validate" && (
                      <FormErrorMessage message="Please enter valid value" />
                    )}
                    {errors.city && errors.city.type == "required" && (
                      <FormErrorMessage message="required" />
                    )}
                  </div>
                  <div className="col">
                    <label htmlFor="" className="position-relative required">
                      State
                    </label>
                    <input
                      className="d-none"
                      {...register('stateId', {
                        required: true,
                      })}
                      defaultValue={userDetailsData?.state}
                      style={{ border: errors.stateId ? "1px solid red" : "" }}
                    />
                    <Select
                      key={StateFieldkey}
                      className={` ${errors.stateId ? "field-required" : ""} `}
                      placeholder="Select state"
                      defaultValue={
                        selectedState
                          ? stateList.find((x) => x.value === selectedState)
                          : null
                      }
                      isDisabled={stateList.length === 0 ? true : false}
                      components={{
                        IndicatorSeparator: () => null,
                      }}
                      options={
                        selectedCountry !== "" && stateList.length > 0
                          ? stateList
                          : initialStateList
                      }
                      onChange={(value) => {
                        handleState(value);
                        setValue("stateId", value?.value, {
                          shouldValidate: true,
                        });
                      }}
                    />
                    {errors.stateId && <FormErrorMessage message="required" />}
                  </div>
                </div>
              </div>

              <div className="form-group">
                <div className="row">
                  <div className="col">
                    <label htmlFor="" className="position-relative required">
                      Zip
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Zip"
                      defaultValue={userDetailsData?.zip}
                      {...register('zipCode', {
                        required: true,
                        validate: handleSpecialCharacterWhiteSpaces,
                      })}
                      style={{
                        border:
                          errors.zipCode || zipCodeError ? "1px solid red" : "",
                      }}
                      onChange={(event) => {
                        handleZip(event);
                      }}
                    />
                    {errors.zipCode && errors.zipCode.type == "validate" && (
                      <FormErrorMessage message="Please enter valid value" />
                    )}
                    {zipCodeError && selectedCountry === DefaultCountry.USA && (
                      <FormErrorMessage message={zipCodeError} />
                    )}
                    {errors.zipCode && errors.zipCode.type == "required" && (
                      <FormErrorMessage message="required" />
                    )}
                  </div>
                  <div className="col">
                    <label htmlFor="" className="position-relative required">
                      Country
                    </label>
                    <input
                      className="d-none"
                      {...register('countryId', {
                        required: true,
                      })}
                      defaultValue={
                        userDetailsData?.country ?? DefaultCountry.USA
                      }
                      style={{
                        border: errors.countryId ? "1px solid red" : "",
                      }}
                    />
                    <Select
                      key={CountryFieldkey}
                      className={` ${errors.countryId ? "field-required" : ""
                        } `}
                      placeholder="Select country"
                      isDisabled={countryList.length === 0 ? true : false}
                      defaultValue={
                        userDetailsData?.country
                          ? countryList.find(
                            (x) => x.value === userDetailsData?.country
                          )
                          : countryList?.find(
                            (x: { value: string }) =>
                              x.value === DefaultCountry.USA
                          )
                      }
                      components={{
                        IndicatorSeparator: () => null,
                      }}
                      options={countryList}
                      onChange={(value) => {
                        setValue("stateId", "", {
                          shouldValidate: true,
                        });
                        onCountryChangeHandler(value);
                      }}
                    />
                    {errors.countryId && (
                      <FormErrorMessage message="required" />
                    )}
                  </div>
                </div>
              </div>

              <div className="form-group row">
                <div className="col-12">
                  <label htmlFor="" className=" position-relative required">
                    Time Zone
                  </label>
                </div>
                <div className="col-6">
                  <input
                    className="d-none"
                    defaultValue={userDetailsData?.timeZone ? timeZones.find((x) => x.value === userDetailsData?.timeZone)?.value : ""}
                    {...register('timeZone', { required: true })} 
                    style={{ border: errors.timeZone ? "1px solid red" : "" }}
                  />
                  <Select
                    className={` ${errors.timeZone ? "field-required" : ""} `}
                    placeholder="Select Time Zone"
                    components={{
                      IndicatorSeparator: () => null,
                    }}
                    options={timeZones}
                    defaultValue={
                      userDetailsData?.timeZone
                        ? timeZones.find(
                          (x) => x.value === userDetailsData?.timeZone
                        )
                        : null
                    }
                    onChange={(value) => {
                      setValue("timeZone", value?.value, {
                        shouldValidate: true,
                      });
                    }}
                  />
                  {errors.timeZone && <FormErrorMessage message="required" />}
                </div>
              </div>

              <div className="form-group">
                <div className="row">
                  <div className="col">
                    <label htmlFor="" className="position-relative ">
                      Phone Number
                    </label>
                    <input
                      id="contactNumber"
                      type="text"
                      className="form-control"
                      placeholder="+1-555-666-7777"
                      {...register('contactNumber', {
                        onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
                          validateTeNumber(event, 0);
                        }
                      })}
                      defaultValue={userDetailsData?.phoneNumber}
                      style={{ border: numberErrMsg ? "1px solid red" : "" }}
                    />
                    {numberErrMsg && (
                      <FormErrorMessage message={numberErrMsg} />
                    )}

                    {/* phone number and alternative numbers arent coming from input fiels...nedds to chech tomorrow */}
                  </div>
                  <div className="col">
                    <label htmlFor="" className="position-relative ">
                      Alternative Phone Number
                    </label>
                    <input
                      type="text"
                      id="alternativePhoneNumber"
                      className="form-control"
                      placeholder="+1-555-666-7777"
                      {...register('alternativePhoneNumber', {
                        onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
                          validateTeNumber(event, 1);
                        }
                      })}
                      defaultValue={userDetailsData?.alternativePhoneNumber}
                      style={{
                        border: alterNumberErrMsg ? "1px solid red" : "",
                      }}
                    />
                    {alterNumberErrMsg && (
                      <FormErrorMessage message={alterNumberErrMsg} />
                    )}
                  </div>
                </div>
              </div>
              <div className="form-group">
                <div className="row">
                  <div className="col">
                    <label htmlFor="" className="position-relative required">
                      Education
                    </label>
                    <input
                      className="d-none"
                      {...register('education', {
                        required: true,
                      })}
                      defaultValue={userDetailsData?.education}
                      style={{
                        border: errors.education ? "1px solid red" : "",
                      }}
                    />
                    <Select
                      className={` ${errors.education ? "field-required" : ""
                        } `}
                      placeholder="Select Education"
                      defaultValue={
                        userDetailsData?.education
                          ? education.find(
                            (x) => x.value === userDetailsData?.education
                          )
                          : null
                      }
                      components={{
                        IndicatorSeparator: () => null,
                      }}
                      options={education}
                      onChange={(value) => {
                        setValue("education", value?.value, {
                          shouldValidate: true,
                        });
                      }}
                    />
                    {errors.education && (
                      <FormErrorMessage message="required" />
                    )}
                  </div>

                  <div className="col">
                    <label htmlFor="" className="position-relative required">
                      Gender
                    </label>
                    <input
                      className="d-none"
                      {...register('gender', {
                        required: true,
                      })}
                      defaultValue={userDetailsData?.gender}
                      style={{ border: errors.gender ? "1px solid red" : "" }}
                    />
                    <Select
                      className={` ${errors.gender ? "field-required" : ""} `}
                      placeholder="Select gender"
                      options={genders}
                      defaultValue={
                        userDetailsData
                          ? genders?.find(
                            (x: { value: number }) =>
                              x.value == Number(userDetailsData?.gender)
                          )
                          : null
                      }
                      components={{
                        IndicatorSeparator: () => null,
                      }}
                      onChange={(value) => {
                        setValue("gender", value?.value, {
                          shouldValidate: true,
                        });
                      }}
                    />
                    {errors.gender && <FormErrorMessage message="required" />}
                  </div>
                </div>
              </div>

              {
                !isOperator && (
                  <div className="form-group row ">
                    <div className="col-12">
                      <label htmlFor="notes" className="col-12 p-0">
                        Notes
                      </label>
                      <textarea
                        id="notes"
                        {...register('notes', {})}
                        data-resize="none"
                        className="form-control"
                        defaultValue={userDetailsData?.notes}
                      />
                    </div>
                  </div>
                )
              }
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-link text-site text-uppercase"
            onClick={() => {
              handleShow(false);
            }}
          >
            Cancel
          </button>
          <button
            type="submit"
            className="btn btn-primary text-uppercase login-from-submit-btn user-from-submit-btn"
            disabled={
              numberErrMsg == "" && alterNumberErrMsg == "" && zipCodeError == "" ? false : true
            }
          >
            Save
          </button>
        </Modal.Footer>
      </form>
    </Modal>
  );
}
