import { useCallback, useEffect, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Connection } from 'twilio-client';
import { useGetUserTin } from '../../../src/pages/hooks';
import { AssetSvg } from '../../assets';
import {
  DialPad,
  OngoingCallScreen,
  WaitingScreen,
} from '../../components';
import { AppRouteUi, CallStatusType, device } from '../../configs';
import { useAppSelector } from '../../core';
import { logger } from '../../utils';
import { VRIAppStateType } from "../../core";

type TypeDialerPageHistory = {
  isValidCallFlow: boolean;
};

export function DialerPage() {
  const history = useHistory();
  const location = useLocation();
  const isValidCallFlow = (() => {
    try {
      const { isValidCallFlow } = location?.state as TypeDialerPageHistory;
      return isValidCallFlow;
    } catch (error) {
      console.error(error);
      history.push(AppRouteUi.Home.Root);
      return false;
    }
  })();
  const [dialedInput, setDialedInput] = useState('');
  const [isConnected, setIsConnected] = useState(false);
  const [deviceStatus, setDeviceStatus] = useState<'connected' | 'initiated'>(
    'initiated'
  );
  const [readyForPin, setReadyForPin] = useState(false);
  const [isAccepted, setIsAccepted] = useState(false);
  const connectionRef = useRef<Connection>();
  const data = useAppSelector((state: VRIAppStateType) => ({
    audioCallStatus: state.audioCall.audioCallStatus,
    token: state.auth.VRItoken,
    userId: state.auth.profile?.id,
  }));
  const { tinData, isTinFetching } = useGetUserTin(data.userId!, true);
  const dialedInputStyle = {
    minWidth:
      dialedInput.length > 25 ? dialedInput.length * 10 + 'px' : '250px',
  };

  const handleDisconnect = useCallback(() => {
    logger({ message: 'Disconnecting Call' });
    device?.disconnectAll();
    history.push({
      pathname: AppRouteUi.Home.Root,
    });
  }, [history]);

  const enableRemoteAudio = useCallback((enabled: any) => {
    const connection = connectionRef?.current!;
    const stream = connection.getRemoteStream();
    const audioTracks = stream?.getAudioTracks()!;
    if (!audioTracks) {
      return;
    }
    for (const track of audioTracks) {
      track.enabled = enabled;
    }
  }, []);

  useEffect(() => {
    if (isTinFetching || !tinData) {
      return;
    }
    const handleDeviceReady = () => {
      const phoneNumber = process.env.REACT_APP_TWILIO_PHONE_NUMBER!;
      try {
        if (device?.activeConnection()) {
          device?.disconnectAll();
        }
        logger({ message: 'Connecting Phone Number: ', data: phoneNumber });
        connectionRef.current = device?.connect({ to: phoneNumber });
        setReadyForPin(true);
      } catch (error) {
        console.error(error);
      }
    };

    if (!device.isInitialized) {
      logger({ message: 'Device Not Initialized' });
      if (data.token) {
        logger({ message: 'Token Found' });
        device?.setup(data.token);
        device?.on('ready', () => {
          handleDeviceReady();
        });
      } else {
        logger({ message: 'No Token Found' });
        toast.error('No token found for IVR call');
        history.push(AppRouteUi.Home.Root);
        return;
      }
    } else {
      logger({ message: 'Device Found' });
      handleDeviceReady();
    }
    device?.on('connect', () => {
      logger({ message: 'Device Connected' });
      enableRemoteAudio(false);
      setDeviceStatus('connected');
    });
    device?.on('disconnect', () => {
      logger({ message: 'Device Disconnected' });
      handleDisconnect();
    });

    return () => {
      device?.removeAllListeners();
    };
  }, [isTinFetching, tinData, enableRemoteAudio, history, handleDisconnect]);

  useEffect(() => {
    logger({ message: 'Call Status: ', data: data.audioCallStatus });
    if (data.audioCallStatus?.current === CallStatusType.Received) {
      setIsAccepted(true);
    }
  }, [data.audioCallStatus]);

  useEffect(() => {
    if (!isTinFetching && !tinData) {
      toast.error('User TIN not found');
      logger({ message: 'User TIN not found' });
      history.push({
        pathname: AppRouteUi.Home.Root,
      });
    }
  }, [isTinFetching, tinData, history]);

  useEffect(() => {
    if (readyForPin && tinData && deviceStatus === 'connected') {
      const sendUserTIN = () => {
        let index = 0;
        const tin = tinData!;

        const intervalId = setInterval(() => {
          if (index === tin.length) {
            clearInterval(intervalId);
            enableRemoteAudio(true);

            setTimeout(() => {
              setIsConnected(true);
            }, 4000);
          } else {
            logger({ message: 'Sending TIN: ', data: tin[index] });
            device?.activeConnection()?.sendDigits(tin[index]);
            index++;
          }
        }, 1000);
      };

      sendUserTIN();
    }
  }, [readyForPin, tinData, enableRemoteAudio, deviceStatus]);

  if (!isValidCallFlow) {
    return <></>;
  }

  return (
    <div className="dialer-page">
      {!isConnected ? (
        <WaitingScreen handleHangup={handleDisconnect} />
      ) : isAccepted ? (
        <OngoingCallScreen handleHangup={handleDisconnect} />
      ) : (
        <div className="d-flex flex-column justify-content-center align-items-center min-vh-100 dialer-content">
          <DialPad
            dialedInput={dialedInput}
            dialedInputStyle={dialedInputStyle}
            setDialedInput={setDialedInput}
            handleDisconnect={handleDisconnect}
          />
          <div>
            <div className="key-row dial-button mt-2">
              <button
                className="btn ripple"
                onClick={() => {
                  logger({ message: 'Call hangup button clicked' });
                  handleDisconnect();
                }}
              >
                <AssetSvg.Disconnect />
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
