import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import LoadingBar from 'react-top-loading-bar';
import { VRIAppStateType } from '../../core';
import {
  AppConstants,
  CallStatusType,
  PlatformApi,
  PlatformType,
  topLoaderProgress,
} from '../../configs';
import {
  DirectCall,
  ModalCallSummary,
  RecentCalls,
  SearchInterpreterOpi,
  SearchInterpreterVri,
  VideoConference,
} from '../../features';
import { CallSummaryResponseModel } from '../../models/api/Call';
import {
  useFunctionalityBookingSummary,
  useFunctionalityCallSummary,
  useFunctionalityUserProfile,
} from '../hooks';
import { shallowEqual } from 'react-redux';
import { AxiosAuth, useAppSelector } from '../../core';
import { ApiSchemaMenuSettingsData } from '../../models/api/menuSettings';
import { controlBrowserBackAndReloadButton } from '../../helpers';
// import { Link } from 'react-router-dom';//remove this later

export function PageHome(): JSX.Element {
  const [menuSettings, setMenuSettings] = useState<ApiSchemaMenuSettingsData>();
  const [loaderProgress, setLoaderProgress] = React.useState(0);
  const [tableType, setTableType] = React.useState('search');
  const [show, setShow] = React.useState(false);
  const [callSummaryData, setCallSummaryData] =
    React.useState<CallSummaryResponseModel>();
  const [loaderInProgress, setLoaderInProgress] = useState(false);
  const [pagedOperatorCriteriaList, setPagedOperatorCriteriaList] = useState();
  const location = useLocation<{
    callId: string;
    bookingId: string;
    CallStatus: CallStatusType;
  }>();
  const callId = location.state?.callId;
  const bookingId = location.state?.bookingId;
  const { LoadById } = useFunctionalityCallSummary();
  const { LoadBookingSummaryById } = useFunctionalityBookingSummary();
  const { controlBrowserBackButton } = controlBrowserBackAndReloadButton();
  const menuData = useAppSelector(
    (state: VRIAppStateType) => ({
      menuSettings: state.auth.menuSettings,
      callStatus: state.videoCall?.CallStatus,
      userRole: state.auth.profile?.userRole,
    }),
    shallowEqual
  );
  const haveIVRCallPermission = (() => {
    const userRole = menuData?.userRole;
    const { CompanyAdmin, CompanyFinance, User } = AppConstants.UserRoles;
    return (
      userRole === CompanyAdmin ||
      userRole === CompanyFinance ||
      userRole === User
    );
  })();
  const userId = useAppSelector((state: VRIAppStateType) => state.auth.profile?.id);

  const menuSettingsResolved = async () => {
    setLoaderInProgress(true);
    const menuSettingsData = await getMenusettings();
    let pagedOperatorCriteriaListUrl =
      PlatformApi.Operator.GetOperatorSearchCriteriaList(
        userId!,
        PlatformType.VRI
      );
    const response = await AxiosAuth.get(pagedOperatorCriteriaListUrl);
    if (menuSettingsData && response.data.data) {
      setMenuSettings(menuSettingsData);
      setLoaderProgress(topLoaderProgress.complete);
      setLoaderInProgress(false);
      setPagedOperatorCriteriaList(response.data.data);
    }
  };

  useEffect(() => {
    if (callId) {
      setLoaderProgress(topLoaderProgress.start);
      LoadById(callId).then((data) => {
        if (data?.id) {
          setLoaderProgress(topLoaderProgress.complete);
          setCallSummaryData(data);
          setShow(true);
        }
      });
    } else if (bookingId) {
      setLoaderProgress(topLoaderProgress.start);
      LoadBookingSummaryById(bookingId).then((data) => {
        setLoaderProgress(topLoaderProgress.complete);
        setCallSummaryData(data);
        setShow(true);
      });
    }
  }, [callId, bookingId]);

  useEffect(() => {}, [menuSettings, callSummaryData]);
  const { getMenusettings } = useFunctionalityUserProfile();
  useEffect(() => {
    setLoaderProgress(topLoaderProgress.start);
    menuSettingsResolved();
  }, []);

  useEffect(() => {
    if (
      menuData.callStatus?.current === CallStatusType.Canceled ||
      menuData.callStatus?.current === CallStatusType.OperatorNotFound ||
      menuData.callStatus?.current === CallStatusType.Zero
    ) {
      controlBrowserBackButton();
    }
  }, [menuData.callStatus?.current]);

  const Loader = () => {
    return (
      <div className="py-5 text-muted text-center my-auto loader-wrapper h-100">
        <div className="lds-ring">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    );
  };

  return (
    <>
      {loaderInProgress ? (
        Loader()
      ) : (
        <>
          <LoadingBar
            progress={loaderProgress}
            onLoaderFinished={() => setLoaderProgress(0)}
          />
          {
            <div className="call-search-page py-3">
              <nav>
                <div className="nav nav-tabs" id="nav-tab" role="tablist" style={{position: "relative"}}>
                  {menuData.menuSettings?.callSettings?.vriEnabled && (
                    <a
                      className="nav-item nav-link active call-search-nav-link"
                      id="nav-search-interpreter-tab"
                      data-toggle="tab"
                      href="#nav-search-interpreter"
                      role="tab"
                      onClick={() => {
                        setTableType('search');
                      }}
                    >
                      VRI
                    </a>
                  )}
                  {/* {haveIVRCallPermission && menuData.menuSettings?.callSettings?.opiEnabled && (
                    <a
                      className="nav-item nav-link call-search-nav-link"
                      id="nav-search-interpreter-opi-tab"
                      data-toggle="tab"
                      href="#nav-search-interpreter-opi"
                      role="tab"
                      onClick={() => {
                        setTableType('search');
                      }}
                    >
                      OPI
                    </a>
                  )} */}
                  {menuData.menuSettings?.callSettings?.directCallEnabled && (
                    <a
                      className="nav-item nav-link  call-search-nav-link"
                      id="nav-direct-call-tab"
                      data-toggle="tab"
                      href="#nav-direct-call"
                      role="tab"
                      onClick={() => {
                        setTableType('search');
                      }}
                    >
                      Direct Call
                    </a>
                  )}
                  {menuData.menuSettings?.callSettings
                    ?.videoScheduleEnabled && (
                    <a
                      className="nav-item nav-link  call-search-nav-link"
                      id="nav-booking-call-tab"
                      data-toggle="tab"
                      href="#nav-booking-call"
                      role="tab"
                      onClick={() => {
                        setTableType('conference');
                      }}
                    >
                      Conference
                    </a>
                  )}
                  <div style={{position: "absolute", top: "3px", right: "10px"}}>
                  </div>
                </div>
              </nav>

              <div
                className="tab-content pb-3 pt-2 px-3 bg-white border-grey  tab-content-active"
                id="nav-tabContent"
              >
                {menuData.menuSettings?.callSettings?.vriEnabled && (
                  <div
                    className={`tab-pane fade show ${
                      menuData.menuSettings?.callSettings?.vriEnabled
                        ? 'active'
                        : ''
                    }`}
                    id="nav-search-interpreter"
                    role="tabpanel"
                    aria-labelledby="nav-search-interpreter-tab"
                  >
                    <SearchInterpreterVri
                      pagedOperatorCriteriaList={pagedOperatorCriteriaList}
                    />
                  </div>
                )}
                {menuData.menuSettings?.callSettings?.opiEnabled && (
                  <div
                    className={`tab-pane fade show ${
                      !menuData.menuSettings?.callSettings?.vriEnabled &&
                      menuData.menuSettings?.callSettings?.opiEnabled
                        ? 'active'
                        : ''
                    }`}
                    id="nav-search-interpreter-opi"
                    role="tabpanel"
                    aria-labelledby="nav-search-interpreter-opi-tab"
                  >
                    <SearchInterpreterOpi />
                  </div>
                )}
                {menuData.menuSettings?.callSettings?.directCallEnabled && (
                  <div
                    className={`tab-pane fade show ${
                      !menuData.menuSettings?.callSettings?.vriEnabled &&
                      !menuData.menuSettings?.callSettings?.opiEnabled &&
                      menuData.menuSettings?.callSettings?.directCallEnabled
                        ? 'active'
                        : ''
                    }`}
                    id="nav-direct-call"
                    role="tabpanel"
                    aria-labelledby="nav-direct-call-tab"
                  >
                    <DirectCall />
                  </div>
                )}
                {menuData.menuSettings?.callSettings?.videoScheduleEnabled && (
                  <div
                    className={`tab-pane fade show ${
                      !menuData.menuSettings?.callSettings?.vriEnabled &&
                      !menuData.menuSettings?.callSettings?.opiEnabled &&
                      !menuData.menuSettings?.callSettings?.directCallEnabled &&
                      menuData.menuSettings?.callSettings?.videoScheduleEnabled
                        ? 'active'
                        : ''
                    }`}
                    id="nav-booking-call"
                    role="tabpanel"
                    aria-labelledby="nav-booking-call-tab"
                  >
                    <VideoConference
                      pagedOperatorCriteriaList={pagedOperatorCriteriaList}
                    />
                  </div>
                )}
              </div>
            </div>
          }
          <RecentCalls
            type={tableType}
            loaderProgress={loaderProgress}
            setLoaderProgress={setLoaderProgress}
            callSummaryData={callSummaryData}
            setCallSummaryData={setCallSummaryData}
          />
          {show && (
            <ModalCallSummary
              summary={callSummaryData}
              show={show}
              handleShow={setShow}
            />
          )}
        </>
      )}
    </>
  );
}
