import { faDownload, faEnvelope, faFileExport, faRedo, } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { useHistory } from 'react-router-dom';
import { shallowEqual } from "react-redux";
import LoadingBar from "react-top-loading-bar";
import { AdminPageTemplate, ColumnToolTip, ModalPrintInvoice, Searchbar, } from "../../..";
import { AppConstants, AppRouteUi, ExportTableType, InvoiceGenerationType, InvoiceStatus, InvoiceType, PaginationSettings, PlatformApi, topLoaderProgress, TransactionType } from "../../../../configs";
import { ExportPayload, TableColumnExtend, } from "../../../../models";
import { ApiSchemaColumn, ApiSchemaFilter } from "../../../../models/api/filter";
import { InvoiceModel, MarkAsPaidRequestModel, RptInvoiceViewModel, SummaryInfoModel, } from "../../../../models/api/Finance";
import { exportAsExcel } from "../../../../pages/hooks";
import { useFunctionalityInvoice, useFunctionalityPagedInvoice, } from "../../../../pages/hooks/functionalityInvoice";
import { b64toBlob, downLoadBlob, handleNumberToTwoDecimalPoint } from "../../../../utils";
import { ModalMarkAsPaid } from "../../../modals/markAsPaidModal";
import { InvoiceFilter } from "./invoiceFilter";
import { mutate } from "swr";
import { useAppSelector } from "../../../../core";
import { AssetSvg } from "../../../../assets";
import { VRIAppStateType } from "../../../../core";

type Props = {
  transactionType: TransactionType;
  invoiceList?: any;
  invoiceType: InvoiceType;
  invoiceListCount?: number;
  loaderProgress?: number;
  setLoaderProgress?: any;
  infoBoxData?: SummaryInfoModel;
  pageName?: string;
  invoiceGenerationType: InvoiceGenerationType
};

export function CommonInvoice(props: Props) {
  const [hasPagingData, setHasPagingData] = useState<boolean>(false);
  const [loaderProgress, setLoaderProgress] = useState(0);
  const [invoiceDetails, setInvoiceDetails] = useState<RptInvoiceViewModel>();
  const { transactionType, invoiceType, pageName, invoiceGenerationType } = props;

  useEffect(() => {

  }, [invoiceType])
  const [tableData, setTableData] = useState<InvoiceModel[] | undefined>();
  const [tableDataCount, setTableDataCount] = useState(0);
  const [onUpdateSuccess, setOnUpdateSuccess] = useState<boolean>(false);

  useEffect(() => {
    if (onUpdateSuccess) {
      mutate(pagedInvoiceUrl);
    }
  }, [onUpdateSuccess]);
  const [isPageFilterData, setIsPageFilterData] = useState(false);
  const [isShowCommonFilter, setIsShowCommonFilter] = useState<boolean>(false);
  const [pageIndex, setPageIndex] = useState<number>(
    PaginationSettings.initialPageIndex
  );
  const [pageSize, setPageSize] = useState(PaginationSettings.initialPageSize);
  const [searchText, setSearchText] = useState<string>("");
  const [summaryInfoBoxData, setSummaryInfoBoxData] = useState<
    [string, string][]
  >([]);

  const [printInvoiceModalShow, setPrintInvoiceModalShow] = React.useState(false);
  const [markAsModalShow, setMarkAsModalShow] = React.useState(false);
  const [dataToSave, setDataToSave] = useState<MarkAsPaidRequestModel>(Object);
  const ViewInvoice = (invoiceId: string) => {
    getInvoiceDetails(invoiceId, invoiceType).then((data) => {
      setInvoiceDetails(data?.data);
      setPrintInvoiceModalShow(true);
    });
  };
  const { downloadInvoicePdf } = useFunctionalityInvoice({});
  const onMarkAsPaid = (model: InvoiceModel) => {
    setDataToSave({
      invoiceId: model.invoiceId,
      accountId: model.accountId,
      amount: model.amount,
      checkNumber: "",
      paidDate: new Date(),
      currency: "USD",
      notes: "",
      invoiceType: invoiceType,
      invoice: model.invoice,
      from: model.from,
      to: model.to,
    });
    setMarkAsModalShow(true);
  };
  const SendInvoice = (invoiceId: string) => {
    sendInvoiceMail(invoiceId, invoiceType);
  };
  let testuser: ApiSchemaFilter = {
    filterQueries: [],
  };
  const [filterData, setFilterData] = useState<ApiSchemaFilter>(testuser);
  const [filterDataChange, setFilterDataChange] = useState<number>(0);

  if ((filterData && filterDataChange) || searchText) {
    if (!isPageFilterData) setIsPageFilterData(true);
  }
  const data = useAppSelector(
    (state: VRIAppStateType) => ({
      userRole: state.auth.profile?.userRole,
    }),
    shallowEqual
  );
  const {
    pagedInvoiceList,
    pagedInvoiceListCount,
    invoiceInfoModel,
    pagedInvoiceUrl,
  } = useFunctionalityPagedInvoice({
    invoiceType: invoiceType,
    pageSize: pageSize,
    pageNo: pageIndex,
    searchText: searchText?.trimStart(),
    setLoaderProgress,
    filterData: filterData,
    filterDataChange: filterDataChange,
    isPageFilterData: isPageFilterData,
  });

  useEffect(() => {
    if (invoiceInfoModel) {
      setSummaryInfoBoxData([
        ["Paid", handleNumberToTwoDecimalPoint(invoiceInfoModel.paid)],
        ["Due", handleNumberToTwoDecimalPoint(invoiceInfoModel.due)],
        ["Total", handleNumberToTwoDecimalPoint(invoiceInfoModel.total)],
      ]);
    }
  }, [invoiceInfoModel]);
  useEffect(() => {
    if (pagedInvoiceList) {
      setTableData(pagedInvoiceList);
      setTableDataCount(pagedInvoiceListCount);
    }
  }, [pagedInvoiceList, pagedInvoiceListCount]);
  const { generateInvoice, getInvoiceDetails, sendInvoiceMail } =
    useFunctionalityInvoice({
      setLoaderProgress: setLoaderProgress,
    });

  useEffect(() => {
    if (pagedInvoiceListCount) {
      setHasPagingData(true);
    } else {
      setHasPagingData(false);
    }
  }, [pagedInvoiceList]);

  const handleSearch = (e: any) => {
    ReactDOM.unstable_batchedUpdates(() => {
      setSearchText(e.target.value);
      setPageIndex(1);
    });
  };
  const onChangePageIndex = (index: number) => {
    setPageIndex(index);
  };
  const onChangePageSize = (size: number) => {
    setPageSize(size);
  };

  const history = useHistory();

  //here, we are defining the columns of the table
  const invoiceTableColumns: TableColumnExtend<InvoiceModel>[] = [
    {
      name: <ColumnToolTip data="Invoice" />,
      selector: (row: InvoiceModel) => {
        return <ColumnToolTip data={row.invoice} />;
      },
      sortable: true,
      sortField: "SerialId",
    },
    {
      name: <ColumnToolTip data="Booking Serial" />,
      selector: (row: InvoiceModel) => {
        return (
          <>
            <a
              className="btn btn-link text-site text text-decoration-underline"
              onClick={() => {
                if (invoiceType === 7 || invoiceType === 8) {
                  if (setLoaderProgress)
                    setLoaderProgress(topLoaderProgress.start);

                  history.push({
                    pathname: AppRouteUi.EditJobs.Root,
                    state: {
                      jobId: row.bookingId,
                      loaderProgress: loaderProgress,
                    },
                  });
                  // LoadDetailsByJobId(row.bookingId).then((data) => {
                  //   if (setLoaderProgress)
                  //     setLoaderProgress(topLoaderProgress.complete);
                  //   history.push({
                  //     pathname: AppRouteUi.EditJobs.Root,
                  //     state: {
                  //       jobData: data,
                  //       loaderProgress: loaderProgress,
                  //     },
                  //   });
                  // });
                }
              }}
            >
              {row.bookingSerial}
            </a>
          </>
        );
      },
      sortable: true,
      omit: invoiceType === 7 || invoiceType === 8 ? false : true,
      sortField: "Booking.SerialNo",
    },
    {
      name: <ColumnToolTip data="Company" />,
      selector: (row: InvoiceModel) => {
        return <ColumnToolTip data={row.companyName} />;
      },
      sortable: true,
      sortField: "Company.Name",
      omit:
        invoiceType === 3 || invoiceType === 4 || invoiceType === 7
          ? false
          : true,
    },
    {
      name: <ColumnToolTip data="Operator" />,
      selector: (row: InvoiceModel) => {
        return <ColumnToolTip data={row.operatorName} />;
      },
      sortable: true,
      sortField: "Operator.User.Name",
      omit: invoiceType === 2 || invoiceType === 8 ? false : true,
    },
    {
      name: <ColumnToolTip data="From" />,
      selector: (row: InvoiceModel) => {
        return <ColumnToolTip data={row.from} />;
      },
      sortable: true,
      sortField: "Start",
    },
    {
      name: <ColumnToolTip data="To" />,
      selector: (row: InvoiceModel) => {
        return <ColumnToolTip data={row.to} />;
      },
      sortable: true,
      sortField: "End",
    },
    {
      name: <ColumnToolTip data="Amount" />,
      width: "110px",
      selector: (row: InvoiceModel) => {
        return <ColumnToolTip data={handleNumberToTwoDecimalPoint(row.amount)} />;
      },
      sortable: true,
      sortField: "Amount",
      conditionalCellStyles: [
        {
          when: (row: InvoiceModel) => row.amount < 0,
          style: {
            color: "red",
          },
        },
      ],
    },
    {
      name: <ColumnToolTip data="Status" />,
      width: "110px",
      selector: (row: InvoiceModel) => {
        return <ColumnToolTip data={row.status} />;
      },
      sortable: true,
      sortField: "Status",
    },
    {
      name: <ColumnToolTip data="Actions" />,
      width: "380px",
      right: true,
      selector: (row: InvoiceModel) => {
        return (
          <>
            {
              !row.isCancelled && (data.userRole !== AppConstants.UserRoles.CompanyAdmin || data.userRole !== AppConstants.UserRoles.CompanyFinance) && getReGenerateIcon(row)
            }
            {
              !row.isCancelled && (data.userRole !== AppConstants.UserRoles.CompanyAdmin || data.userRole !== AppConstants.UserRoles.CompanyFinance) && getActionButton(row)
            }
            <div
              className="btn svg-color"
              title="Edit"
              onClick={() => {
                ViewInvoice(row.invoiceId);
              }}
            >
              <span >
                <AssetSvg.ViewProfile />
              </span>
            </div>

            {invoiceType === 1 || invoiceType === 3 || invoiceType === 4 ? (
              <a className="btn btn-link text-site svg-color" title="Excel Download">
                <FontAwesomeIcon
                  icon={faFileExport}
                  onClick={() => handleExportAsExcel(row)}
                />
              </a>
            ) : null}
            {invoiceType === 1 || invoiceType === 3 || invoiceType === 4 ? (
              <a className="btn btn-link text-site svg-color" title="Pdf download">
                <FontAwesomeIcon
                  icon={faDownload}
                  onClick={() =>
                    downloadPdf(row.invoiceId, invoiceType, row.invoice)
                  }
                />
              </a>
            ) : null}
            {
              (data.userRole !== AppConstants.UserRoles.CompanyAdmin || data.userRole !== AppConstants.UserRoles.CompanyFinance) && (
                <a className="btn btn-link text-site svg-color" title="Email report">
                  <FontAwesomeIcon
                    icon={faEnvelope}
                    onClick={() => SendInvoice(row.invoiceId)}
                  />
                </a>
              )
            }
          </>
        );
      },
    },
  ];
  const getReGenerateIcon = (row: InvoiceModel) => {
    if (row.status === InvoiceStatus.DUE) {
      if (invoiceType === InvoiceType.Operator || invoiceType === InvoiceType.CallCenter || invoiceType === InvoiceType.Customer || invoiceType === InvoiceType.Company) {
        if (data.userRole === AppConstants.UserRoles.Admin || data.userRole === AppConstants.UserRoles.Finance || (data.userRole === AppConstants.UserRoles.ResellerAdmin && invoiceType !== InvoiceType.CallCenter) || (data.userRole === AppConstants.UserRoles.ResellerFinance && invoiceType !== InvoiceType.CallCenter)) {
          return <a className="btn btn-link text-site">
            <FontAwesomeIcon
              icon={faRedo}
              onClick={() => {
                generateInvoice(row.invoiceId, invoiceGenerationType)
              }}
            />
          </a>
        }
      }

    }
  }
  const getActionButton = (row: InvoiceModel) => {
    if (invoiceType === InvoiceType.CallCenter) {
      if (data.userRole !== AppConstants.UserRoles.ResellerAdmin && data.userRole !== AppConstants.UserRoles.ResellerFinance) {
        if (row.paidDate === '') {
          return (<button
            className="btn btn-light border text-nowrap text-uppercase markedPaid-btn"
            onClick={() => {
              onMarkAsPaid(row);
            }}
          >
            Mark as paid
          </button>
          )
        } else {
          return <span>Paid on {row.paidDate}</span>
        }
      }
      else {
        if (row.paidDate) {
          return <span>Paid on {row.paidDate}</span>
        }
        else return <span></span>
      }
    } else {
      if (row.paidDate) {
        return <span>Paid on {row.paidDate}</span>
      } else {
        return (<button
          className="btn btn-light border text-nowrap text-uppercase markedPaid-btn"
          onClick={() => {
            onMarkAsPaid(row);
          }}
        >
          Mark as paid
        </button>
        )
      }
    }
  }

  useEffect(() => { }, [filterData]);
  const downloadPdf = (
    invoiceId: string,
    invoiceType: InvoiceType,
    formattedInvoiceId: string
  ) => {
    downloadInvoicePdf(invoiceId ?? "", invoiceType ?? 0).then((data) => {
      if (data) {
        const linkSource = `data:application/pdf;base64,${data}`;
        const downloadLink = document.createElement("a");
        const fileName = `${formattedInvoiceId}-${moment().format(
          "MM-YYYY"
        )}.pdf`;
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
      }
    });
  };
  const handleExportAsExcel = async (data: InvoiceModel) => {
    let columns: ApiSchemaColumn[] = [];
    if (data.from && data.to) {
      let dateColumn: ApiSchemaColumn = {
        columnName: "CreatedAt",
        dateQuery: {
          startDate: moment(data.from).format("YYYY-MM-DD[T]HH:mm:ss.SSS[Z]"),
          endDate: moment(data.to).format("YYYY-MM-DD[T]HH:mm:ss.SSS[Z]"),
        },
      };
      columns.push(dateColumn);
    }
    if (invoiceType === InvoiceType.CallCenter) {
      let dateColumn: ApiSchemaColumn = {
        columnName: "ResellerId",
        value: data.companyId,
      };
      columns.push(dateColumn);
    } else {
      let dateColumn: ApiSchemaColumn = {
        columnName: "CompanyId",
        value: data.companyId,
      };
      columns.push(dateColumn);
    }
    const newFilter = {
      filterQueries: columns,
    };
    const exportPayload: ExportPayload = {
      queryRequestModel: newFilter,
      columnList: [],
    };
    const responseData = await exportAsExcel({
      tableType: ExportTableType.InternalCall,
      exportPayload,
      setLoaderProgress,
    });
    if (responseData) {
      const blob = b64toBlob(
        responseData.data,
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      );
      downLoadBlob(blob, "CallLog");
    }
  };
  return (
    <>
      <LoadingBar
        progress={loaderProgress}
        onLoaderFinished={() => setLoaderProgress(0)}
      />
      <h4 className="my-3 font-weight-light"></h4>
      <div className="row">
        <div className="col-12 d-flex">
          <div className="w-100">
            <Searchbar
              showFilter={true}
              filterPanel={"jobFilterPanel"}
              searchText={searchText}
              handleSearch={handleSearch}
              setIsShowCommonFilter={setIsShowCommonFilter}
            />
          </div>
        </div>
        {isShowCommonFilter ? (
          <InvoiceFilter
            setFilterData={setFilterData}
            setLoaderProgress={setLoaderProgress}
            setFilterDataChange={setFilterDataChange}
            invoiceType={invoiceType}
            pageName={pageName}
            setPageIndex={setPageIndex}
          />
        ) : null}
      </div>
      <AdminPageTemplate
        loaderProgress={loaderProgress ?? 0}
        tableHeaders={invoiceTableColumns}
        tableData={tableData ?? []}
        searchText={searchText}
        handleSearch={handleSearch}
        hideSearchBar={true}
        pageIndex={pageIndex}
        setPageIndex={setPageIndex}
        pageSize={pageSize}
        count={tableDataCount}
        onChangePageIndex={onChangePageIndex}
        onChangePageSize={onChangePageSize}
        callLogInfoBoxData={summaryInfoBoxData}
        hasPagingData={hasPagingData}
        setHasPagingData={setHasPagingData}
        setFilterDataForAnyTable={setFilterData}
        setFilterDataChangeForAnyTable={setFilterDataChange}
      />
      {
        printInvoiceModalShow && <ModalPrintInvoice
          show={printInvoiceModalShow}
          handleShow={setPrintInvoiceModalShow}
          invoice={invoiceDetails}
        //  bookingSerila={tableData?.bookingSerial}
        />
      }
      <ModalMarkAsPaid
        show={markAsModalShow}
        handleShow={setMarkAsModalShow}
        companyId={""}
        adminRole={""}
        transactionType={transactionType}
        dataToSave={dataToSave}
        setDataToSave={setDataToSave}
        modalTitle={"Mark As Paid"}
        pagedInvoiceUrl={pagedInvoiceUrl}
        onUpdateSuccess={onUpdateSuccess}
        setOnUpdateSuccess={setOnUpdateSuccess}
      />
    </>
  );
}