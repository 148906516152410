import { Modal } from "react-bootstrap";
import { FooterButtons, StepIndicatorTabs } from "../../..";
import { useAppSelector } from "../../../../core/Redux/ReduxStore";
import { StepNumber } from "../../../../configs";
import { useFunctionalityCompany, useFunctionalityCompanyCodeExistence } from "../../../../pages/hooks";
import { AppConstants, CompanyType, PlatformType, SubscriptionType, SubscriptionValue } from '../../../../configs/appConstants';
import { toast } from "react-toastify";
import { VRIAppStateType } from "../../../../core";

type Props = {
  stepCounter: number;
  setStepCounter: (value: number) => void;
  handleShow: (value: boolean) => void;
  companyType?: CompanyType;
  parentId?: string | "";
};

export function StepConfirmationCreateCompany(props: Props): JSX.Element {
  const { stepCounter, setStepCounter, handleShow, companyType, parentId } = props;
  const { onCreateCompany } = useFunctionalityCompany({
    companyType: companyType,
    parentId: parentId ?? "",
  });
  const formData = useAppSelector((state: VRIAppStateType) => state.company);
  const { onCompanyCodeCheckExistence } = useFunctionalityCompanyCodeExistence({
    code: formData?.companyStep1?.code ?? ""
  });
  const onCreateCompanyHandler = (clearCompanyDataCallBack: any) => {
    onCompanyCodeCheckExistence()
      ?.then((res) => {
        if (res?.data?.data || (formData?.companyStep1?.code && formData?.companyStep1?.code.length < 4)) {
          toast.error("Company Creation Failed");
          clearCompanyDataCallBack();
          handleShow(false);
          return;
        } else {
          onCreateCompany().then((response: any) => {
            if (response.status === AppConstants.Api.StatusOK) {
              clearCompanyDataCallBack();
              handleShow(false);
            }
          });
        }
      });
  };
  return (
    <>
      <Modal.Body>
        <form id="companyCreateForm">
          <div className=" component-padding border border-gry">
            <div className="row">
              <div className="col-12">
                <StepIndicatorTabs
                  step={stepCounter || StepNumber.firstStep}
                  companyType={companyType}
                />
                <div className="row mt-4">
                  <div className="col-12  text-center ">
                    <div className="d-flex flex-wrap align-items-center justify-content-center mb-3">
                      <div className="site-check-mark-container mr-2">
                        <div className="check-mark"></div>
                      </div>
                      <div>
                        <p className="mb-1">We are almost done.</p>
                      </div>
                    </div>
                    <p>
                      Click <em>Create</em> to proceed or click <em>Back</em> to
                      make changes.
                    </p>
                  </div>
                  <div className="col-12">
                    <div className="component-card rounded mb-3 ">
                      <div className="component-card-header border rounded-top">
                        <div>General Information</div>
                      </div>
                      <div
                        className="component-card-body rounded-bottom border-left border-right border-bottom"
                        id="collapseExample"
                      >
                        <div className="row mb-2 mb-sm-0">
                          <div className="col-12 col-sm-4 ">
                            <p className="mb-0">Company Name</p>
                          </div>
                          <div className="col-12 col-sm-8 text-muted">
                            <p className="mb-0 word-wrap">
                              {formData.companyStep1?.name ?? ""}
                            </p>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-12 col-sm-4 ">
                            <p className="mb-0">Address</p>
                          </div>
                          <div className="col-12 col-sm-8 text-muted">
                            <p className="mb-0 word-wrap">
                              {formData.companyStep1?.address ?? ""}
                            </p>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-12 col-sm-4">
                            <p className="mb-0">City</p>
                          </div>
                          <div className="col-12 col-sm-8 text-muted">
                            <p className="mb-0 word-wrap">
                              {formData.companyStep1?.city ?? ""}
                            </p>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-12 col-sm-4">
                            <p className="mb-0">Zip</p>
                          </div>
                          <div className="col-12 col-sm-8 text-muted">
                            <p className="mb-0 word-wrap">
                              {formData.companyStep1?.zipCode ?? ""}
                            </p>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-12 col-sm-4">
                            <p className="mb-0">State</p>
                          </div>
                          <div className="col-12 col-sm-8 text-muted">
                            <p className="mb-0 word-wrap">
                              {formData.companyStep1?.state ?? ""}
                            </p>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-12 col-sm-4">
                            <p className="mb-0">Country</p>
                          </div>
                          <div className="col-12 col-sm-8 text-muted">
                            <p className="mb-0 word-wrap">
                              {formData.companyStep1?.country ?? ""}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="component-card rounded mb-3  ">
                      <div className="component-card-header border rounded-top">
                        <div>Contact and Subscription</div>
                      </div>
                      <div
                        className="component-card-body rounded-bottom border-left border-right border-bottom"
                        id="collapseExample"
                      >
                        <div className="row mb-2 mb-sm-0">
                          <div className="col-12 col-sm-4 ">
                            <p className="mb-0">Platforms</p>
                          </div>
                          <div className="col-12 col-sm-8 text-muted">
                            <p className="mb-0 word-wrap">
                              {formData.companyStep3n4
                                .filter(
                                  (data: any) => data.isEnabled.toString() === "true"
                                )
                                .map((data: any) => PlatformType[data.platform])
                                .join(", ")}
                            </p>
                          </div>
                        </div>
                        {formData.companyStep3n4.map((data: any) =>
                          data.isEnabled.toString() === "true" ? (
                            <>
                              <div className="row">
                                <div className="col-12 col-sm-4 ">
                                  <p className="mb-0">
                                    {PlatformType[data.platform]}{" "}
                                    {data.platform !== PlatformType.OnSite ? (
                                      <>Connection Fee</>
                                    ) : (
                                      <>Booking Fee</>
                                    )}
                                  </p>
                                </div>
                                <div className="col-12 col-sm-8 text-muted">
                                  <p className="mb-0 word-wrap">
                                    {data.connectionFee}
                                  </p>
                                </div>
                              </div>
                              {
                                data.platform !== PlatformType.OnSite && <div className="row">
                                  <div className="col-12 col-sm-4">
                                    <p className="mb-0">
                                      {PlatformType[data.platform]} Technology Fee
                                    </p>
                                  </div>
                                  <div className="col-12 col-sm-8 text-muted">
                                    <p className="mb-0 word-wrap">
                                      {data.technologyFee}
                                    </p>
                                  </div>
                                </div>
                              }
                            </>
                          ) : null
                        )}
                        <div className="row">
                          <div className="col-12 col-sm-4">
                            <p className="mb-0">Connection Type</p>
                          </div>
                          <div className="col-12 col-sm-8 text-muted">
                            <p className="mb-0 word-wrap">
                              {formData.companyStep2?.type == SubscriptionType.Postpaid ? SubscriptionValue.Postpaid : SubscriptionValue.Prepaid}
                            </p>
                          </div>
                        </div>
                        {/* <div className="row">
                          <div className="col-12 col-sm-4">
                            <p className="mb-0">Credit Limit</p>
                          </div>
                          <div className="col-12 col-sm-8 text-muted">
                            <p className="mb-0 word-wrap">
                              {formData.companyStep2?.creditLimit ?? ""}
                            </p>
                          </div>
                        </div> */}

                        <div className="row">
                          <div className="col-12 col-sm-4">
                            <p className="mb-0">Subscription Fee</p>
                          </div>
                          <div className="col-12 col-sm-8 text-muted">
                            <p className="mb-0 word-wrap">
                              {formData.companyStep2?.subscriptionRate ?? ""}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>

                    {companyType == CompanyType.Customer ? (
                      <div className="component-card rounded mb-3  ">
                        <div className="component-card-header border rounded-top">
                          <div>Packages</div>
                        </div>
                        <div
                          className="component-card-body rounded-bottom border-left border-right border-bottom"
                          id="collapseExample"
                        >
                          {formData.companyStep3n4.map((data:any) =>
                            data.isEnabled.toString() === "true" ? (
                              <div className="row mb-2 mb-sm-0">
                                <div className="col-12 col-sm-4 ">
                                  <p className="mb-0">
                                    {PlatformType[data.platform]} Package Name
                                  </p>
                                </div>
                                <div className="col-12 col-sm-8 text-muted">
                                  <p className="mb-0 word-wrap">
                                    {data.languagePackName}
                                  </p>
                                </div>
                              </div>
                            ) : null
                          )}
                        </div>
                      </div>
                    ) : null}

                    <div className="component-card rounded ">
                      <div className="component-card-header border rounded-top">
                        <div>Contact Person</div>
                      </div>
                      <div
                        className="component-card-body rounded-bottom border-left border-right border-bottom"
                        id="collapseExample"
                      >
                        <div className="row mb-2 mb-sm-0">
                          <div className="col-12 col-sm-4 ">
                            <p className="mb-0">Name</p>
                          </div>
                          <div className="col-12 col-sm-8 text-muted">
                            <p className="mb-0 word-wrap">
                              {`${formData.companyStep5?.firstName} ${formData.companyStep5?.lastName}` ??
                                ""}
                            </p>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-12 col-sm-4 ">
                            <p className="mb-0">Mobile Number</p>
                          </div>
                          <div className="col-12 col-sm-8 text-muted">
                            <p className="mb-0 word-wrap">
                              {formData.companyStep5?.contactNumber ?? ""}
                            </p>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-12 col-sm-4">
                            <p className="mb-0">Email Address</p>
                          </div>
                          <div className="col-12 col-sm-8 text-muted">
                            <p className="mb-0 word-wrap">
                              {formData.companyStep5?.email ?? ""}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </Modal.Body>
      <Modal.Footer className="border-0">
        <FooterButtons
          counter={stepCounter || StepNumber.firstStep}
          setCounter={setStepCounter}
          isLastStep={true}
          onCreate={onCreateCompanyHandler}
          handleShow={handleShow}
        />
      </Modal.Footer>
    </>
  );
}
