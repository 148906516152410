import Select from "react-select";
import { shallowEqual } from "react-redux";
import React, { useState } from "react";
import ReactDOM from "react-dom";
import { useForm } from "react-hook-form";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Controller } from "react-hook-form";
import moment from "moment";
import { ApiUserNames, SelectModel } from "../../../../models";
import { useFunctionalityCompanyNames, useFunctionalityGetAdminNames, useFunctionalityOperatorNamesByCallCenterId } from "../../../../pages/hooks";
import { ApiSchemaColumn, ApiSchemaFilter, ApiSchemaFormFilter } from "../../../../models/api/filter";
import { useAppSelector } from "../../../../core";
import { ActivitySelectOption, AuditLogSelectOption } from "../../../modals/jobFormData";
import { VRIAppStateType } from "../../../../core";

type Props = {
  pageName: 'auditLogsPage' | 'activityPage';
  setFilterData: any;
  setFilterDataChange?: any;
  setPageIndex: Function;
};

export function CommonAuditActivityFilter(props: Props): JSX.Element {
  const { setFilterData, setFilterDataChange, pageName, setPageIndex } = props;
  const [companyNames, setCompanyNames] = useState<SelectModel[]>();
  const [adminNames, setAdminNames] = useState<SelectModel[]>();
  const [operatorNames, setOperatorNames] = useState<SelectModel[]>();
  const [startDate, setStartDate] = useState<Date | undefined>();
  const [endDate, setEndDate] = useState<Date | undefined>();
  const [selectedCompany, setSelectedCompany] = useState<any>([{ value: "", label: "Any" }]);
  const [selectedAdminNames, setSelectedAdminNames] = useState<any>([{ value: "", label: "Any" }]);
  const [selectedOperatorNames, setSelectedOperatorNames] = useState<any>([{ value: "", label: "Any" }]);
  const [selectedAction, setSelectedAction] = useState<any>([{ value: 0, label: "Any" }]);

  const { onCompanyNames } = useFunctionalityCompanyNames(true);
  const { getAdminNames } = useFunctionalityGetAdminNames();
  const { onOperatorNamesByCallCenterId } = useFunctionalityOperatorNamesByCallCenterId();

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    control,
  } = useForm<ApiSchemaFormFilter>();

  const callCenterId = useAppSelector(
    (state: VRIAppStateType) => state.auth.menuSettings?.callCenterSettings.callCenterId,
    shallowEqual
  );

  React.useEffect(() => {
    if (pageName === "auditLogsPage") {
      onCompanyNames("").then((res) => {
        setCompanyNames([
          {
            value: "",
            label: "Any",
          },
          ...res.data,
        ]);
      });
    }
    if (pageName === "auditLogsPage") {
      getAdminNames().then((res) => {
        const adminNameData = res?.data.map((element: ApiUserNames) => {
          return {
            value: element.id,
            label: element.name
          }
        })
        setAdminNames([
          {
            value: "",
            label: "Any",
          },
          ...adminNameData!,
        ]);
      });
    }
    if (pageName === "activityPage") {
      onOperatorNamesByCallCenterId(callCenterId!).then((res) => {
        setOperatorNames([
          {
            value: "",
            label: 'Any'
          },
          ...res.data
        ])
      })
    }
  }, []);

  const onFilterHandler = (data: ApiSchemaFormFilter) => {
    let columns: ApiSchemaColumn[] = [];
    let start, end;

    if (data) {
      for (let [key, value] of Object.entries(data)) {
        if (value === "" || value === undefined || value === '0') continue;
        if (key === "startDate") {
          if (value) {
            start = moment(value).format("YYYY-MM-DD[T]HH:mm:ss.SSS[Z]");
          } else {
            start = "";
          }
        } else if (key === "endDate") {
          if (value) {
            end = moment(value).format("YYYY-MM-DD[T]HH:mm:ss.SSS[Z]");
          } else {
            end = "";
          }
        } else {
          let column: ApiSchemaColumn = { columnName: key, value: value };
          columns.push(column);
        }
      }
      if (start || end) {
        let dateColumn: ApiSchemaColumn = {
          columnName: "CreatedAt",
          dateQuery: { startDate: start, endDate: end },
        };
        columns.push(dateColumn);
      }
    }

    ReactDOM.unstable_batchedUpdates(() => {
      setFilterData((prevState: ApiSchemaFilter) => {
        return {
          ...prevState,
          filterQueries: columns
        }
      });
      setFilterDataChange && setFilterDataChange(Math.floor(Math.random() * 100) + 1);
      setPageIndex(1);
    });
  };

  return (
    <div className="col-12 collapse mt-3" id="commonAuditActivityFilterPanel">
      <div className="card card-body">
        <form onSubmit={handleSubmit(onFilterHandler)}>
          <label>Date</label>
          <div className="row">
            <label htmlFor="bookingDate"></label>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 form-group">
              <Controller
                name="startDate"
                control={control}
                render={({ field }) => (
                  <DatePicker
                    dateFormat="MM/dd/yyyy"
                    placeholderText="Start date"
                    className="form-control"
                      selected={field.value ? new Date(field.value): null}
                    onChange={(value) => {
                      setStartDate(value ? value : undefined);
                      field.onChange(value)
                    }}
                    showYearDropdown
                    showMonthDropdown
                    dropdownMode="select"
                    maxDate={endDate}
                  />
                )}
              />
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 form-group">
              <Controller
                name="endDate"
                control={control}
                render={({ field}) => (
                  <DatePicker
                    dateFormat="MM/dd/yyyy"
                    placeholderText="End date"
                    className="form-control"
                      selected={field.value ? new Date(field.value): null}
                    onChange={(value) => {
                      field.onChange(value);
                      setEndDate(value ? value : undefined);
                    }}
                    minDate={startDate}
                    showYearDropdown
                    showMonthDropdown
                    dropdownMode="select"
                  />
                )}
              />
            </div>
          </div>
          <div className="row">
            {pageName === 'auditLogsPage' ?
              <>
                <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 form-group">
                  <label htmlFor="">Company</label>
                  <input className="d-none" {...register('CompanyId', {})} />
                  <Select
                    options={companyNames}
                    value={selectedCompany}
                    components={{
                      IndicatorSeparator: () => null,
                    }}
                    placeholder="Select a company"
                    onChange={(value) => {
                      setValue("CompanyId", value?.value, {
                        shouldValidate: true,
                      });
                      setSelectedCompany([
                        { label: value?.label, value: value?.value },
                      ]);
                    }}
                  />
                </div>
                <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 form-group">
                  <label htmlFor="">Actor</label>
                  <input className="d-none" {...register('ActorId', {})} />
                  <Select
                    options={adminNames}
                    value={selectedAdminNames}
                    components={{
                      IndicatorSeparator: () => null,
                    }}
                    placeholder="Select an actor"
                    onChange={(value) => {
                      setValue("ActorId", value?.value, {
                        shouldValidate: true,
                      });
                      setSelectedAdminNames([
                        { label: value?.label, value: value?.value },
                      ]);
                    }}
                  />
                </div>
              </>
              :
              null
            }
            {pageName === 'activityPage' ?
              <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 form-group">
                <label htmlFor="">Operator</label>
                <input className="d-none" {...register('ActorId', {})} />
                <Select
                  options={operatorNames}
                  value={selectedOperatorNames}
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                  placeholder="Select an operator"
                  onChange={(value) => {
                    setValue("ActorId", value?.value, {
                      shouldValidate: true,
                    });
                    setSelectedOperatorNames([
                      { label: value?.label, value: value?.value },
                    ]);
                  }}
                />
              </div>
              :
              null
            }

            <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 form-group">
              <label htmlFor="">Action</label>
              <input className="d-none" {...register('Action', {})} />
              <Select
                options={pageName === 'activityPage' ? ActivitySelectOption : AuditLogSelectOption}
                value={selectedAction}
                components={{
                  IndicatorSeparator: () => null,
                }}
                placeholder="Select an action"
                onChange={(value) => {
                  setValue("Action", value?.value, {
                    shouldValidate: true,
                  });
                  setSelectedAction([
                    { label: value?.label, value: value?.value },
                  ]);
                }}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-12 text-right">
              <button
                type="button"
                className="btn btn-link text-uppercase filter-reset-btn"
                onClick={() => {
                  setFilterData((prevState: ApiSchemaFilter) => {
                    return {
                      ...prevState,
                      filterQueries: []
                    }
                  });
                  setFilterDataChange && setFilterDataChange(Math.floor(Math.random() * 100) + 1);
                  reset();
                  setSelectedCompany([]);
                  setSelectedAdminNames([]);
                  setSelectedOperatorNames([]);
                  setSelectedAction([]);
                  setStartDate(undefined);
                  setEndDate(undefined);
                }}
              >
                Reset
              </button>
              <button
                type="submit"
                className="btn btn-primary text-uppercase mx-3"
              >
                Filter
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
