import React, { useEffect } from "react";
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import {
  DialerPage,
  PageCalendar,
  PageCallLog,
  PageChat,
  PageHome,
  PageIncomingCall,
  PageInflowCallLog,
  PageJobEdit,
  PageJobSettings,
  PageJobs,
  PageLog,
  PageMyAccount,
  PageNotFound,
  PageOperator,
  PageOperatorFinance,
  PageOutflowCallLog,
  PageOutgoingCall,
  PageOverflowCall,
  PageProfile,
  PageVideoIncoming,
  PageVideoOutgoing,
  PageVideoSchedules,
  VideoCallPageV2,
  VosFormPage,
} from ".";
import { SocketNotificationBase } from "../components";
import { AppConstants, AppRouteUi } from "../configs";
import { VRIAppStateType } from "../core";
import {
  ActionAuth,
  ActionUi,
  CallContextProvider,
  ChannelContextProvider,
  SocketContextProvider,
  StorageAuth,
  useAppSelector
} from "../core";
import { AppDrawer, Footer, SideNav, TopNav } from "../features";
import {
  PageAppointmentCategory,
  PageAppointmentDescription,
  PageAuditLogs,
  PageCallOutflow,
  PageCompany,
  PageCompanyProfile,
  PageLanguagePacks,
  PageLanguages,
  PageModality,
  PageReseller,
  PageResellerProfile,
  PageSkills,
  PageTIN,
  PageUserProfile,
  PageUsers,
  PageVendors,
  PageVendorsProfile,
} from "./admin";
import {
  PageActivity,
  PageCallInflow,
  PageMessage,
  PageOperatorProfile,
} from "./callCenter";
import { PageCompanyFinance } from "./finance/CompanyFinancePage";
import { PrivateRoute } from "./public/privateRoute";

import { Alert } from "react-bootstrap";

import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import { useDispatch } from "react-redux";
import { DownloadPage } from ".";
import { ApiSchemaProfileData } from "../models";
import { PageCompanyProfileView } from "./admin/ViewCompanyProfilePage";
import { PageCompanyFinanceProfile } from "./finance/CompanyFinanceProfilePage";
import { PageOperatorFinanceProfile } from "./finance/OperatorFinanceProfilePage";
import { useFunctionalityUser2, useVersionCheck } from "./hooks";
import OutgoingCallFailed from "./public/OutgoingCallFailed";
import OutgoingCallFailedForMicPermission from "./public/OutgoingCallFailedForMicPermission";
import OutgoingCallFailedForMediaPermission from "./public/OutgoingCallFialedForMediaPermission";

// import { VosFormPage } from "./public/vosFormPage";
import { UserAgentUtils, isElectron } from "../utils";
import { toast } from "react-toastify";

// import Video from '../features/video/video';
// import VideoSingle from '../features/video/video-single';
// import VideoAttach from '../features/video/video-attach';

//@ts-ignore
export function LayoutPrivate({ isSupportGalleryView, galleryViewWithAttach }): JSX.Element {
  useVersionCheck();
  const location = useLocation();
  const [show, setShow] = React.useState<boolean>(false);
  const [sidebarToggler, setSidebarToggler] = React.useState<boolean>(false);

  const dispatch = useDispatch();
  const profileData = useAppSelector((state: VRIAppStateType) => state.auth.profile);

  const isLoggedIn = useAppSelector((state: VRIAppStateType) => state.auth.isLoggedIn);
  const data = useAppSelector((state: VRIAppStateType) => ({
    timeZone: state.auth.profile?.timeZone,
    companyId: state.auth.profile?.companyId,
    userRole: state.auth.profile?.userRole,
    connection: state.common,
  }));
  const warnMultipleConnection = (() => {
    const { shouldConnectWS } = data.connection;
    if (!isElectron() && !UserAgentUtils.isWebView() && shouldConnectWS === false) {
      return true;
    }
    return false;
  })();
  const [systemTimeZone, setSystemTimeZone] = React.useState<string>("");
  const { updateTimeZone } = useFunctionalityUser2({});
  useEffect(() => {
    setSystemTimeZone(Intl.DateTimeFormat().resolvedOptions().timeZone);
    if (systemTimeZone != "") {
      timeZoneChecker();
    }
  }, [systemTimeZone]);

  const timeZoneChecker = () => {
    if (
      data.timeZone != systemTimeZone &&
      data.userRole != AppConstants.UserRoles.Consumer
    ) {
      setShow(true);
    }
  };
  useEffect(() => {
    dispatch(ActionUi.SetFileTimeStamp(Date.now()));
  }, []);
  const onUpdateTimeZone = (_timeZone: string) => {
    updateTimeZone({
      timeZone: _timeZone,
    }).then((d) => {
      let copyProfileData = { ...profileData, timeZone: _timeZone };
      dispatch(
        ActionAuth.SetProfileData(
          copyProfileData as unknown as ApiSchemaProfileData
        )
      );
      if (d) StorageAuth.AccessToken = d;
      setShow(false);
    });
  };
  const toggleClass = () => {
    if (!!sidebarToggler) {
      return "main-site-content main-site-toggle";
    } else {
      return "main-site-content";
    }
  };

  useEffect(() => {
    if (UserAgentUtils.isWebView()) {
      return;
    }

    const handleResize = () => {
      if (window.innerWidth < 992) {
        setSidebarToggler(false)
      } else {
        setSidebarToggler(true)
      }
    }

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    }
  }, []);

  if (!isLoggedIn) return <Redirect to={AppRouteUi.Login.Root} />;

  if (AppRouteUi.VideoConference.Root === location.pathname) {
    return <Redirect to={AppRouteUi.NotFound.Root} />;
  }

  return (
    <>
      <Alert
        show={show}
        key="warning"
        variant="warning"
        className="site-alert my-1"
      >
        <div className="d-flex flex-wrap align-items-md-center justify-content-center timezone">
          <div className="mr-3">
            Your computer's time zone doesn't appear to match your profile time
            zone. Click{' '}
            <a
              href="#"
              className="timezoneFixLink"
              onClick={() => onUpdateTimeZone(systemTimeZone)}
            >
              here
            </a>
            to set your profile time zone to (UTC
            {moment(new Date()).utcOffset() / 60 > 0
              ? `+${moment(new Date()).utcOffset() / 60}`
              : moment(new Date()).utcOffset() / 60}
            ) {systemTimeZone}
          </div>

          <div>
            <a
              onClick={() => setShow(false)}
              className="btn btn-purple  pb-0 pt-0 px-2 my-2 my-sm-2 my-md-2 my-lg-0"
            >
              <FontAwesomeIcon icon={faTimes} className="mt-1" />
            </a>
          </div>
        </div>
      </Alert>

      <Alert
        show={warnMultipleConnection}
        variant="warning"
        className="site-alert my-1"
      >
        <div className="mr-3">
          Only one tab can be connected based on first come first serve. In this
          tab call and chat won't work properly. Close existing connected tab to
          connect with the next tab in queue.
        </div>
      </Alert>

      <SocketContextProvider>
        <ChannelContextProvider>
          <CallContextProvider>
            <SocketNotificationBase />
            <Switch>
              <PrivateRoute
                path={AppRouteUi.Log.Root}
                component={PageLog}
                privateConfig={{
                  allowedRoles: [
                    'Admin',
                    'Operator',
                    'User',
                    'ResellerAdmin',
                    'CompanyAdmin',
                  ],
                }}
              />
              <PrivateRoute
                path={AppRouteUi.Chat.Root}
                exact
                component={PageChat}
                privateConfig={{
                  allowedRoles: [
                    'Admin',
                    'Operator',
                    'User',
                    'ResellerAdmin',
                    'CompanyAdmin',
                    'Manager',
                    'ResellerManager',
                  ],
                }}
              />
              <PrivateRoute
                path={AppRouteUi.IncomingCall.Root}
                exact
                component={PageIncomingCall}
                privateConfig={{
                  allowedRoles: [
                    'Admin',
                    'Operator',
                    'User',
                    'ResellerAdmin',
                    'CompanyAdmin',
                    'Manager',
                    'ResellerManager',
                    'Finance',
                    'ResellerFinance',
                  ],
                }}
              />
              <PrivateRoute
                path={AppRouteUi.OutgoingCall.Root}
                exact
                component={PageOutgoingCall}
                privateConfig={{
                  allowedRoles: [
                    'Admin',
                    'Operator',
                    'User',
                    'ResellerAdmin',
                    'CompanyAdmin',
                    'CompanyFinance',
                    'Manager',
                    'ResellerManager',
                    'Finance',
                    'ResellerFinance',
                  ],
                }}
              />
              <PrivateRoute
                path={AppRouteUi.OutgoingCallFailed.Root}
                exact
                component={OutgoingCallFailed}
                privateConfig={{
                  allowedRoles: [
                    'Admin',
                    'Operator',
                    'User',
                    'ResellerAdmin',
                    'CompanyAdmin',
                    'CompanyFinance',
                    'Manager',
                    'ResellerManager',
                    'Finance',
                    'ResellerFinance',
                  ],
                }}
              />
              <PrivateRoute
                path={AppRouteUi.OutgoingCallFailedForMedia.Root}
                exact
                component={OutgoingCallFailedForMediaPermission}
                privateConfig={{
                  allowedRoles: [
                    'Admin',
                    'Operator',
                    'User',
                    'ResellerAdmin',
                    'CompanyAdmin',
                    'CompanyFinance',
                    'Manager',
                    'ResellerManager',
                    'Finance',
                    'ResellerFinance',
                  ],
                }}
              />
              <PrivateRoute
                path={AppRouteUi.OutgoingCallFailedForMic.Root}
                exact
                component={OutgoingCallFailedForMicPermission}
                privateConfig={{
                  allowedRoles: [
                    'Admin',
                    'Operator',
                    'User',
                    'ResellerAdmin',
                    'CompanyAdmin',
                    'CompanyFinance',
                    'Manager',
                    'ResellerManager',
                    'Finance',
                    'ResellerFinance',
                  ],
                }}
              />
              <PrivateRoute
                path={AppRouteUi.VideoIncomingCall.Root}
                exact
                component={PageVideoIncoming}
                privateConfig={{
                  allowedRoles: [
                    'Admin',
                    'Operator',
                    'User',
                    'Manager',
                    'ResellerManager',
                    'ResellerAdmin',
                    'CompanyAdmin',
                    'CompanyFinance',
                    'Finance',
                    'ResellerFinance',
                  ],
                }}
              />
              <PrivateRoute
                path={AppRouteUi.VideoOutgoingCall.Root}
                exact
                component={PageVideoOutgoing}
                privateConfig={{
                  allowedRoles: [
                    'Admin',
                    'Operator',
                    'User',
                    'ResellerAdmin',
                    'CompanyAdmin',
                    'CompanyFinance',
                    'Manager',
                    'ResellerManager',
                    'Finance',
                    'ResellerFinance',
                  ],
                }}
              />
              <PrivateRoute
                path={AppRouteUi.VideoCall.Room.Root()}
                exact
                component={VideoCallPageV2}
                privateConfig={{
                  allowedRoles: [
                    'Admin',
                    'Operator',
                    'User',
                    'Manager',
                    'ResellerManager',
                    'ResellerAdmin',
                    'CompanyAdmin',
                    'CompanyFinance',
                    'Finance',
                    'ResellerFinance',
                  ],
                }}
              />
              <PrivateRoute
                path={AppRouteUi.IVROutgoingCall.Root}
                exact
                component={DialerPage}
                privateConfig={{
                  allowedRoles: ['CompanyAdmin', 'CompanyFinance', 'User'],
                }}
              />
              <PrivateRoute
                path={AppRouteUi.VideoConference.Room.RootIncludeOperator()}
                exact
                component={VideoCallPageV2}
                privateConfig={{
                  allowedRoles: [
                    'Admin',
                    'Operator',
                    'User',
                    'ResellerAdmin',
                    'CompanyAdmin',
                    'CompanyFinance',
                  ],
                }}
              />
              <PrivateRoute
                path={AppRouteUi.VideoConference.Room.Root()}
                exact
                component={VideoCallPageV2}
                privateConfig={{
                  allowedRoles: [
                    'Admin',
                    'Operator',
                    'User',
                    'Consumer',
                    'ResellerAdmin',
                    'CompanyAdmin',
                    'CompanyFinance',
                  ],
                }}
              />

              <PrivateRoute
                path={AppRouteUi.VideoConference.Room.RootConsumer()}
                exact
                component={VideoCallPageV2}
                privateConfig={{
                  allowedRoles: ['Consumer', 'Operator'],
                }}
              />
              <Route
                path={AppRouteUi.Download.root}
                exact
                component={DownloadPage}
              />

              <div className="d-flex" id="wrapper">
                {UserAgentUtils.isWebView() ? (
                  <AppDrawer
                    isToggled={sidebarToggler}
                    setToggleStatus={setSidebarToggler}
                  />
                ) : (
                  <SideNav isToggled={sidebarToggler} />
                )}
                <div id="page-content-wrapper" className="pl-2 pr-2">
                  <TopNav
                    toggleButton={sidebarToggler}
                    toggler={setSidebarToggler}
                  />
                  <div className={toggleClass()}>
                    <div className="container-fluid">
                      <div className="row full-content-scroll">
                        <div className="col-12">
                          <PrivateRoute
                            path={AppRouteUi.Home.Root}
                            exact
                            component={PageHome}
                            privateConfig={{
                              allowedRoles: [
                                'Admin',
                                'Finance',
                                'Manager',
                                'User',
                                'ResellerAdmin',
                                'ResellerFinance',
                                'ResellerManager',
                                'CompanyAdmin',
                                'CompanyFinance',
                              ],
                            }}
                          />
                          <PrivateRoute
                            path={AppRouteUi.Jobs.Root}
                            exact
                            component={PageJobs}
                            privateConfig={{
                              allowedRoles: [
                                'Admin',
                                'Manager',
                                'Operator',
                                'User',
                                'ResellerAdmin',
                                'CompanyAdmin',
                                'CompanyFinance',
                                'Finance',
                                'ResellerManager',
                                'ResellerFinance',
                              ],
                            }}
                          />
                          <PrivateRoute
                            path={AppRouteUi.EditJobs.Root}
                            exact
                            component={PageJobEdit}
                            privateConfig={{
                              allowedRoles: [
                                'Admin',
                                'Manager',
                                'User',
                                'ResellerAdmin',
                                'CompanyAdmin',
                                'Finance',
                              ],
                            }}
                          />
                          <PrivateRoute
                            path={AppRouteUi.EVosForm.Root}
                            exact
                            component={VosFormPage}
                            privateConfig={{
                              allowedRoles: [
                                'Admin',
                                'Manager',
                                'Operator',
                                'ResellerAdmin',
                                'Finance',
                              ],
                            }}
                          />
                          <PrivateRoute
                            path={AppRouteUi.Calendar.Root}
                            exact
                            component={PageCalendar}
                            privateConfig={{
                              allowedRoles: [
                                'Admin',
                                'Manager',
                                'Operator',
                                'User',
                                'ResellerAdmin',
                                'CompanyAdmin',
                                'CompanyFinance',
                                'Finance',
                                'ResellerManager',
                                'ResellerFinance',
                              ],
                            }}
                          />
                          <PrivateRoute
                            path={AppRouteUi.VideoScheduler.Root}
                            exact
                            component={PageVideoSchedules}
                            privateConfig={{
                              allowedRoles: [
                                'Admin',
                                'Operator',
                                'User',
                                'ResellerAdmin',
                                'CompanyAdmin',
                              ],
                            }}
                          />
                          <PrivateRoute
                            path={AppRouteUi.Settings.Root}
                            exact
                            component={PageJobSettings}
                            privateConfig={{
                              allowedRoles: [
                                'Admin',
                                'ResellerAdmin',
                                'Finance',
                                'CompanyAdmin',
                              ],
                            }}
                          />
                          <PrivateRoute
                            path={AppRouteUi.Profile.Root}
                            exact
                            component={PageProfile}
                            privateConfig={{
                              allowedRoles: [
                                'Admin',
                                'Manager',
                                'Operator',
                                'User',
                                'ResellerAdmin',
                                'CompanyAdmin',
                                'CompanyFinance',
                                'ResellerManager',
                                'ResellerFinance',
                                'Finance',
                              ],
                            }}
                          />
                          <PrivateRoute
                            path={AppRouteUi.CallLog.Root}
                            exact
                            component={PageCallLog}
                            privateConfig={{
                              allowedRoles: [
                                'Operator',
                                'CompanyAdmin',
                                'User',
                                'CompanyFinance',
                                'Finance',
                                'Manager',
                                'ResellerManager',
                              ],
                            }}
                          />
                          <PrivateRoute
                            path={AppRouteUi.InternalCallLog.Root}
                            exact
                            component={PageCallLog}
                            privateConfig={{
                              allowedRoles: [
                                'Admin',
                                'Finance',
                                'Manager',
                                'ResellerAdmin',
                                'ResellerFinance',
                              ],
                            }}
                          />
                          <PrivateRoute
                            path={AppRouteUi.OverflowCallLog.Root}
                            exact
                            component={PageOverflowCall}
                            privateConfig={{
                              allowedRoles: [
                                'Admin',
                                'Finance',
                                'ResellerFinance',
                              ],
                            }}
                          />
                          <PrivateRoute
                            path={AppRouteUi.InflowCallLog.Root}
                            exact
                            component={PageInflowCallLog}
                            privateConfig={{
                              allowedRoles: ['ResellerAdmin'],
                            }}
                          />
                          <PrivateRoute
                            path={AppRouteUi.OutflowCallLog.Root}
                            exact
                            component={PageOutflowCallLog}
                            privateConfig={{
                              allowedRoles: ['ResellerAdmin'],
                            }}
                          />

                          <PrivateRoute
                            path={AppRouteUi.Company.Root}
                            exact
                            component={PageCompany}
                            privateConfig={{
                              allowedRoles: ['Admin', 'ResellerAdmin'],
                            }}
                          />
                          <PrivateRoute
                            path={AppRouteUi.Company.Profile.Root()}
                            exact
                            component={PageCompanyProfile}
                            privateConfig={{
                              allowedRoles: ['Admin', 'ResellerAdmin'],
                            }}
                          />
                          <PrivateRoute
                            path={AppRouteUi.CompanyProfile.Profile.Root()}
                            exact
                            component={PageCompanyProfileView}
                            privateConfig={{
                              allowedRoles: ['CompanyAdmin', 'CompanyFinance'],
                            }}
                          />
                          <PrivateRoute
                            path={AppRouteUi.Reseller.Root}
                            exact
                            component={PageReseller}
                            privateConfig={{
                              allowedRoles: ['Admin'],
                            }}
                          />
                          <PrivateRoute
                            path={AppRouteUi.Reseller.Profile.Root()}
                            exact
                            component={PageResellerProfile}
                            privateConfig={{
                              allowedRoles: ['Admin'],
                            }}
                          />

                          <PrivateRoute
                            path={AppRouteUi.Vendors.Root}
                            exact
                            component={PageVendors}
                            privateConfig={{
                              allowedRoles: ['Admin', 'ResellerAdmin'],
                            }}
                          />
                          <PrivateRoute
                            path={AppRouteUi.Vendors.Profile.Root()}
                            exact
                            component={PageVendorsProfile}
                            privateConfig={{
                              allowedRoles: ['Admin', 'ResellerAdmin'],
                            }}
                          />
                          <PrivateRoute
                            path={AppRouteUi.CallOutflow.Root}
                            exact
                            component={PageCallOutflow}
                            privateConfig={{
                              allowedRoles: ['Admin'],
                            }}
                          />
                          <PrivateRoute
                            path={AppRouteUi.User.Root}
                            exact
                            component={PageUsers}
                            privateConfig={{
                              allowedRoles: [
                                'Admin',
                                'ResellerAdmin',
                                'CompanyAdmin',
                              ],
                            }}
                          />
                          <PrivateRoute
                            path={AppRouteUi.User.Profile.Root()}
                            exact
                            component={PageUserProfile}
                            privateConfig={{
                              allowedRoles: [
                                'Admin',
                                'ResellerAdmin',
                                'CompanyAdmin',
                              ],
                            }}
                          />
                          <PrivateRoute
                            path={AppRouteUi.TIN.Root}
                            exact
                            component={PageTIN}
                            privateConfig={{
                              allowedRoles: [
                                'Admin',
                                'ResellerAdmin',
                                'CompanyAdmin',
                              ],
                            }}
                          />
                          <PrivateRoute
                            path={AppRouteUi.Language.Root}
                            exact
                            component={PageLanguages}
                            privateConfig={{
                              allowedRoles: [
                                'Admin',
                                'ResellerAdmin',
                                'CompanyAdmin',
                              ],
                            }}
                          />
                          <PrivateRoute
                            path={AppRouteUi.LanguagePacks.Root}
                            exact
                            component={PageLanguagePacks}
                            privateConfig={{
                              allowedRoles: ['Admin', 'ResellerAdmin'],
                            }}
                          />
                          <PrivateRoute
                            path={AppRouteUi.Skill.Root}
                            exact
                            component={PageSkills}
                            privateConfig={{
                              allowedRoles: ['Admin', 'ResellerAdmin'],
                            }}
                          />
                          <PrivateRoute
                            path={AppRouteUi.Modality.Root}
                            exact
                            component={PageModality}
                            privateConfig={{
                              allowedRoles: ['Admin', 'ResellerAdmin'],
                            }}
                          />
                          <PrivateRoute
                            path={AppRouteUi.AppointmentCategory.Root}
                            exact
                            component={PageAppointmentCategory}
                            privateConfig={{
                              allowedRoles: ['Admin', 'ResellerAdmin'],
                            }}
                          />
                          <PrivateRoute
                            path={AppRouteUi.AppointmentDescription.Root}
                            exact
                            component={PageAppointmentDescription}
                            privateConfig={{
                              allowedRoles: ['Admin', 'ResellerAdmin'],
                            }}
                          />
                          <PrivateRoute
                            path={AppRouteUi.AuditLogs.Root}
                            exact
                            component={PageAuditLogs}
                            privateConfig={{
                              allowedRoles: [
                                'Admin',
                                // "ResellerAdmin",
                                //   "CompanyAdmin",
                              ],
                            }}
                          />

                          <PrivateRoute
                            path={AppRouteUi.Operator.Root}
                            exact
                            component={PageOperator}
                            privateConfig={{
                              allowedRoles: [
                                'Admin',
                                'ResellerAdmin',
                                'Manager',
                                'ResellerManager',
                              ],
                            }}
                          />
                          <PrivateRoute
                            path={AppRouteUi.Operator.Profile.Root()}
                            exact
                            component={PageOperatorProfile}
                            privateConfig={{
                              allowedRoles: [
                                'Admin',
                                'ResellerAdmin',
                                'Manager',
                                'ResellerManager',
                              ],
                            }}
                          />
                          <PrivateRoute
                            path={AppRouteUi.Activity.Root}
                            exact
                            component={PageActivity}
                            privateConfig={{
                              allowedRoles: [
                                'Admin',
                                'ResellerAdmin',
                                'Manager',
                                'ResellerManager',
                              ],
                            }}
                          />
                          <PrivateRoute
                            path={AppRouteUi.Message.Root}
                            exact
                            component={PageMessage}
                            privateConfig={{
                              allowedRoles: [
                                'Admin',
                                'ResellerAdmin',
                                'Manager',
                                'ResellerManager',
                              ],
                            }}
                          />
                          <PrivateRoute
                            path={AppRouteUi.CallInflow.Root}
                            exact
                            component={PageCallInflow}
                            privateConfig={{
                              allowedRoles: [
                                'Admin',
                                'ResellerAdmin',
                                'Manager',
                                'ResellerManager',
                              ],
                            }}
                          />

                          <PrivateRoute
                            path={AppRouteUi.Account.Root}
                            exact
                            component={PageMyAccount}
                            privateConfig={{
                              allowedRoles: [
                                'Admin',
                                'Finance',
                                'ResellerAdmin',
                                'ResellerFinance',
                                'CompanyAdmin',
                                'CompanyFinance',
                              ],
                            }}
                          />
                          <PrivateRoute
                            path={AppRouteUi.CompanyFinance.Root}
                            exact
                            component={PageCompanyFinance}
                            privateConfig={{
                              allowedRoles: [
                                'Admin',
                                'Finance',
                                'ResellerAdmin',
                                'ResellerFinance',
                                'CompanyAdmin',
                                'CompanyFinance',
                              ],
                            }}
                          />
                          <PrivateRoute
                            path={AppRouteUi.OperatorFinance.Root}
                            exact
                            component={PageOperatorFinance}
                            privateConfig={{
                              allowedRoles: [
                                'Admin',
                                'Finance',
                                'ResellerAdmin',
                                'ResellerFinance',
                                'CompanyAdmin',
                              ],
                            }}
                          />
                          <PrivateRoute
                            path={AppRouteUi.CompanyFinance.Profile.Root()}
                            exact
                            component={PageCompanyFinanceProfile}
                            privateConfig={{
                              allowedRoles: [
                                'Admin',
                                'Finance',
                                'ResellerAdmin',
                                'ResellerFinance',
                                'CompanyAdmin',
                                'CompanyFinance',
                              ],
                            }}
                          />
                          <PrivateRoute
                            path={AppRouteUi.OperatorFinance.Profile.Root()}
                            exact
                            component={PageOperatorFinanceProfile}
                            privateConfig={{
                              allowedRoles: [
                                'Admin',
                                'Finance',
                                'ResellerAdmin',
                                'ResellerFinance',
                              ],
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <Footer />
                </div>
              </div>
              <PrivateRoute
                path="**"
                component={PageNotFound}
                privateConfig={{
                  allowedRoles: [
                    'Admin',
                    'Operator',
                    'User',
                    'ResellerAdmin',
                    'CompanyAdmin',
                  ],
                }}
              />
            </Switch>
          </CallContextProvider>
        </ChannelContextProvider>
      </SocketContextProvider>
    </>
  );
}
