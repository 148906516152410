import React from 'react';
import { ZoomClient } from '../types';
export default React.createContext<ZoomClient>(null as any);

//It is an unnamed context instance that is created using React.createContext. 
//The name of the context is determined by the variable name or default export name that you assign when exporting or importing it.

//This context does not have a state management (no handler/setter function) - so we are assuming that it is created only for the 
//sake of carrying an object that has its own setters and getters already implemented for it. In this sense, we are only providing
//this context as a value (with no handler functions), since the value itself contains the means through which to edit it.

//This is indeed the case, as we see in src/index.tsx: we are initializing the context imported from here with an object
//called zmClient, which was built using the @zoom/videosdk library. That object (zmClient) has its own functions built into it. 