import { useDispatch } from "react-redux";
import { Link, useHistory } from 'react-router-dom';
import { AssetsPng } from "../../assets";
import GoogleLogoutCom from "../../components/common/google/googleLogout";
import { AppConstants, AppRouteUi } from "../../configs";
import {
  ActionAuth,
  StorageAuth,
  useAppSelector
} from "../../core";
import { ActionUi } from "../../core/Redux/Slices/sliceUi";
import { useFunctionalityAuth } from "../../pages/hooks";
import { searchInCurrentURL } from "../../utils";
import { useEffect } from "react";
import { VRIAppStateType } from "../../core";

export function TopNavPublic(): JSX.Element {
  const user = useAppSelector((state: VRIAppStateType) => state.auth.profile);

  const history = useHistory();
  const dispatch = useDispatch();
  const isLoggedIn = useAppSelector((state: VRIAppStateType) => state.auth.isLoggedIn);
  const { onLogout } = useFunctionalityAuth();
  
  const getLoginPath = (): string => {
    const isMakeSchedule = searchInCurrentURL(AppRouteUi.UserCalendar.Root);
    const isLobby = searchInCurrentURL(AppRouteUi.Lobby.Root);
    const isConsumer = searchInCurrentURL(AppRouteUi.Consumer.Root);
    if (isMakeSchedule || isLobby || isConsumer) {
      return AppRouteUi.Login.ExternalLogin;
    } else {
      return AppRouteUi.Login.Root;
    }
  };

  // const onLogout = React.useCallback(() => {
  //   StorageAuth.Clear();
  //   StorageUserCalendar.Clear();
  //   dispatch(ActionApp.ResetStore());
  // }, [dispatch]);

  useEffect(() => {
    if (StorageAuth.ProfileData?.userRole !== AppConstants.UserRoles.Consumer) {
      StorageAuth.clearStorage();
      dispatch(ActionAuth.resetData());
    }
  }, [dispatch]);

  return (
    <nav className="navbar navbar-expand-lg navbar-light  bg-top-nav sticky-top">
      <div className="nav  nav-item drop-down header-state-info position-relative ml-auto">
        <a
          href=""
          className="nav-link dropdown-toggle "
          id="navbarUserStatusDropdwon"
          role="button"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <div className="d-flex align-items-center ">
            <div className="mr-3 text-white">{user?.name ?? 'Guest User'}</div>
            <div className="header-profile-image d-block">
              <img src={AssetsPng.GenericUser} alt="" className="img-fluid" />
            </div>
          </div>
        </a>
        <div
          className="dropdown-menu dropdown-menu-right py-0"
          aria-labelledby="navbarDropdown"
        >
          {isLoggedIn ? (
            user?.userRole === AppConstants.UserRoles.Consumer ? (
              <GoogleLogoutCom />
            ) : (
              <Link
                to={AppRouteUi.Login.Root}
                className="dropdown-item  d-lg-block d-xl-block"
                onClick={() => {
                  onLogout();
                }}
              >
                Log out
              </Link>
            )
          ) : (
            <Link
              to={getLoginPath()}
              className="dropdown-item d-flex justify-content-center"
              onClick={() => {
                dispatch(ActionUi.SetPreviousRoute(history.location.pathname));
              }}
            >
              Login
            </Link>
          )}
        </div>
      </div>
    </nav>
  );
}
