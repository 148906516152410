import { useState } from "react";
import { useGoogleLogin, GoogleLogin } from '@react-oauth/google';
import { batch, useDispatch } from "react-redux";
import { useHistory } from 'react-router-dom';
import { toast } from "react-toastify";
import { AppConstants, PlatformApi, ExternalLoginConstants, AppRouteUi } from "../../../configs";
import { ActionAuth, AxiosAuth, StorageAuth, useAppSelector } from "../../../core";
import { ApiBodyExternalLogin, ApiSchemaLogin } from "../../../models";
import { VRIAppStateType } from "../../../core";

function GoogleLoginComponent(): JSX.Element {
	const dispatch = useDispatch();
	const history = useHistory();

	const previousPath = useAppSelector((state: VRIAppStateType) => state.ui.previousRoute)
	let userRole = '';

	const [loading, setLoading] = useState<boolean>(false)

	const onSuccess = async (credentialResponse: any) => {
		setLoading(true)

		try {
			const response = await AxiosAuth.post<ApiSchemaLogin>(
				PlatformApi.Authentication.ExternalLogin.Root(),
				{ idToken: credentialResponse.credential, provider: ExternalLoginConstants.LoginProviders.Google } as ApiBodyExternalLogin
			);
			if (response.status === AppConstants.Api.StatusOK && response.data.token) {
				setLoading(false)
				StorageAuth.AccessToken = response.data.token;
				userRole = response.data.profile.userRole;
				StorageAuth.loginStatus = 'true';
				batch(() => {
					dispatch(ActionAuth.SetIsLoggedIn(true));
					dispatch(ActionAuth.SetProfileData(response.data.profile));
				});

				toast.success('Login Successfully!')

				if (!!previousPath && userRole == AppConstants.UserRoles.Consumer)
					history.push(previousPath);
				else
					history.push(AppRouteUi.Consumer.Load())
			}
		} catch (e) {
			setLoading(false)
			toast.error('Failed to Login!')
			console.log(e);
		}
	}

	const onError = () => {
		toast.error('Failed to Login!')
	}

	const googleLogin = useGoogleLogin({
		onSuccess,
		onError,
		flow: 'implicit',
	});


	return (
		<div className="d-flex align-items-center justify-content-center">
			<button className="btn d-flex align-items-center justify-content-center" onClick={() => googleLogin()}>
				{loading && (
					<span
						className="spinner-border spinner-border-sm m-1"
						role="status"
						aria-hidden="true"
					/>
				)}
				<GoogleLogin onSuccess={onSuccess}/>
			</button>
		</div>
	)
}

export default GoogleLoginComponent;