import { promises } from "dns";
import React, { useCallback, useMemo, useState } from "react";
import { toast } from "react-toastify";
import useSWR, { mutate } from "swr";
import {
  PlatformApi,
  AppConstants,
  AppRouteUi,
  topLoaderProgress,
  CompanyType,
} from "../../configs";
import { AxiosAuth, useAppSelector } from "../../core";
import { companyMapper } from "../../helpers";
import {
  ApiResponse,
  ApiResponseWithoutData,
  WithPagination,
} from "../../models/api/common";
import {
  ApiSchemaCompanyByIdData,
  ApiSchemaCompanyData,
  ApiSchemaUpdateCompanySkill,
} from "../../models/api/company";
import { UtilsJQuery } from "../../utils";
import { VRIAppStateType } from "../../core";

type PagedProps = {
  pageSize: number;
  pageNo: number;
  searchText?: string;
  setLoaderProgress: any;
  parentCompanyId: string;
};

let pagedCompanyUrl: string;

export function useFunctionalityPagedReseller(props: PagedProps) {
  const { pageSize, pageNo, searchText, setLoaderProgress } = props;
  const [pagedCompanyListCount, setPagedCompanyListCount] = useState<number>(0);

  pagedCompanyUrl = PlatformApi.Reseller.GetAll(pageSize, pageNo, searchText);
  const { data: apiCompanyData } = useSWR<ApiSchemaCompanyData[]>(
    pagedCompanyUrl,
    () => {
      setLoaderProgress(topLoaderProgress.start);

      return AxiosAuth.get<ApiResponse<WithPagination<ApiSchemaCompanyData[]>>>(
        pagedCompanyUrl
      )
        .then((r) => {
          setLoaderProgress(topLoaderProgress.complete);
          setPagedCompanyListCount(r.data.data?.count);
          return r.data.data.data;
        })
        .catch((e) => {
          setLoaderProgress(topLoaderProgress.complete);
          console.error(e);
          return e;
        });
    }
  );

  const pagedCompanyList = useMemo(() => {
    const data = Array.isArray(apiCompanyData) ? apiCompanyData : [];
    return data;
  }, [apiCompanyData]);

  return {
    pagedCompanyListCount,
    pagedCompanyList,
  };
}
type Props = {
  companyType?: CompanyType;
  setLoaderProgress?: any;
  parentId?: string;
  shouldVendorGetAllInvoke?: boolean;
};

export function useFunctionalityReseller(props: Props) {
  const {
    companyType,
    setLoaderProgress,
    parentId,
    shouldVendorGetAllInvoke = false,
  } = props;
  const formData = useAppSelector((state: VRIAppStateType) => state.company);
  const companyId = AppRouteUi.Reseller.Profile.useParam();

  const { data: apiCompanyById } = useSWR<ApiSchemaCompanyByIdData>(
    companyId && shouldVendorGetAllInvoke
      ? PlatformApi.Reseller.GetById(companyId)
      : null,
    () => {
      setLoaderProgress(topLoaderProgress.start);
      return AxiosAuth.get<ApiResponse<ApiSchemaCompanyByIdData>>(
        PlatformApi.Reseller.GetById(companyId)
      )
        .then((r) => {
          setLoaderProgress(topLoaderProgress.complete);
          return r.data.data;
        })
        .catch((e) => {
          setLoaderProgress(topLoaderProgress.complete);
          console.error(e);
          return e;
        });
    }
  );

  const companyByIdData = () => {
    const data: ApiSchemaCompanyByIdData =
      typeof apiCompanyById === "object" ? apiCompanyById : {};
    return data;
  };

  const onCreateCompany = React.useCallback(async () => {
    const btnLoading = UtilsJQuery.Ladda(".login-from-submit-btn");
    btnLoading.start?.();
    try {
      const response = await AxiosAuth.post(
        PlatformApi.Reseller.Create(),
        companyMapper(formData, companyType ?? 0, parentId)
      );

      if (response.status === AppConstants.Api.StatusOK) {
        mutate(pagedCompanyUrl);
        toast.success("Company Creation Successful");
      }
    } catch (e) {
      toast.error("Company Creation Failed");
    }
    btnLoading.stop?.();

    return Promise.resolve(null);
  }, []);

  const onUpdateCompanySkill = React.useCallback(
    (companyId: string, body: ApiSchemaUpdateCompanySkill) => {
      AxiosAuth.put(PlatformApi.Company.UpdateCompanySkill(companyId), body)
        .then((res) => {
          if (res?.status === AppConstants.Api.StatusOK) {
            toast.success(res?.data?.message);
            mutate(PlatformApi.Company.GetById(companyId));
          }
        })
        .catch((e) => {
          toast.error(e);
          console.log(e);
        });
    },
    []
  );
  const addPhoneNumber = React.useCallback(
    async (callCenterId: string, phoneNumber: string) => {
      const btnLoading = UtilsJQuery.Ladda(".btn-phone-add");
      btnLoading.start?.();
      try {
        const response = await AxiosAuth.post<ApiResponseWithoutData>(
          PlatformApi.CallCenter.AddPhoneNumber(callCenterId, phoneNumber)
        );

        if (response.status === AppConstants.Api.StatusOK) {
          if (response.data.isSuccess) {
            mutate(pagedCompanyUrl);
            toast.success("Phone Number Add Successful");
            btnLoading.stop?.();
          } else {
            toast.error(response.data.message);
          }
        }
      } catch (e) {
        toast.error("Adding Phone Number  Failed");
        btnLoading.stop?.();
        return Promise.reject(e);
      }

      return Promise.resolve(null);
    },
    []
  );

  const removePhoneNumber = React.useCallback(async (id: string) => {
    const btnLoading = UtilsJQuery.Ladda(".user-from-submit-btn");
    btnLoading.start?.();
    try {
      const response = await AxiosAuth.delete(
        PlatformApi.CallCenter.RemovePhoneNumber(id)
      );

      if (response.status === AppConstants.Api.StatusOK) {
        mutate(pagedCompanyUrl);
        toast.success("Phone Number deleted Successful");
        btnLoading.stop?.();
      }
    } catch (e) {
      toast.error("Deleting Phone Number  Failed");
      btnLoading.stop?.();
    }

    return Promise.resolve(null);
  }, []);
  const getPhoneNumberList = React.useCallback(async (callCenterId: string) => {
    try {
      setLoaderProgress(topLoaderProgress.start);
      const response = await AxiosAuth.get(
        PlatformApi.CallCenter.GetPhoneNumberList(callCenterId)
      );

      if (response.status === AppConstants.Api.StatusOK) {
        setLoaderProgress(topLoaderProgress.complete);
        return Promise.resolve(response.data);
      }
    } catch (e) {
      setLoaderProgress(topLoaderProgress.complete);
      // toast.error("Deleting Phone Number  Failed");
    }
    return Promise.resolve(null);
  }, []);
  return {
    onCreateCompany,
    companyByIdData,
    apiCompanyById,
    onUpdateCompanySkill,
    addPhoneNumber,
    removePhoneNumber,
    getPhoneNumberList,
  };
}
export function useFunctionalityResellerNames() {
  const onResellerNames = useCallback(async () => {
    const resellerNamesUrl = PlatformApi.Reseller.GetResellerNames();
    try {
      const res = await AxiosAuth.get<ApiResponse<any>>(resellerNamesUrl);

      if (res.status === AppConstants.Api.StatusOK) {
      }
      return Promise.resolve(res.data);
    } catch (e) {
      return Promise.reject(null);
    }
  }, []);

  return {
    onResellerNames,
  };
}
