import React from "react";
import moment from "moment";
import { AppConstants, PaymentCycleType } from "../../../../configs";
import { Subscription } from "../../../../models/api/company";
import { useAppSelector } from "../../../../core";
import { AssetSvg } from "../../../../assets";
import { VRIAppStateType } from "../../../../core";

type Props = {
  handleShow: (value: boolean) => void;
  data: Subscription | undefined;
  isReseller?: boolean;
  editable?: boolean
};

export function CardPostPaid(props: Props): JSX.Element {
  const { handleShow, data, isReseller, editable } = props;
  const userRole = useAppSelector((state: VRIAppStateType) => state.auth.profile?.userRole);

  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const cycleTime = (time: string) => {
    const date = new Date(time);

    if (Object.prototype.toString.call(date) === "[object Date]") {
      if (isNaN(date.getTime())) {
        return "";
      }
    } else {
      return "";
    }

    const modifiedDate = monthNames[date.getMonth()]
      .concat(" " + date.getDate().toString())
      .concat(", " + date.getFullYear().toString());
    return modifiedDate;
  };

  const getCycleStartTime = (cycleStartTime: string) => {
    return cycleTime(cycleStartTime.replace(/-/g, "/"));
  };

  const getCycleEndTime = (cycleEndTime: string) => {
    return cycleTime(cycleEndTime.replace(/-/g, "/"));
  };

  const getYearlyCycleTime = () => {
    const date = new Date();
    const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    const startCycle = cycleTime(moment(firstDay).format("MM-DD-YYYY"));

    let yearFromFirstDay = new Date(firstDay);
    yearFromFirstDay.setFullYear(yearFromFirstDay.getFullYear() + 1);
    yearFromFirstDay.setDate(yearFromFirstDay.getDate() - 1);

    const endCycle = cycleTime(moment(yearFromFirstDay).format("MM-DD-YYYY"));

    return startCycle.concat(" - ").concat(endCycle);
  };

  const getMonthlyCycleTime = () => {
    const date = new Date();
    const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);

    const startCycle = cycleTime(moment(firstDay).format("MM-DD-YYYY"));
    const endCycle = cycleTime(moment(lastDay).format("MM-DD-YYYY"));

    return startCycle.concat(" - ").concat(endCycle);
  };

  const getMonthlyOrYearlyCycleTime = () => {
    if (data?.paymentCycle === PaymentCycleType.Monthly) {
      return getMonthlyCycleTime();
    }
    // else if(data?.paymentCycle === PaymentCycleType.Yearly) {
    //   return getYearlyCycleTime();
    // }
  };

  const checkDate = (cycleEndTime: string) => {
    const date = new Date(cycleEndTime);
    const currentDate = new Date();

    if (currentDate.getTime() > date.getTime()) {
      return true;
    }
    return false;
  };

  return (
    <div className="component-card mb-3">
      <div className="component-card-header d-flex align-items-center justify-content-between border-top border-right border-left">
        <div>Post Paid</div>
        {editable && (
          <button
            className="btn btn-link px-0 text-site btn-sm text-uppercase"
            onClick={() => handleShow(true)}
          >
            <div className="btn svg-color" title="contractPostPaidEdit">
              <span>
                <AssetSvg.Edit />
              </span>
            </div>
          </button>
        )}
      </div>
      <div className="component-card-body border">
        <div className="row flex-wrap mb-2">
          <div className="col-12">
            <div className="mb-0 d-flex flex-wrap align-items-center">
              <div className="text-muted">Subscription rate</div>
            </div>
          </div>
          <div className="col-12">
            <p className="mb-0 text-dark font-500 currency-symbol">
              {`$${data?.subscriptionRate}`}
            </p>
          </div>
        </div>

        <div className="row flex-wrap mb-2">
          <div className="col-12">
            <p className="text-muted mb-0">Payment Cycle</p>
          </div>
          <div className="col-12">
            <div className="mb-0 d-flex flex-wrap align-items-center">
              <p className="currency-value mb-0 text-dark font-500">
                {data?.paymentCycle
                  ? PaymentCycleType[data?.paymentCycle]
                  : null}
              </p>
            </div>
          </div>
        </div>
        {
          !isReseller && <div className="row flex-wrap mb-2">
            <div className="col-12">
              <p className="mb-0 text-muted">Credit limit</p>
            </div>
            <div className="col-12">
              <div className="mb-0 d-flex flex-wrap align-items-center">
                <p className="currency-symbol text-dark font-500 mb-0">{`$${data?.creditLimit}`}</p>
              </div>
            </div>
          </div>
        }
        <div className="row flex-wrap mb-2">
          <div className="col-12">
            <p className="mb-0 text-muted">Current cycle</p>
          </div>
          <div className="col-12">
            <div className="mb-0 d-flex flex-wrap align-items-center">
              <p className="currency-value mb-0 text-dark font-500">
                {checkDate(moment(data?.endDate).format("MM-DD-YYYY"))
                  ? getMonthlyOrYearlyCycleTime()
                  : getCycleStartTime(
                    moment(data?.startDate).format("MM-DD-YYYY")
                  )?.concat(" - ") +
                  getCycleEndTime(moment(data?.endDate).format("MM-DD-YYYY"))}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
