import React, { createContext, useContext, useState, useEffect } from 'react';
import { useZoomVideo } from '../components/VideoProvider/useZoomVideo/useZoomVideo';
import { useSelector, shallowEqual } from 'react-redux';
import { TSliceUi } from '../core';
import { getExploreName } from '../utils/platform';
import { useLocation } from 'react-router-dom';
import { useFunctionalitySessionMaps } from '../pages/hooks/functionalityGetSessionMaps';
import { SessionMapsResponseModel } from "../models/api/Call";
import { VRIAppStateType, useAppSelector  } from '../core';

const ZoomContext = createContext(null as any);

//@ts-ignore
export const ZoomCallProvider = ({ children }) => {

  //for the url params
  const [sessionMapsData, setSessionMapsData] = useState<SessionMapsResponseModel | null>(null);
  const [apiLoading, setIsApiLoading] = useState<boolean>(false);
  const { LoadSessionMaps } = useFunctionalitySessionMaps();

  const searchParams = new URLSearchParams(useLocation().search);
  const guid = searchParams.get("call"); //if no such parameter exists in the current path, guid will be undefined, which is exactly the behaviour we want.

  //getting username
  const user = useAppSelector(
    (state: VRIAppStateType) => ({
      name: state.auth.profile?.name,
    }),
    shallowEqual
  );


  //for websocket params 
  const myTopic: string = useSelector((state: { ui: TSliceUi }) => state.ui.SessionName) || "notopic";
  const mySignature: string = useSelector((state: { ui: TSliceUi }) => state.ui.jwtToken) || "nosignature";


  useEffect(() => {
    const fetchSessionData = async (guid: string) => {
      setIsApiLoading(true);
      try {
        // Call LoadSessionMaps instead of making the API call directly
        const data = await LoadSessionMaps(guid);

        //@ts-ignore
        setSessionMapsData(data);
      } catch (error) {
        console.error("Error fetching session data:", error);
      } finally {
        setIsApiLoading(false);
      }
    };

    if (guid) {
      fetchSessionData(guid);
    }
  }, [guid, LoadSessionMaps]);

  const {
    loading,
    loadingText,
    isSupportGalleryView,
    galleryViewWithAttach,
    mediaContext,
  } = useZoomVideo({
    sdkKey: '', // Replace with your actual SDK key
    topic: sessionMapsData?.sessionName || myTopic,
    signature: sessionMapsData?.jwtToken || mySignature,
    name: user.name,
    userName: '',
    password: '', // Replace with your actual password
    webEndpoint: 'zoom.us', // Or other endpoint if needed
    enforceGalleryView: 1,
    enforceVB: 1,
    useVideoPlayer: 1,
    sessionKey: '1234569',
    userIdentity: `${Math.floor(Math.random() * 1000)}`,
    role: 0
  });

  const value = {
    loading: loading || apiLoading,
    loadingText,
    isSupportGalleryView,
    galleryViewWithAttach,
    mediaContext,
    sessionMapsData //added by us, to be able to check if a sessionMapsData does exist on a guid
  };


  console.log("zoomCall-context has been rerended. The value of mediaContext is:");
  console.log(mediaContext);
  
  return (
    <ZoomContext.Provider value={value}>
      {children}
    </ZoomContext.Provider>
  );
};

export const useZoomCall = () => useContext(ZoomContext);
