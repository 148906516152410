import React, { useEffect } from "react";
import { shallowEqual, useDispatch } from "react-redux";
import { useHistory } from 'react-router-dom';
import { Connection } from "twilio-client";
import { ActionCommon, useAppSelector } from "..";
import {
  AppRouteUi,
  CallStatusType,
  device
} from "../../configs";
import { ActionAudioCall } from "../../core/Redux/Slices/sliceCall";
import { logger } from "../../utils";
import { VRIAppStateType } from "../../core";

export const CallContext = React.createContext<any>(12);

export function CallContextProvider(props: React.PropsWithChildren<{}>) {
  const [callinfo, setCallinfo] = React.useState<Connection>(Object);
  const [isAccepted, setIsAccepted] = React.useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  //for OPI calling

  const data = useAppSelector(
    (state: VRIAppStateType) => ({
      userRole: state.auth.profile?.userRole,
      userId: state.auth.profile?.id,
      callerId: state.ui.callerId,
      audioCallStatus: state.audioCall.audioCallStatus,
      receiverId: state.ui.receiverId,
    }),
    shallowEqual
  );
  
  useEffect(()=> {
    const handleDeviceOffline = () => {
      dispatch(ActionCommon.setTwilioDeviceStatus('offline'));
    };
    const handleDeviceReady = () => {
      dispatch(ActionCommon.setTwilioDeviceStatus('online'));
    };
    device.on('offline', handleDeviceOffline);
    device.on('ready', handleDeviceReady);

    return () => {
      device.off('offline', handleDeviceOffline);
      device.off('ready', handleDeviceReady);
    };
  },[dispatch]);

  useEffect(() => {
    const listenerIncoming = function (call: Connection) {
      if (
        data.audioCallStatus?.current == CallStatusType.Canceled &&
        data.audioCallStatus?.prev == CallStatusType.Zero
      ) {
      } else {
        const callId =
          call.customParameters?.get("CallId") ??
          call.customParameters?.get("callId") ??
          "";
        dispatch(
          ActionAudioCall.SetAudioCallStatus({
            prev: CallStatusType.Initiated,
            current: CallStatusType.Ringing,
          })
        );
        setCallinfo(call);

        history.push(AppRouteUi.IncomingCall.Root);
      }
    };
    const listenerConnect = function (call: Connection) {
      logger({message: "Connected: ", data: (call as any).message});
      dispatch(
        ActionAudioCall.SetAudioCallStatus({
          prev: CallStatusType.Ringing,
          current: CallStatusType.Established,
        })
      );
      setIsAccepted(true);
      const callId =
        call.customParameters?.get("CallId") ??
        call.customParameters?.get("callId") ??
        "";
      const receiverId =
        call.customParameters?.get("ReceiverId") ??
        call.customParameters?.get("to") ??
        "";
      if (data.userId == receiverId) {
        let _startTime = new Date().toISOString();
        // onUpdate(callId, {
        //   callStatus: CallStatusType.Established,
        //   startTime: _startTime,
        // });
      }
    };

    const listenerOutgoing = function (call: any) {
      setCallinfo(call);
      console.log("outgoing", call);
    };

    device.on("incoming", listenerIncoming);

    device.on("disconnect", (call: any, err?: Error) => {
      if (call._isAnswered) {
        console.log("Call end Time" + new Date());
        dispatch(
          ActionAudioCall.SetAudioCallStatus({
            prev: CallStatusType.Established,
            current: CallStatusType.Completed,
          })
        );
      }
    });

    device.on("outgoing", listenerOutgoing);
    device.on("connect", listenerConnect);
    return () => {
      device.off("incoming", listenerIncoming);
      device.off("outgoing", listenerOutgoing);
      device.off("outgoing", listenerConnect);
    };
  }, [history]);

  const getAudioCallEndTime = () => {
    return new Date().toString();
  };

  // callInfo?.on("reject", () => {
  //   console.log("rejected");
  //   // history.push({
  //   //   pathname: AppRouteUi.Home.Root(),
  //   // });
  // });

  // callInfo?.on("cancel", () => {
  //   console.log("canceled");
  //   // history.push({
  //   //   pathname: AppRouteUi.Home.Root(),
  //   // });
  // });

  return (
    <CallContext.Provider
      value={{
        callinfo,
        isAccepted,
        setIsAccepted,
      }}
    >
      {props.children}
    </CallContext.Provider>
  );
}
