import { useState } from "react";
import { Button } from "react-bootstrap";
import { useHistory } from "react-router";
import {AppRouteUi, PlatformApi} from "../../configs/appRoute";
import { useAppSelector } from "../../core";
import { CommonUserProfile } from "../../features";
import { ApiSchemaUpdateUser } from "../../models";
import { useFunctionalityUser, useFunctionalityUser2, useFunctionalityUserGetDetailsById } from "../hooks";
import {mutate} from "swr";
import { VRIAppStateType } from "../../core";

export function PageUserProfile(): JSX.Element {
  const userId = AppRouteUi.User.Profile.useParam();

  const history = useHistory();
  const profile = useAppSelector((state: VRIAppStateType) => state.auth.profile);
  const [editModalShow, setEditModalShow] = useState(false);
  const { userDetailsData } = useFunctionalityUserGetDetailsById({ userId: userId });
  const { updateUserDetails } = useFunctionalityUser2({ userId: userId });
  const onUpdateHandler = (form: ApiSchemaUpdateUser) => {

    form = { ...form, id: userDetailsData?.id, gender: Number(form.gender) }
    updateUserDetails(form)
      .then(() => {
        mutate(PlatformApi.Users.GetDetailsById(userId || ""));
        setEditModalShow(false);
      })
      .catch((e: any) => { console.error(e) })
  };

  return (
    <>
      <Button  className="my-3"onClick={() => history.goBack()}>Back to List</Button>
      <CommonUserProfile profile={profile} userDetailsData={userDetailsData} onUpdateHandler={onUpdateHandler} editModalShow={editModalShow} setEditModalShow={setEditModalShow} />
    </>
  );
}
