import { useCallback } from "react";
import { VriApi, AppConstants } from "../../configs";
import { useAppSelector, AxiosAuth } from "../../core";
import { ApiResponse } from "../../models";
import { SessionMapsResponseModel } from "../../models/api/Call";

export function useFunctionalitySessionMaps() {

  const LoadSessionMaps = useCallback(async (guidGenerated: string) => {
    const sessionMapsUrl = VriApi.Session.GetSessionMaps(guidGenerated);
    try {
      const res = await AxiosAuth.get<ApiResponse<SessionMapsResponseModel>>(sessionMapsUrl);

    //   console.log("hook res");
    //   console.log(res);
      if (res.status === AppConstants.Api.StatusOK) {
        return Promise.resolve(res.data);
      }
    } catch (e) {
      return Promise.reject(e);
    }
  }, []);

  return {
    LoadSessionMaps,
  };
}
