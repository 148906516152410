import { useEffect, useRef, useContext, useState, useCallback, useReducer, useMemo } from 'react';
import { Router, BrowserRouter as ZoomRouter, Switch, Route } from 'react-router-dom';
import ZoomVideo, { ConnectionState, ReconnectReason } from '@zoom/videosdk';
import { message, Modal } from 'antd';
import 'antd/dist/antd.min.css';
import produce from 'immer';

// import Video from './features/video/video';
// import VideoSingle from './features/video/video-single';
// import VideoAttach from './features/video/video-attach';

import * as Sentry from '@sentry/react';
import { createBrowserHistory } from 'history';
import { shallowEqual } from 'react-redux';
// import { Router } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { AppConfig, PlatformApi } from './configs';
import {
  ActionAuth,
  ActionCommon,
  StorageAuth,
  useAppDispatch,
  useAppSelector,
  VRIAppStateType
} from './core';
import { ApiSchemaProfileData } from './models';
import { OperatorStatusType } from './models/api/Operator';
import { LayoutApplication } from './pages';
import { NavigationUtils, UserAgentUtils, logger } from './utils';

import ZoomMediaContext from './contexts/media-context';


import { ZoomCallProvider } from './contexts/zoomCall-context';


// Initial media state shape
const mediaShape = {
  audio: { encode: false, decode: false },
  video: { encode: false, decode: false },
  share: { encode: false, decode: false },
};

// Reducer to manage media state
const mediaReducer = produce((draft: any, action: any) => {
  switch (action.type) {
    case 'audio-encode':
      draft.audio.encode = action.payload;
      break;
    case 'audio-decode':
      draft.audio.decode = action.payload;
      break;
    case 'video-encode':
      draft.video.encode = action.payload;
      break;
    case 'video-decode':
      draft.video.decode = action.payload;
      break;
    case 'share-encode':
      draft.share.encode = action.payload;
      break;
    case 'share-decode':
      draft.share.decode = action.payload;
      break;
    case 'reset-media':
      Object.assign(draft, { ...mediaShape });
      break;
    default:
      break;
  }
}, mediaShape);


export const history = UserAgentUtils.isElectron()
  ? createBrowserHistory({
    basename: window.location.pathname,
  })
  : createBrowserHistory();

function App() {

  //same code found inside useZoomVideo.ts
  const [mediaState, mediaDispatch] = useReducer(mediaReducer, mediaShape);
  const [mediaStream, setMediaStream] = useState<MediaStream | null>(null);

  const mediaContext = useMemo(() => ({ ...mediaState, mediaStream }), [mediaState, mediaStream]);

  const dispatch = useAppDispatch();
  const userData = useAppSelector(
    (state: VRIAppStateType) => ({
      mail: state.auth.profile?.email,
    }),
    shallowEqual
  );
  const isFirstLoadRef = useRef(true);
  const serviceWorkerRef = useRef<ServiceWorker | null>(null);

  Sentry.setUser({ email: userData.mail });
  // Sentry.setContext("character", {
  //   name: "Mighty Fighter",
  //   age: 19,
  //   attack_type: "melee",
  // });

  useEffect(() => {
    logger({ message: 'User Agent: ', data: window.navigator.userAgent });
  }, []);

  useEffect(() => {
    const handleLocalStorageChange = () => {
      window.addEventListener('storage', (event) => {
        try {
          if (
            event.key === 'vri/auth/loginStatus' &&
            event.newValue === 'false'
          ) {
            StorageAuth.clearStorage();
            window.location.reload();
          }
          if (event.key === 'vri/auth/profile') {
            const previousValue = JSON.parse(
              event.oldValue!
            ) as ApiSchemaProfileData;
            const newValue = JSON.parse(
              event.newValue!
            ) as ApiSchemaProfileData;
            if (
              previousValue.userStatus !== newValue.userStatus &&
              newValue.userStatus !== OperatorStatusType.Offline
            ) {
              dispatch(
                ActionAuth.SetUserStatus(StorageAuth.ProfileData?.userStatus!)
              );
            }
          }
        } catch (error) {
          console.error(error);
        }
      });
    };

    window.addEventListener('storage', handleLocalStorageChange);
    return () => {
      window.removeEventListener('storage', handleLocalStorageChange);
    };
  }, [dispatch]);

  useEffect(() => {
    if (
      !AppConfig.tabQueue ||
      UserAgentUtils.isMobileBrowser() ||
      UserAgentUtils.isWebView() ||
      !('serviceWorker' in navigator) ||
      !isFirstLoadRef.current
    ) {
      return;
    }
    isFirstLoadRef.current = false;

    const { serviceWorker } = navigator;
    serviceWorker.register('/serviceWorker.js');

    serviceWorker.ready
      .then((registration) => {
        const serviceWorker = registration.active;
        serviceWorkerRef.current = serviceWorker;
        const message = {
          type: 'visit',
          isRefreshed: NavigationUtils.isPageRefreshed(),
        };
        serviceWorker?.postMessage(message);
        logger({ message: 'Sending [Service Worker] : ', data: message });
      })
      .catch((error) => {
        logger({ message: 'Error [Service Worker] : ', data: error });
      });

    serviceWorker.addEventListener('message', (event) => {
      logger({ message: '[Service Worker] Sent : ', data: event.data });
      if (event.data.type === 'status') {
        dispatch(ActionCommon.setShouldConnectWS(event.data.active));
      }
    });
  }, [dispatch]);

  useEffect(() => {
    if (
      !AppConfig.tabQueue ||
      UserAgentUtils.isMobileBrowser() ||
      UserAgentUtils.isWebView() ||
      !('serviceWorker' in navigator)
    ) {
      return;
    }
    const handleUnload = () => {
      serviceWorkerRef.current?.postMessage({
        type: 'leave',
        url: PlatformApi.Operator.ChangeCurrentStatus(
          StorageAuth?.ProfileData?.id,
          OperatorStatusType.Offline
        ),
        token: StorageAuth.AccessToken,
      });
    };
    window.addEventListener('beforeunload', handleUnload);
    return () => {
      window.removeEventListener('beforeunload', handleUnload);
    };
  }, []);


  // if (loading) {
  //   return <div>{loadingText || 'Loading...'}</div>;
  // }

  /********************************************************************************************************* */

  return (
    // <ZoomCallProvider>
    // <ZoomMediaContext.Provider value={mediaContext}>
    <Router history={history}>
      <ZoomCallProvider>
        <ToastContainer />
        <LayoutApplication />
      </ZoomCallProvider>
    </Router>
    // </ZoomMediaContext.Provider>
    // </ZoomCallProvider>

  );
}

export default App;