import moment from "moment";
import React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from 'react-router-dom';
import { toast } from "react-toastify";
import useSWR, { mutate } from "swr";
import { AppRouteUi, VriApi } from "../../configs";
import { AppConstants, CallStatusType, CallType } from "../../configs/appConstants";
import { ActionVideoCall, ActionUi, AxiosAuth, useAppSelector } from "../../core";
import { ApiResponse } from "../../models";
import {
  ContactInfo,
} from "../../models/api/videoconference";
import { UserMediaUtils, logger } from "../../utils";
import { VRIAppStateType } from "../../core";

type ComponentState = {
  roomId: string;
  contactInfo: ContactInfo[];
  conferenceType: string;
  startDate?: string;
  endDate?: string;
  startTime?: string;
  endTime?: string;
  interpreterRequired: boolean;
  language?: string;
  languageName?: string;
  skill?: string;
  gender?: string;
  department?: string;
  location?: any;
  customFields?: any[];
  isBusy: boolean;
  timeZone: string;
};

type ComponentActions =
  | { type: "setRoomId"; payload: string }
  | { type: "setContactInfo"; payload: ContactInfo[] }
  | { type: "setConferenceType"; payload: string }
  | { type: "setStartDate"; payload: string | undefined }
  | { type: "setEndDate"; payload: string | undefined }
  | { type: "setStartTime"; payload: string | undefined }
  | { type: "setEndTime"; payload: string | undefined }
  | { type: "setInterpreterRequired"; payload: boolean }
  | { type: "setLanguage"; payload: string | undefined }
  | { type: "setLanguageName"; payload: string | undefined }
  | { type: "setSkill"; payload: string | undefined }
  | { type: "setGender"; payload: string | undefined }
  | { type: "setDepartment"; payload: string | undefined }
  | { type: "setLocation"; payload: any | undefined }
  | { type: "setCustomFields"; payload: any[] | undefined }
  | { type: "setIsNotBusy" }
  | { type: "setIsBusy" }
  | { type: "reset" }
  | { type: "setTimeZone"; payload: string | undefined };

type TypeComponentReducer = React.Reducer<ComponentState, ComponentActions>;

export function useFunctionalityVideoScheduler(pageSize?: number, pageNo?: number, tableType?: string) {
  const [generateButtonDisable, setGenerateButtonDisable] =
    React.useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const defaultTimeZoneUser = useAppSelector((state: VRIAppStateType) => state.auth.profile?.timeZone);
  const ComponentStateInit = {
    roomId: "",
    contactInfo: [
      {
        email: "",
        phone: "",
      },
    ],
    conferenceType: "instantConference",
    interpreterRequired: false,
    isBusy: false,
    customFields: [],
    startDate: moment().format('YYYY-MM-DD'),
    endDate: moment().format('YYYY-MM-DD'),
    startTime: moment().format('HH:mm'),
    endTime: moment().format('HH:mm'),
    timeZone: defaultTimeZoneUser ? defaultTimeZoneUser : Intl.DateTimeFormat().resolvedOptions().timeZone
  };

  const ComponentReducer: TypeComponentReducer = (state:any, action:any) => {
    switch (action.type) {
      case "setRoomId":
        return { ...state, roomId: action.payload ?? "" };
      case "setContactInfo":
        return { ...state, contactInfo: action.payload ?? [] };
      case "setConferenceType":
        return { ...state, conferenceType: action.payload ?? "" };
      case "setStartDate":
        return { ...state, startDate: action.payload };
      case "setEndDate":
        return { ...state, endDate: action.payload };
      case "setStartTime":
        return { ...state, startTime: action.payload };
      case "setEndTime":
        return { ...state, endTime: action.payload };
      case "setInterpreterRequired":
        return { ...state, interpreterRequired: action.payload };
      case "setLanguage":
        return { ...state, language: action.payload ?? "" };
      case "setLanguageName":
        return { ...state, languageName: action.payload ?? "" };
      case "setSkill":
        return { ...state, skill: action.payload ?? "" };
      case "setGender":
        return { ...state, gender: action.payload ?? "" };
      case "setDepartment":
        return { ...state, department: action.payload ?? "" };
      case "setLocation":
        return { ...state, location: action.payload ?? "" };
      case "setCustomFields":
        return { ...state, customFields: action.payload ?? [] };
      case "setIsNotBusy":
        return { ...state, isBusy: false };
      case "setIsBusy":
        return { ...state, isBusy: true };
      case "reset":
        return ComponentStateInit;
      case "setTimeZone":
        return { ...state, timeZone: action.payload ?? "" };
      default:
        return state;
    }
  };

  const videoSchedulerUrl = VriApi.Conference.GetConference.Root(pageSize!, pageNo!);

  const [state, stateDispatch] = React.useReducer<TypeComponentReducer>(
    ComponentReducer,
    ComponentStateInit
  );

  const setRoomId = React.useCallback(
    (id: string) => stateDispatch({ type: "setRoomId", payload: id }),
    []
  );
  const setContactInfo = React.useCallback(
    (info: ContactInfo[]) =>
      stateDispatch({ type: "setContactInfo", payload: info }),
    []
  );
  const setConferenceType = React.useCallback(
    (conferenceType: string) =>
      stateDispatch({ type: "setConferenceType", payload: conferenceType }),
    []
  );
  const setStartDate = React.useCallback(
    (startDate?: string) =>
      stateDispatch({ type: "setStartDate", payload: startDate }),
    []
  );
  const setEndDate = React.useCallback(
    (endDate?: string) =>
      stateDispatch({ type: "setEndDate", payload: endDate }),
    []
  );
  const setStartTime = React.useCallback(
    (startTime?: string) =>
      stateDispatch({ type: "setStartTime", payload: startTime }),
    []
  );
  const setEndTime = React.useCallback(
    (endTime?: string) =>
      stateDispatch({ type: "setEndTime", payload: endTime }),
    []
  );
  const setInterpreterRequired = React.useCallback(
    (required: boolean) =>
      stateDispatch({ type: "setInterpreterRequired", payload: required }),
    []
  );
  const setLanguage = React.useCallback(
    (language?: string) =>
      stateDispatch({ type: "setLanguage", payload: language }),
    []
  );
  const setLanguageName = React.useCallback(
    (languageName?: string) =>
      stateDispatch({ type: "setLanguageName", payload: languageName }),
    []
  );
  const setSkill = React.useCallback(
    (skill?: string) => stateDispatch({ type: "setSkill", payload: skill }),
    []
  );
  const setGender = React.useCallback(
    (gender?: string) => stateDispatch({ type: "setGender", payload: gender }),
    []
  );
  const setDepartment = React.useCallback(
    (department?: string) => stateDispatch({ type: "setDepartment", payload: department }),
    []
  );
  const setLocation = React.useCallback(
    (location?: any) => stateDispatch({ type: "setLocation", payload: location }),
    []
  );
  const setCustomFields = React.useCallback(
    (customFields?: any) => stateDispatch({ type: "setCustomFields", payload: customFields }),
    []
  );
  const setTimeZone = React.useCallback(
    (timeZone?: string) => stateDispatch({ type: "setTimeZone", payload: timeZone }),
    []
  );

  const onCreateSchedule = () => {
    if (!state.roomId) {
      toast.error('Please generate room link!')
      return
    }
    stateDispatch({ type: "setIsBusy" });

    const body = new FormData();

    body.append("roomId", state.roomId);
    body.append("contactInfo", JSON.stringify(state.contactInfo));
    body.append("conferenceType", state.conferenceType);
    body.append("timeZone", state.timeZone);

    if (state.conferenceType === 'scheduledConference') {
      body.append("startDate", state.startDate ?? "");
      body.append("endDate", state.endDate ?? "");
      body.append("startTime", state.startTime ?? "");
      body.append("endTime", state.endTime ?? "");
    }
    if (state.interpreterRequired) {
      body.append("interpreterRequired", state.interpreterRequired.toString());
      body.append("language", state.language ?? "");
      body.append("skill", state.skill ?? "");
      body.append("gender", state.gender ?? "");
      body.append("department", state.department ?? "");
      body.append("location", state.location ?? "");
      body.append("customFields", JSON.stringify(state.customFields));
    }
    const getStartDate = body.get('startDate')
    const getEndDate = body.get('endDate')
    const getStartTime = body.get('startTime')
    const getEndTime = body.get('endTime')

    if ((getStartDate) && ((getStartDate! > getEndDate!) || ((getStartDate === getEndDate) && (getStartTime! >= getEndTime!)))) {
      if (getStartDate! > getEndDate!) {
        toast.error('End Date must be greater!')
        stateDispatch({ type: "setIsNotBusy" });
      } else if (getStartDate === getEndDate) {
        if (getStartTime! >= getEndTime!) {
          toast.error('End Time must be greater!')
          stateDispatch({ type: "setIsNotBusy" });
        }
      }
    } else {

      AxiosAuth.post(VriApi.Conference.CreateConference.Root(), body)
        .then((r) => {
          if (r.status === AppConstants.Api.StatusOK) {
            toast.success("Room Creation Successful");
            setGenerateButtonDisable(false);
            mutate(VriApi.Conference.GetConference.Root(10, 1));

            if (state.conferenceType === "instantConference") {
              // history.push({
              //   pathname: AppRouteUi.Lobby.WaitingRoom.Load(state.roomId),
              //   state: {
              //     interpreterRequired: state.interpreterRequired,
              //   },
              // });
              dispatch(
                ActionVideoCall.SetCallStatus({
                  prev: CallStatusType.Received,
                  current: CallStatusType.Established,
                })
              )
              dispatch(ActionUi.SetPreviousRoute(history.location.pathname));
              dispatch(ActionUi.SetRoomId(state.roomId));
              history.push({
                pathname: AppRouteUi.VideoConference.Room.Load(
                  state.roomId,
                  state.interpreterRequired
                ),
                state: {
                  callType: CallType.VideoConference,
                  interpreterRequired: state.interpreterRequired,
                },
              });
            }
          }
          // stateDispatch({ type: "setIsNotBusy" });
          stateDispatch({ type: "reset" });
          setStartDate(moment().format('YYYY-MM-DD'))
          setEndDate(moment().format('YYYY-MM-DD'))
          setStartTime(moment().format('HH:mm'))
          setEndTime(moment().format('HH:mm'))
        })
        .catch((e) => {
          toast.error("Room Creation Failed! Scheduled time is passed or is overlapped with other schedules.");
          stateDispatch({ type: "setIsNotBusy" });
        });
    }
  };

  const { data: conferenceListData } = useSWR<any>(
    tableType === "conference" ? videoSchedulerUrl : null,
    () =>
      AxiosAuth.get<ApiResponse<any>>(videoSchedulerUrl)
        .then((r) => r.data.data)
        .catch((e) => {
          console.error(e);
          return e;
        })
  );

  const conferenceList = React.useMemo(() => {
    const data = Array.isArray(conferenceListData?.data) ? conferenceListData?.data : [];
    return data;
  }, [conferenceListData]);

  const conferenceListCount = React.useMemo(() => {
    const data = conferenceListData?.count ? conferenceListData?.count : 0;
    return data;
  }, [conferenceListData]);

  return {
    conferenceList,
    conferenceListCount,
    generateButtonDisable,
    setGenerateButtonDisable,
    state,
    setRoomId,
    setContactInfo,
    setConferenceType,
    setStartDate,
    setEndDate,
    setStartTime,
    setEndTime,
    setInterpreterRequired,
    setLanguage,
    setLanguageName,
    setSkill,
    setGender,
    setDepartment,
    setLocation,
    setCustomFields,
    setTimeZone,
    onCreateSchedule,
  };
}
export function useFunctionalityVideoConferenceCall(setLoadingForStartCall?: any) {
  const videoCallTracks = useAppSelector((state: VRIAppStateType) => state.videoCall.tracks);
  const history = useHistory();
  const dispatch = useDispatch();
  const onCreateScheduleCall = (roomId: string, assignedOperatorId: string) => {
    const creteCallUrl = VriApi.Conference.CreateScheduleConferenceCall(roomId);
    AxiosAuth.post(creteCallUrl)
      .then((r) => {
        if (r.status === AppConstants.Api.StatusOK) {
          toast.success("Room Creation Successful");
          dispatch(
            ActionVideoCall.SetCallStatus({
              prev: CallStatusType.Received,
              current: CallStatusType.Established,
            })
          )
          dispatch(ActionUi.SetPreviousRoute(history.location.pathname));
          dispatch(ActionUi.SetRoomId(roomId));
          history.push({
            pathname: AppRouteUi.VideoConference.Room.LoadWithInterPretor(
              roomId,
              assignedOperatorId
            ),
            state: {
              callType: CallType.VideoConference,
              interpreterRequired: true,
            },
          });
          setLoadingForStartCall(false)
        }

      })
      .catch((error) => {
        toast.error("Room Creation Failed");
        setLoadingForStartCall(false)
        UserMediaUtils.stopAllMedia();
        videoCallTracks && dispatch(ActionVideoCall.setTracks(null));
        logger({message: 'Scheduled Room Creation Failed', data: error});
      });
  };
  const onCreateRoom = (roomId: string, interpreterRequired: boolean = false) => {
    const createRoom = VriApi.Room.CreateRoom(roomId);
    AxiosAuth.get(createRoom)
      .then((r) => {
        if (r.status === AppConstants.Api.StatusOK) {
          toast.success("Room Creation Successful");
          dispatch(ActionUi.SetPreviousRoute(history.location.pathname));
          dispatch(ActionUi.SetRoomId(roomId));
          dispatch(
            ActionVideoCall.SetCallStatus({
              prev: CallStatusType.Received,
              current: CallStatusType.Established,
            })
          )
          history.push({
            pathname: AppRouteUi.VideoConference.Room.Load(
              roomId,
              interpreterRequired
            ),
            state: {
              callType: CallType.VideoConference,
              interpreterRequired: interpreterRequired,
            },
          });
          setLoadingForStartCall(false)
        }

      })
      .catch((error) => {
        toast.error("Room Creation Failed");
        setLoadingForStartCall(false)
        logger({message: 'Room Creation Failed', data: error});
        UserMediaUtils.stopAllMedia();
        videoCallTracks && dispatch(ActionVideoCall.setTracks(null));
      });
  };
  return {
    onCreateScheduleCall,
    onCreateRoom
  }
}