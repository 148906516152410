import {
  ApiSchemaAvailableManager,
  ApiSchemaCalenderProvider,
  ApiSchemaUserDetailsData,
} from "./../../models/api/userlist";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { toast } from "react-toastify";
import useSWR, { mutate } from "swr";
import {
  AppConstants,
  AppRouteUi,
  PlatformApi,
  topLoaderProgress,
  CalendarProvider,
  VriApi,
} from "../../configs";
import { ActionAuth, AxiosAuth, StorageAuth, useAppSelector } from "../../core";
import {
  ApiResponse,
  ApiSchemaChangeRole,
  ApiSchemaChangeStatus,
  ApiSchemaCompanyByIdData,
  APISchemaCreateUser,
  ApiSchemaProfileData,
  ApiSchemaUpdatePassword,
  ApiSchemaUpdateTin,
  ApiSchemaUpdateUser,
  ApiSchemaUserData,
  ApiSchemaUserProfileData,
  ApiUserNames,
  IGetUserTinById,
  WithPagination,
} from "../../models";
import { OptionMapper, UtilsJQuery } from "../../utils";
import axios, { CancelToken } from "axios";
import { VRIAppStateType } from "../../core";

type Props = {
  companyId: string;
  pageSize?: number;
  pageNo?: number;
  searchText?: string;
  setLoaderProgress?: any;
};
let pagedUserUrl: string;
export function useFunctionalityPagedUser(props: Props) {
  const { searchText, setLoaderProgress, companyId, pageNo, pageSize } = props;
  const [pagedListCount, setPagedListCount] = useState<number>(0);

  pagedUserUrl = PlatformApi.Users.GetAll(
    companyId,
    pageSize,
    pageNo,
    searchText
  );
  const { data: apiUserList } = useSWR<ApiSchemaUserData[]>(
    companyId ? pagedUserUrl : null,
    () => {
      setLoaderProgress(topLoaderProgress.start);
      return AxiosAuth.get<ApiResponse<WithPagination<ApiSchemaUserData[]>>>(
        pagedUserUrl
      )
        .then((r) => {
          setLoaderProgress(topLoaderProgress.complete);
          setPagedListCount(r.data.data?.count);
          return r.data.data.data;
        })
        .catch((e) => {
          setLoaderProgress(topLoaderProgress.complete);
          console.error(e);
          return e;
        });
    }
  );

  const userList = useMemo(() => {
    const data = Array.isArray(apiUserList) ? apiUserList : [];
    return data;
  }, [apiUserList]);

  return {
    userList,
    pagedListCount,
  };
}
type UserProps = {
  userId?: string | "";
  companyId?: string | "";
  isInvoke?: boolean;
};

export function useFunctionalityUserGetUserById(props: UserProps) {
  const { userId, companyId } = props;

  const { data: apiUserData } = useSWR<ApiSchemaUserProfileData>(
    userId ? PlatformApi.Users.GetUserById(userId) : null,
    () =>
      AxiosAuth.get<ApiResponse<ApiSchemaUserProfileData>>(
        PlatformApi.Users.GetUserById(userId || "")
      )
        .then((r) => {
          return r.data.data;
        })
        .catch((e) => {
          console.error(e);
          return e;
        })
  );

  const userData = React.useMemo(() => {
    const data = typeof apiUserData === "object" ? apiUserData : {};
    return data;
  }, [apiUserData]);

  return {
    userData,
  };
}

export function useFunctionalityUserGetNames(props: UserProps) {
  const { userId, companyId } = props;
  const data = useAppSelector((state: VRIAppStateType) => ({
    userRole: state.auth.profile?.userRole,
  }));
  const { data: apiUserNames } = useSWR<ApiUserNames[]>(
    companyId ? PlatformApi.Users.GetNames(companyId) : null,
    () =>
      data.userRole === AppConstants.UserRoles.Operator
        ? AxiosAuth.get(PlatformApi.Users.GetAdminNames(companyId))
          .then((r) => r.data.data)
          .catch((e) => {
            console.error(e);
            return e;
          })
        : AxiosAuth.get(PlatformApi.Users.GetNames(companyId))
          .then((r) => r.data.data)
          .catch((e) => {
            console.error(e);
            return e;
          })
  );

  const userNames = React.useMemo(() => {
    const data = Array.isArray(apiUserNames)
      ? OptionMapper.mapperFunc(apiUserNames)
      : [];
    return data;
  }, [apiUserNames]);

  return {
    userNames,
  };
}

export function useFunctionalityUserGetNamesWithActiveStatus(props: UserProps) {
  const { userId, companyId } = props;
  const data = useAppSelector((state: VRIAppStateType) => ({
    userRole: state.auth.profile?.userRole,
  }));
  const { data: apiUserNames } = useSWR<ApiUserNames[]>(
    companyId ? PlatformApi.Users.GetNames(companyId) : null,
    () =>
      data.userRole === AppConstants.UserRoles.Operator
        ? AxiosAuth.get(PlatformApi.Users.GetAdminNames(companyId))
          .then((r) => r.data.data)
          .catch((e) => {
            console.error(e);
            return e;
          })
        : AxiosAuth.get(PlatformApi.Users.GetNamesWithActiveStatus(companyId))
          .then((r) => r.data.data)
          .catch((e) => {
            console.error(e);
            return e;
          })
  );

  const userNames = React.useMemo(() => {
    const data = Array.isArray(apiUserNames)
      ? OptionMapper.mapperFuncWithActiveStatus(apiUserNames)
      : [];
    return data;
  }, [apiUserNames]);

  return {
    userNames,
  };
}

export function useFunctionalityUserGetNamesForBooking(props: UserProps) {
  const { userId, companyId, isInvoke } = props;

  const { data: apiUserNamesForBooking } = useSWR<ApiUserNames[]>(
    companyId && isInvoke ? PlatformApi.Users.GetNamesForBooking() : null,
    () =>
      AxiosAuth.get(PlatformApi.Users.GetNamesForBooking())
        .then((r) => r.data.data)
        .catch((e) => {
          console.error(e);
          return e;
        })
  );

  const userNamesForBooking = React.useMemo(() => {
    const data = Array.isArray(apiUserNamesForBooking)
      ? OptionMapper.mapperFunc(apiUserNamesForBooking)
      : [];
    return data;
  }, [apiUserNamesForBooking]);

  return {
    userNamesForBooking,
  };
}

export function useFunctionalityUserGetDetailsById(props: UserProps) {
  const { userId, companyId } = props;

  const { data: apiUserDetailsData } = useSWR<ApiSchemaUserDetailsData>(
    userId ? PlatformApi.Users.GetDetailsById(userId) : null,
    () =>
      AxiosAuth.get<ApiResponse<ApiSchemaUserDetailsData>>(
        PlatformApi.Users.GetDetailsById(userId || "")
      )
        .then((r) => {
          return r.data.data;
        })
        .catch((e) => {
          console.error(e);
          return e;
        })
  );

  const userDetailsData = React.useMemo(() => {
    const data =
      typeof apiUserDetailsData === "object" ? apiUserDetailsData : {};
    return data;
  }, [apiUserDetailsData]);

  return {
    userDetailsData,
  };
}

// ########### split it into 4 different hooks avobe ##################
// TODO:
// After clearing unnecessary imports, this hook will be removed

export function useFunctionalityUser(props: UserProps) {
  const { userId, companyId } = props;

  const { data: apiUserData } = useSWR<ApiSchemaUserProfileData>(
    userId ? PlatformApi.Users.GetUserById(userId) : null,
    () =>
      AxiosAuth.get<ApiResponse<ApiSchemaUserProfileData>>(
        PlatformApi.Users.GetUserById(userId || "")
      )
        .then((r) => {
          return r.data.data;
        })
        .catch((e) => {
          console.error(e);
          return e;
        })
  );

  const userData = React.useMemo(() => {
    const data = typeof apiUserData === "object" ? apiUserData : {};
    return data;
  }, [apiUserData]);

  const { data: apiUserNames } = useSWR<ApiUserNames[]>(
    companyId ? PlatformApi.Users.GetNames(companyId) : null,
    () =>
      AxiosAuth.get(PlatformApi.Users.GetNames(companyId))
        .then((r) => r.data.data)
        .catch((e) => {
          console.error(e);
          return e;
        })
  );

  const userNames = React.useMemo(() => {
    const data = Array.isArray(apiUserNames)
      ? OptionMapper.mapperFunc(apiUserNames)
      : [];
    return data;
  }, [apiUserNames]);

  // NOTE: have no use

  // const { data: apiUserNamesForBooking } = useSWR<ApiUserNames[]>(
  //   companyId ? PlatformApi.Users.GetNamesForBooking() : null,
  //   () =>
  //     AxiosAuth.get(PlatformApi.Users.GetNamesForBooking())
  //       .then((r) => r.data.data)
  //       .catch((e) => {
  //         console.error(e);
  //         return e;
  //       })
  // );

  // const userNamesForBooking = React.useMemo(() => {
  //   const data = Array.isArray(apiUserNamesForBooking)
  //     ? OptionMapper.mapperFunc(apiUserNamesForBooking)
  //     : [];
  //   return data;
  // }, [apiUserNamesForBooking]);

  const { data: apiUserDetailsData } = useSWR<ApiSchemaUserDetailsData>(
    userId ? PlatformApi.Users.GetDetailsById(userId) : null,
    () =>
      AxiosAuth.get<ApiResponse<ApiSchemaUserDetailsData>>(
        PlatformApi.Users.GetDetailsById(userId || "")
      )
        .then((r) => {
          return r.data.data;
        })
        .catch((e) => {
          console.error(e);
          return e;
        })
  );

  const userDetailsData = React.useMemo(() => {
    const data =
      typeof apiUserDetailsData === "object" ? apiUserDetailsData : {};
    return data;
  }, [apiUserDetailsData]);

  return {
    userData,
    userNames,
    // userNamesForBooking,
    userDetailsData,
  };
}

export function useFunctionalityUserDetailById() {
  const getUser = useCallback(async (userId: string) => {
    try {
      const res = await AxiosAuth.get<ApiResponse<ApiSchemaUserProfileData>>(
        PlatformApi.Users.GetUserById(userId || "")
      );
      if ((res.status = AppConstants.Api.StatusOK)) {
        return Promise.resolve(res.data.data);
      }
    } catch (e) {
      console.error(e);
      return Promise.reject(e);
    }
    return Promise.resolve(null);
  }, []);

  return {
    getUser,
  };
}

export function useFunctionalityUserConnections() {
  const getConnections = useCallback(async () => {
    try {
      const res = await AxiosAuth.get<ApiResponse<ApiSchemaCalenderProvider[]>>(
        VriApi.Conference.GetCalenderProviders.Root()
      );
      if ((res.status = AppConstants.Api.StatusOK)) {
        return Promise.resolve(res.data.data);
      }
    } catch (e) {
      console.error(e);
      return Promise.reject(e);
    }
    return Promise.resolve(null);
  }, []);

  const connect = useCallback(
    async (ConnectionType: CalendarProvider, code: string) => {
      try {
        const res = await AxiosAuth.post<ApiResponse<any>>(
          VriApi.Conference.ConnectCalendar.Root(),
          { code: code }
        );
        if ((res.status = AppConstants.Api.StatusOK)) {
          toast.success("Connected");
          return Promise.resolve(res.data.isSuccess);
        }
      } catch (e) {
        console.error(e);
        return Promise.reject(e);
      }
      return Promise.resolve(null);
    },
    []
  );

  const disconnect = useCallback(async (ConnectionType: CalendarProvider) => {
    try {
      const res = await AxiosAuth.post<ApiResponse<any>>(
        VriApi.Conference.DisconnectCalendar.Root(ConnectionType)
      );
      if ((res.status = AppConstants.Api.StatusOK)) {
        toast.success("Disconnected");
        return Promise.resolve(res.data.data);
      }
    } catch (e) {
      console.error(e);
      toast.error("Failed");
      return Promise.reject(e);
    }
    return Promise.resolve(null);
  }, []);

  return {
    getConnections,
    connect,
    disconnect,
  };
}

type UserRoleProps = {
  companyId: string | "";
  isInvoke: boolean;
};

export function useFunctionalityCompanyUsers(props: UserRoleProps) {
  const { companyId, isInvoke } = props;

  const { data: apiCompanyUserNames } = useSWR<ApiUserNames[]>(
    companyId && isInvoke
      ? PlatformApi.Users.GetCompanyUserNames(companyId)
      : null,
    () =>
      AxiosAuth.get(PlatformApi.Users.GetCompanyUserNames(companyId))
        .then((r) => r.data.data)
        .catch((e) => {
          console.error(e);
          return e;
        })
  );

  const companyUserNames = React.useMemo(() => {
    const data = Array.isArray(apiCompanyUserNames)
      ? OptionMapper.mapperFunc(apiCompanyUserNames)
      : [];
    return data;
  }, [apiCompanyUserNames]);

  return {
    companyUserNames,
  };
}

export function useFunctionalityUserForExternal(props: { companyId: string }) {
  const { companyId } = props;

  const { data: apiUserNames } = useSWR<ApiUserNames[]>(
    companyId ? PlatformApi.Users.GetNamesForExternalUser(companyId) : null,
    () =>
      AxiosAuth.get(PlatformApi.Users.GetNamesForExternalUser(companyId))
        .then((r) => r.data.data)
        .catch((e) => {
          console.error(e);
          return e;
        })
  );

  const userNames = React.useMemo(() => {
    const data = Array.isArray(apiUserNames)
      ? OptionMapper.mapperFunc(apiUserNames)
      : [];
    return data;
  }, [apiUserNames]);
  return {
    userNames,
  };
}
export function useFunctionalityUser2(props: UserProps) {
  const { userId, companyId } = props;

  const userTinData = useCallback(async (userId: string) => {
    const btnLoading = UtilsJQuery.Ladda(".user-from-submit-btn");
    btnLoading.start?.();

    try {
      const res = await AxiosAuth.get<ApiResponse<string>>(
        PlatformApi.Tin.GetUserTinById(userId || "")
      );

      if ((res.status = AppConstants.Api.StatusOK)) {
        return Promise.resolve(res.data.data);
      }
    } catch (e) {
      console.error(e);
      return Promise.reject(e);
    }

    btnLoading.stop?.();
    return Promise.resolve(null);
  }, []);

  const GetUserStatus = useCallback(async (userId: string) => {
    try {
      const res = await AxiosAuth.get<ApiResponse<string>>(
        PlatformApi.Users.GetUserStatus(userId || "")
      );
      if ((res.status = AppConstants.Api.StatusOK)) {
        return Promise.resolve(res.data.data);
      }
    } catch (e) {
      console.error(e);
      return Promise.reject(e);
    }
    return Promise.resolve(null);
  }, []);

  const onUpdateTin = useCallback(async (form: ApiSchemaUpdateTin) => {
    const btnLoading = UtilsJQuery.Ladda(".user-from-submit-btn");
    btnLoading.start?.();

    try {
      const res = await AxiosAuth.post(
        PlatformApi.Tin.UpdateUserTin(form.userId || "", form.tin || "")
      );

      if ((res.status = AppConstants.Api.StatusOK)) {
        mutate(pagedUserUrl);
        toast.success("Tin changed");
      }
    } catch (e) {
      toast.error("Failed to change tin");
    }

    btnLoading.stop?.();
    return Promise.resolve(null);
  }, []);

  const updateUserDetails = useCallback(async (form: ApiSchemaUpdateUser) => {
    const btnLoading = UtilsJQuery.Ladda(".user-from-submit-btn");
    btnLoading.start?.();
    let response;
    try {
      response = await AxiosAuth.put<ApiResponse<string>>(
        PlatformApi.Users.Update(),
        form
      );
      if ((response.status = AppConstants.Api.StatusOK)) {
        mutate(PlatformApi.Company.GetById(companyId || ""));
        toast.success("Profile update successful");

        if (response.data.data && response.data.data !== "") {
          StorageAuth.AccessToken = response.data.data;
        }

        return Promise.resolve();
      }
    } catch (e) {
      toast.error("Failed to update user details");
      console.log(e);
      return Promise.reject();
    }
    btnLoading.stop?.();
    return response;
  }, []);

  const updateTimeZone = useCallback(async (model: ApiSchemaUpdateUser) => {
    let response;
    const btnLoading = UtilsJQuery.Ladda(".timezone");
    btnLoading.start?.();
    try {
      response = await AxiosAuth.put<ApiResponse<string>>(
        PlatformApi.Users.UpdateTimezone(),
        model
      );
      if ((response.status = AppConstants.Api.StatusOK)) {
        toast.success("Timezone update Successfully");
        return Promise.resolve(response.data.data);
      }
    } catch (e) {
      toast.error("Failed to update Timezone");
      console.log(e);
    }
    btnLoading.stop?.();
    return Promise.resolve(null);
  }, []);

  const onChangeStatus = useCallback(async (form: ApiSchemaChangeStatus) => {
    const btnLoading = UtilsJQuery.Ladda(".user-from-submit-btn");
    btnLoading.start?.();
    try {
      const res = await AxiosAuth.put(PlatformApi.Users.ChangeStatus(), form);
      if ((res.status = AppConstants.Api.StatusOK)) {
        mutate(pagedUserUrl);
        toast.success("Status changed");
      }
    } catch (e) {
      toast.error("Failed to change status");
    }
    btnLoading.stop?.();
    return Promise.resolve(null);
  }, []);

  const onChangeRole = useCallback(async (form: ApiSchemaChangeRole) => {
    const btnLoading = UtilsJQuery.Ladda(".user-from-submit-btn");
    btnLoading.start?.();

    try {
      const res = await AxiosAuth.put(PlatformApi.Users.ChangeRole(), form);

      if ((res.status = AppConstants.Api.StatusOK)) {
        mutate(pagedUserUrl);
        toast.success("Role changed");
      }
    } catch (e) {
      toast.error("Failed to change role");
    }

    btnLoading.stop?.();
    return Promise.resolve(null);
  }, []);

  const onUpdatePassword = useCallback(
    async (form: ApiSchemaUpdatePassword) => {
      const btnLoading = UtilsJQuery.Ladda(".user-from-submit-btn");
      btnLoading.start?.();

      try {
        const res = await AxiosAuth.put(
          PlatformApi.Users.UpdatePassword(),
          form
        );

        if ((res.status = AppConstants.Api.StatusOK)) {
          mutate(pagedUserUrl);
          toast.success("Password Updated");
        }
      } catch (e) {
        toast.error("Failed Updating Password");
      }

      btnLoading.stop?.();
      return Promise.resolve(null);
    },
    []
  );
  const onAddCompanyUser = useCallback(async (form: APISchemaCreateUser) => {
    const btnLoading = UtilsJQuery.Ladda(".company-user-from-submit-btn");
    btnLoading.start?.();
    try {
      const res = await AxiosAuth.post<APISchemaCreateUser>(
        PlatformApi.Users.Create(),
        (form = { ...form, companyId: companyId || "" })
      );
      if (res.status === AppConstants.Api.StatusOK) {
        mutate(pagedUserUrl);
        toast.success("User added successfully");
      }
    } catch (e) {
      toast.error("Adding user failed");
      btnLoading.stop?.();
      return Promise.reject(e);
    }
  }, []);

  const onUpdateCompanyUser = useCallback(async (form: ApiSchemaUpdateUser) => {
    const btnLoading = UtilsJQuery.Ladda(".user-from-submit-btn");
    btnLoading.start?.();

    try {
      const res = await AxiosAuth.put(
        PlatformApi.Users.Update(),
        (form = { ...form, id: userId })
      );

      if ((res.status = AppConstants.Api.StatusOK)) {
        mutate(pagedUserUrl);
        toast.success("User Updated");
      }
    } catch (e) {
      toast.error("Failed User Update");
    }

    btnLoading.stop?.();
    return Promise.resolve(null);
  }, []);
  const getUserStatus = useCallback(async (id: string) => {
    const btnLoading = UtilsJQuery.Ladda(".user-from-submit-btn");
    btnLoading.start?.();
    try {
      const res = await AxiosAuth.get(PlatformApi.Users.GetUserStatus(id));

      if ((res.status = AppConstants.Api.StatusOK)) {
        return Promise.resolve(res.data.data);
      }
    } catch (e) {
      return Promise.reject(null);
    }
    btnLoading.stop?.();
    return Promise.resolve(null);
  }, []);

  const onDeleteUser = useCallback(async (userId: string) => {
    const btnLoading = UtilsJQuery.Ladda(".user-from-submit-btn");
    btnLoading.start?.();

    try {
      const res = await AxiosAuth.delete(
        PlatformApi.Users.Delete(userId || "")
      );

      if ((res.status = AppConstants.Api.StatusOK)) {
        mutate(pagedUserUrl);
        toast.success("User Deleted");
      }
    } catch (e) {
      toast.error("User Delete Failed");
    }

    btnLoading.stop?.();
    return Promise.resolve(null);
  }, []);

  return {
    updateUserDetails,
    onUpdateTin,
    userTinData,
    onChangeStatus,
    onChangeRole,
    onAddCompanyUser,
    onUpdatePassword,
    onUpdateCompanyUser,
    onDeleteUser,
    updateTimeZone,
    getUserStatus,
  };
}

export function useFunctionalityUserProfileInfo(props: UserProps) {
  const userDetailsDataUrl = PlatformApi.Users.GetDetailsById(
    props.userId ?? ""
  );

  const onUserDetailsData = useCallback(async () => {
    try {
      const res = await AxiosAuth.get<ApiResponse<ApiSchemaUserDetailsData>>(
        userDetailsDataUrl
      );

      if (res.status === AppConstants.Api.StatusOK) {
        mutate(userDetailsDataUrl);
      }
      return Promise.resolve(res.data);
    } catch (e) {
      return Promise.reject(null);
    }
  }, []);

  return {
    onUserDetailsData,
  };
}

export function useFunctionalityGetAdminNames() {
  const getAdminNamesUrl = PlatformApi.Users.GetAdminNames();
  const getAdminNames = async () => {
    const res = await AxiosAuth.get<ApiResponse<ApiUserNames[]>>(
      getAdminNamesUrl
    );
    if ((res.status = AppConstants.Api.StatusOK)) {
      return Promise.resolve(res.data);
    }
  };
  return {
    getAdminNames,
  };
}

type ManagerProps = {
  companyId?: string | "";
};

export function useFunctionalityAvailableManagers(props: ManagerProps) {
  const { companyId } = props;
  const availableManagerUrl = PlatformApi.Users.GetAvailableManager(
    companyId ?? ""
  );

  const onAvailableManager = useCallback(async () => {
    try {
      const res = await AxiosAuth.get<ApiResponse<ApiSchemaAvailableManager[]>>(
        availableManagerUrl
      );

      if (res.status === AppConstants.Api.StatusOK) {
        return Promise.resolve(res.data ?? []);
      }
      // return Promise.resolve(res.data);
    } catch (e) {
      return Promise.reject(null);
    }
  }, []);

  return {
    onAvailableManager,
  };
}

export function useFunctionalityContactUsers() {
  const onContactUsers = useCallback(async (companyId: string) => {
    const contactUsersUrl = PlatformApi.Users.GetAll(companyId ?? "", 200, 1);

    try {
      const res = await AxiosAuth.get<
        ApiResponse<WithPagination<ApiSchemaUserData[]>>
      >(contactUsersUrl);

      if (res.status === AppConstants.Api.StatusOK) {
        return Promise.resolve(res?.data?.data?.data ?? []);
      }
    } catch (e) {
      return Promise.reject(null);
    }
  }, []);

  return {
    onContactUsers,
  };
}

export function useGetUserTin(userId: string, callAPI: boolean) {
  const [tinData, setTinData] = useState<string | null>('');
  const isTinFetching = tinData === '';

  useEffect(() => {
    if (!callAPI || (!userId && callAPI)) {
      return;
    }
    let mounted = true;
    const source = axios.CancelToken.source();
    const getTin = async () => {
      try {
        const response = await AxiosAuth.get<IGetUserTinById>(
          PlatformApi.Tin.GetUserTinById(userId),
          { cancelToken: source.token }
        );
        mounted && setTinData(response.data.data);
      } catch (error) {
        if(!axios.isCancel(error)) {
          console.error(error);
        }
        mounted && setTinData(null);
      }
    };

    getTin();

    return () => {
      mounted = false;
      source.cancel('Get User TIN request canceled');
    };
  }, [userId, callAPI]);

  return {tinData, isTinFetching};
}
