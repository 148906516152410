import React, { useEffect } from "react";
import { ApiSchemaCompanyByIdData, CompanyAppointmentDescriptionRequestModel, CompanyAppointmentDescriptionResponseModel, } from "../../../models";
import { useFunctionalityAppointmentDescriptionTab, useFunctionalityCompany } from "../../../pages/hooks";
import { useState } from "react";
import { AppConstants, AppRouteUi } from "../../../configs";
import { useAppSelector } from "../../../core";
import LoadingBar from "react-top-loading-bar";
import { NoDataComponent } from "../../admin/noDataComponent";
import { AppointmentDescriptionResponseModel } from "../../../models/api/modality";
import { mutate } from "swr";
import { VRIAppStateType } from "../../../core";

type Props = {
  companyProfileData?: ApiSchemaCompanyByIdData;
  editable: boolean;
};


export function AppointmentDescriptionTab({ editable }: Props) {
  const [loaderProgress, setLoaderProgress] = useState(0);
  const companyId = AppRouteUi.Company.Profile.useParam();
  const resellerId = AppRouteUi.Reseller.Profile.useParam();
  const [onUpdateSuccess, setOnUpdateSuccess] = useState(false);
  const userRole = useAppSelector((state: VRIAppStateType) => state.auth.profile?.userRole);
  const [companyAppointmentDescriptionDetails, setCompanyAppointmentDescriptionDetails] = useState<AppointmentDescriptionResponseModel[]>();
  const [isLoadingAppointmentDescription, setIsLoadingAppointmentDescription] = useState<boolean>(false);

  const { LoadAppointmentDescription, pagedAppointmentDescriptionUrl } = useFunctionalityAppointmentDescriptionTab({
    CompanyId: companyId ? companyId : resellerId,
    setLoaderProgress,
  });
  useEffect(() => {
    LoadAppointmentDescription().then((data) => {
      setCompanyAppointmentDescriptionDetails(data);
      setIsLoadingAppointmentDescription(true);
    });
  }, [onUpdateSuccess])

  const companyAppointmentDescriptions: AppointmentDescriptionResponseModel[] =
    companyAppointmentDescriptionDetails || [];
  const { onUpdateCompanyAppointmentDescription } = useFunctionalityCompany({
    companyId: companyId ? companyId : resellerId,
  });
  const checkIsAllEnabled = () => {
    const notAllAppointmentDescriptionsEnabled = companyAppointmentDescriptions.find((x) => x.status === false);
    if (notAllAppointmentDescriptionsEnabled) return false;
    else return true;
  };
  let isAll: boolean = checkIsAllEnabled();
  useEffect(() => {
  }, [onUpdateSuccess]);

  const onAppointmentDescriptionToggleHandler = (appointmentDescription: AppointmentDescriptionResponseModel) => {
    appointmentDescription = { ...appointmentDescription, status: !appointmentDescription.status };
    const body: CompanyAppointmentDescriptionRequestModel = {
      appointmentDescriptionId: appointmentDescription.id,
      status: appointmentDescription.status,
    };
    onUpdateCompanyAppointmentDescription(companyId ? companyId : resellerId, body)?.then(() => {
      setOnUpdateSuccess((prev) => !prev);
      mutate(pagedAppointmentDescriptionUrl)
    });
  };
  const onAllUpdateToggle = () => {
    isAll = !isAll;
    const body: CompanyAppointmentDescriptionRequestModel = {
      isAll: true,
      status: isAll
    };
    onUpdateCompanyAppointmentDescription(companyId ? companyId : resellerId, body)?.then(() => {
      setOnUpdateSuccess((prev) => !prev);
      mutate(pagedAppointmentDescriptionUrl)
    });
  };

  const showAppointmentDescriptionName = (appointmentDescription: CompanyAppointmentDescriptionResponseModel) => {
    if (userRole === AppConstants.UserRoles.CompanyAdmin) {
      if (appointmentDescription?.status) return true;
      return false;
    } else {
      return true;
    }
  }
  return (
    <>
      <LoadingBar
        progress={loaderProgress}
        onLoaderFinished={() => setLoaderProgress(0)}
      />
      {
        !isLoadingAppointmentDescription ? <NoDataComponent title="Loading..." /> :
          <>
            {
              companyAppointmentDescriptionDetails?.length! > 0 ?
                <div className="pt-3 pb-3 pb-sm-0 ">
                  <div className="component-card mb-3">
                    <div className="component-card-header d-flex align-items-center justify-content-between border-top border-left border-right flex-wrap">
                      <div className="col">{editable ? 'Add AppointmentDescriptions to this Company profile-VRI & OPI' : 'Available appointmentDescriptions for VRI&OPI'} </div>
                      {(companyAppointmentDescriptionDetails
                        && companyAppointmentDescriptionDetails?.length > 0
                        && editable) && (
                          <div className="col text-right">
                            <button
                              type="button"
                              className={checkIsAllEnabled() ? "btn btn-toggle active" : "btn btn-toggle"}
                              onClick={() => {
                                onAllUpdateToggle();
                              }}
                            >
                              <div className="handle"></div>
                            </button>
                            <span className="toggle-button-label">All</span>
                          </div>
                        )}
                    </div>
                    <div className="component-card-body border">
                      <div className="row flex-wrap px-3">
                        {companyAppointmentDescriptions?.map((appointmentDescription, index) => (
                          <>
                            {
                              showAppointmentDescriptionName(appointmentDescription) && <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-4 px-0 my-3" key={appointmentDescription.id}>
                                {editable && (
                                  <button
                                    type="button"
                                    className={
                                      appointmentDescription?.status ? "btn btn-toggle active" : "btn btn-toggle"
                                    }
                                    onClick={() => {
                                      onAppointmentDescriptionToggleHandler(appointmentDescription);
                                    }}
                                  >
                                    <div className="handle"></div>
                                  </button>
                                )}
                                <span className="toggle-button-label">
                                  {appointmentDescription?.appointmentCategoryName}
                                </span>
                              </div>
                            }
                          </>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
                : <NoDataComponent title="No Data Found" />
            }
          </>
      }
    </>
  );
}
