import React, { useCallback, useMemo, useState } from "react";
import { toast } from "react-toastify";
import useSWR, { mutate } from "swr";
import { AppConstants, PlatformApi, topLoaderProgress } from "../../configs";
import { AxiosAuth, useAppSelector } from "../../core";
import { ApiResponse, ApiSchemaSkillData, WithPagination } from "../../models";
import { OptionMapper, UtilsJQuery } from "../../utils";
import { VRIAppStateType } from "../../core";

type Props = {
  pageSize?: number;
  pageNo?: number;
  searchText?: string;
  setLoaderProgress: any;
  CompanyId?: string
};

let pagedSkillUrl: string;

export function useFunctionalityPagedSkill(props: Props) {
  const { CompanyId, pageSize, pageNo, searchText, setLoaderProgress } = props;
  const [pagedSkillListCount, setPagedSkillListCount] = useState(0);

  pagedSkillUrl = PlatformApi.Skill.PagedGetAll(CompanyId, pageSize, pageNo, searchText);

  const { data: apiPagedSkillData, error } = useSWR<ApiSchemaSkillData[]>(
    pagedSkillUrl,
    () => {
      setLoaderProgress(topLoaderProgress.start);
      return AxiosAuth.get<ApiResponse<WithPagination<ApiSchemaSkillData[]>>>(
        pagedSkillUrl
      )
        .then((r) => {
          setLoaderProgress(topLoaderProgress.complete);
          setPagedSkillListCount(r.data.data?.count);
          return r.data.data?.data;
        })
        .catch((e) => {
          setLoaderProgress(topLoaderProgress.complete);
          console.error(e);
          return e;
        });
    }
  );

  const pagedSkillList = React.useMemo(() => {
    const data = Array.isArray(apiPagedSkillData) ? apiPagedSkillData : [];
    return data;
  }, [apiPagedSkillData]);
  return {
    pagedSkillList,
    pagedSkillListCount,
    pagedSkillUrl
  };
}
export function useFunctionalitySkillTab(props: Props) {
  const LoadSkill = useCallback(async () => {
    const { setLoaderProgress, CompanyId } = props;
    pagedSkillUrl = PlatformApi.Skill.SkillTabGetAll(CompanyId);
    try {
      setLoaderProgress(topLoaderProgress.start);
      const res = await AxiosAuth.get<ApiResponse<WithPagination<ApiSchemaSkillData[]>>>(pagedSkillUrl)
      if ((res.status = AppConstants.Api.StatusOK)) {
        setLoaderProgress(topLoaderProgress.complete);
        return Promise.resolve(res.data.data.data);
      }
    }
    catch (e) {
      setLoaderProgress(topLoaderProgress.complete);
      return Promise.reject(e);
    }

  }, []);
  return {
    LoadSkill,
    pagedSkillUrl
  }
}
export function useFunctionalitySkill({
  shouldSkillGetAllInvoke = false,
} = {}) {
  const skillUrl = PlatformApi.Skill.GetAll();
  const skillCreateUrl = PlatformApi.Skill.Create();
  const skillDeleteUrl = PlatformApi.Skill.Delete();

  const isLoggedIn = useAppSelector((state: VRIAppStateType) => state.auth.isLoggedIn);

  const { data: apiSkillList } = useSWR<ApiSchemaSkillData[]>(
    isLoggedIn && shouldSkillGetAllInvoke ? skillUrl : null,
    () =>
      AxiosAuth.get<ApiResponse<WithPagination<ApiSchemaSkillData[]>>>(skillUrl)
        .then((r) => r.data.data?.data)
        .catch((e) => {
          console.error(e);
          return e;
        })
  );

  const optionMappedSkillList = React.useMemo(() => {
    const data = Array.isArray(apiSkillList)
      ? OptionMapper.mapperFunc(apiSkillList)
      : [];
    return data;
  }, [apiSkillList]);

  const onAddSkill = React.useCallback(async (form: ApiSchemaSkillData) => {
    const btnLoading = UtilsJQuery.Ladda(".skill-form-submit-btn");
    btnLoading.start?.();

    try {
      const response = await AxiosAuth.post(skillCreateUrl, form);

      if (response.status === AppConstants.Api.StatusOK) {
        mutate(skillUrl);
        mutate(pagedSkillUrl);
        toast.success("Skill added successfully");
      }
    } catch (e) {
      toast.error("Failed to add skill.");
      btnLoading.stop?.();
      return Promise.reject(e);
    }
  }, []);

  const onEditSkill = React.useCallback(
    (editForm: ApiSchemaSkillData, id: string) => {
      const btnLoading = UtilsJQuery.Ladda(".skill-from-submit-btn");
      btnLoading.start?.();

      return AxiosAuth.put(PlatformApi.Skill.Edit(id), editForm)
        .then((response) => {
          btnLoading.stop?.();
          if (response.status === AppConstants.Api.StatusOK) {
            mutate(skillUrl);
            mutate(pagedSkillUrl);
            toast.success("Skill edited successfully");
            return response.data;
          }
        })
        .catch((e) => {
          btnLoading.stop?.();
          toast.error("Failed to edit.");
          return Promise.reject(e);
        });
    },
    []
  );

  const onDeleteSkill = React.useCallback(async (deleteForm: string[]) => {
    const btnLoading = UtilsJQuery.Ladda(".delete-button");
    btnLoading.start?.();
    try {
      const response = await AxiosAuth.delete(skillDeleteUrl, {
        data: deleteForm,
      });

      if (response.status === AppConstants.Api.StatusOK) {
        mutate(skillUrl);
        mutate(pagedSkillUrl);
        toast.success("Skill deleted successfully");
      }
    } catch (e) {
      toast.error("Failed to delete skill.");
      return Promise.reject(e);
    }

    btnLoading.stop?.();
    return Promise.resolve(null);
  }, []);

  return {
    optionMappedSkillList,
    onAddSkill,
    onEditSkill,
    onDeleteSkill,
  };
}

export function useFunctionalitySkillListNames() {
  const onSkillListName = useCallback(async () => {
    const skillUrl = PlatformApi.Skill.GetAll();
    try {
      const res = await AxiosAuth.get<ApiResponse<any>>(skillUrl);

      if (res.status === AppConstants.Api.StatusOK) {
        // mutate(companyProfileDetailsUrl);
      }
      return Promise.resolve(res.data.data);
    } catch (e) {
      return Promise.reject(null);
    }
  }, []);
  return {
    onSkillListName,
  };
}

export function useFunctionalitySkillNameByCompanyId(userId?: string) {
  const isLoggedIn = useAppSelector((state: VRIAppStateType) => state.auth.isLoggedIn);
  const publicLanguageUrl = PlatformApi.Skill.GetAllSkillName(
    userId ?? '',
  );

  const { data: apiGetAllSkillNameData } = useSWR<any[]>(
    isLoggedIn && userId ? publicLanguageUrl : null,
    () =>
      AxiosAuth.get<ApiResponse<any[]>>(
        publicLanguageUrl
      )
        .then((r) =>
          r.data.data !== undefined ? r.data.data : r.data.data
        )
        .catch((e) => {
          console.error(e);
          return e;
        })
  );

  const getAllSkillNameList = useMemo(() => {
    const data = Array.isArray(apiGetAllSkillNameData)
      ? OptionMapper.mapperFunc(apiGetAllSkillNameData)
      : [];
    return data;
  }, [apiGetAllSkillNameData]);

  return {
    getAllSkillNameList,
  };
}

