import { useEffect, useRef, useState } from "react";
import { useHistory } from 'react-router-dom';
import { AppRouteUi, CallStatusType } from "../../configs";
import { useAppDispatch, useAppSelector } from "../../core/Redux/ReduxStore";
import {
  useFunctionalityConferenceRoomInfo
} from "../hooks";
import { toast } from "react-toastify";
import { UserMediaUtils } from "../../utils";
import { ActionVideoCall } from "../../core";
import { VRIAppStateType } from "../../core";

export function CallReturn(): JSX.Element {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const [isRoomExists, setIsRoomExists] = useState<boolean>(true);
  const conferenceId = AppRouteUi.VideoConference.Room.useParam();
  const interval = useRef<any>(null);
  const { isRoomStarted } = useFunctionalityConferenceRoomInfo();
  const appState = useAppSelector((state: VRIAppStateType) => ({
    isBackdatedVersion: state.common.isBackdatedVersion,
    callStatus: state.videoCall.CallStatus,
  }));

  const handleRejoin = async () => {
    dispatch(
      ActionVideoCall.SetCallStatus({
        prev: appState.callStatus?.current || CallStatusType.Zero,
        current: CallStatusType.Zero,
      })
    );
    const tracks = await UserMediaUtils.startMedia();
    if (!tracks) {
      toast.error('Need both camera and microphone access');
    }
    dispatch(ActionVideoCall.setTracks(tracks));
    history.push({
      pathname: AppRouteUi.VideoConference.Room.LoadConsumer(conferenceId),
    });
  };

  useEffect(() => {
    isRoomStarted(conferenceId).then((d) => {
      if (!d?.isRoomCreated) setIsRoomExists(false);
    });
  }, []);
  useEffect(() => {
    interval.current = setInterval(() => {
      isRoomStarted(conferenceId).then((d) => {
        if (!d?.isRoomCreated) setIsRoomExists(false);
      });
    }, 3000);

    return ()=> {
      clearInterval(interval.current);
    }
  }, []);
  useEffect(() => {
    if (!isRoomExists) clearInterval(interval.current);
  }, [isRoomExists]);
  return (
    <>
      <div className="position-fixed h-100 w-100 login-container-bg overflow-auto">
        <div className="container-md container-login-sm h-100 ">
          <div className="d-flex align-items-center  h-100">
            <div className="component-card">
              <div className="component-card-body border border-radius-xl px-4">
                <div className="row">
                  <div className="col-12 py-3  font-sz-18">
                    You are no longer in the meeting!
                  </div>
                  <div className="col-12 py-3 mb-1">
                    <div className="d-flex flex-wrap justify-content-between">
                      {isRoomExists ? (
                        <button
                          className="btn  btn-purple  text-uppercase mr-3 mt-3"
                          onClick={async() => {
                            handleRejoin();
                          }}
                          disabled={appState.isBackdatedVersion}
                        >
                          Rejoin
                        </button>
                      ) : (
                        <p className="p-0 mt-4">Room has been ended</p>
                      )}
                      <button
                        className="btn  btn-link text-purple p-0 mt-3"
                        onClick={() => {
                          history.push({
                            pathname: AppRouteUi.Consumer.Load(),
                          });
                        }}
                      >
                        Return to home screen
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
