import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { ConversationType, DefaultImageType, FileBucketURL } from "../../../../configs";
import { ChannelContext, useAppSelector } from "../../../../core";
import { ApiResponseConversation, ApiResponseParticipant } from "../../../../models";
import { useFunctionalityChat, useFunctionalityImageView, useFunctionalityUser, useFunctionalityUserDetailById } from "../../../../pages/hooks";
import { VRIAppStateType } from "../../../../core";

type Props = {
  selectGroupDetail: boolean,
  setSelectGroupDetail: any,
  arrowclickHandler?: boolean
}
export function ProfileComponent(props: Props): JSX.Element {

  const data = useAppSelector((state: VRIAppStateType) => ({
    userId: state.auth.profile?.id,

  }));
  const { selectGroupDetail, setSelectGroupDetail } = props
  const { selectedChannel, chatId, convoType, currentConvo, setSelectedChannel, setCurrentConvo, setConvoType, setChatType } = React.useContext(ChannelContext);
  const [groupName, setGroupName] = useState(selectedChannel?.friendlyName)
  const { onUpdateGroupName } = useFunctionalityChat()
  const photoParticipantId: string = (convoType === ConversationType.OneOnOne && currentConvo ? currentConvo.participantId : "default")
  const { defaultImageLoad, imageView } = useFunctionalityImageView();
  const { onFetchGroupDetails } = useFunctionalityChat()
  const [groupParticipantList, setGroupParticipantList] = useState<ApiResponseParticipant[]>([])
  const [inCallGroupName, setIncallGroupName] = useState<any>("")
  const { getUser } = useFunctionalityUserDetailById()



  const fetcthUserDetails = (ids: any) => {
    ids.forEach((id: any) => {
      getUser(id)
        .then((res) => {
          setIncallGroupName((prev: any) => (prev += res?.name))
        })
    });


  }

  useEffect(() => {

  }, [inCallGroupName])



  useEffect(() => {
    var participantId: any[] = [];

    if (selectedChannel && data.userId) {
      var participantMap = selectedChannel.participants
      participantMap?.forEach((value: any, key: any) => {
        if (data.userId != value.identity) {
          participantId.push(value.identity)
        }
      })
      fetcthUserDetails(participantId)
    }

  }, [selectedChannel, data.userId])


  useEffect(() => {

  }, [selectedChannel])

  const clickHandler = () => {
    if (!selectedChannel) {
      setChatType(0)
      setCurrentConvo(undefined)
      setConvoType(0)
      setSelectedChannel(undefined)
    }
    else {
      setChatType(1)
      setCurrentConvo(undefined)
      setConvoType(0)
      setSelectedChannel(undefined)
    }
  }

  const handleKeyDown = (event: any) => {
    if (event.key === 'Enter') {
      event.target.blur();
      onUpdateGroupName(chatId, groupName)
    }
  }

  return (
    <>

      <div className="row mx-0 py-2  chat-border bg-chat-backgroundColor align-items-center">


        {!props.arrowclickHandler && <button className="btn btn-link"
          onClick={(e) => {
            e.preventDefault()
            clickHandler()
          }}>
          <FontAwesomeIcon
            icon={faArrowRight}
            className="text-muted"
          />
        </button>
        }
        <div className="col">
          <div className="row align-items-center justify-content-center">
            <div className="header-profile-image d-block ">
              <img
                src={`${FileBucketURL?.Root}${photoParticipantId}?${imageView?.fileTimeStamp}`}
                alt=""
                onError={(ev) => { defaultImageLoad(ev, DefaultImageType.DefaultChat, "?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260") }}
                className="img-fluid"
              />
            </div>
            {!selectGroupDetail ?
              <div className="px-3">
                <p className="d-block mb-0 chat-title-name overflow-clip-ellipsis">
                  {convoType === ConversationType.OneOnOne && currentConvo ? currentConvo.conversationName : convoType === ConversationType.Group ? selectedChannel.friendlyName : inCallGroupName ?? "Chat"}
                </p>
              </div> :
              <div className="col">
                <input className="form-control"
                  defaultValue={selectedChannel.friendlyName}
                  onChange={(e) => setGroupName(e.target.value)}
                  onKeyDown={handleKeyDown}
                >

                </input>
              </div>
            }
            {convoType === ConversationType.Group && <span className="btn icon-menuOpen svg-color" title="edit" onClick={() => { setSelectGroupDetail(!selectGroupDetail) }}>{selectGroupDetail ?

              <svg width="512" height="512" viewBox="0 0 512 512" fill="#fff" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_222_2)">
                  <path d="M256 512C306.632 512 356.127 496.986 398.226 468.856C440.325 440.727 473.137 400.745 492.513 353.967C511.889 307.189 516.959 255.716 507.081 206.057C497.203 156.398 472.822 110.783 437.02 74.9806C401.217 39.1784 355.602 14.7968 305.943 4.91894C256.284 -4.95888 204.811 0.110766 158.033 19.4868C111.255 38.8628 71.2736 71.675 43.1439 113.774C15.0143 155.873 0.000133618 205.368 0.000129192 256C0.073533 323.873 27.0684 388.945 75.0618 436.938C123.055 484.932 188.127 511.927 256 512V512ZM256 42.6666C298.194 42.6666 339.439 55.1784 374.522 78.6198C409.604 102.061 436.948 135.379 453.094 174.361C469.241 213.342 473.466 256.237 465.234 297.619C457.003 339.002 436.685 377.014 406.85 406.849C377.014 436.685 339.002 457.003 297.619 465.234C256.237 473.466 213.343 469.241 174.361 453.094C135.379 436.948 102.061 409.604 78.62 374.522C55.1786 339.439 42.6668 298.193 42.6668 256C42.7289 199.439 65.225 145.213 105.219 105.219C145.214 65.2248 199.44 42.7287 256 42.6666V42.6666Z" />
                  <path d="M256 170.667C271.616 170.651 286.697 176.358 298.389 186.709C305.365 192.917 311.979 198.955 316.309 203.285L377.6 263.531C381.372 267.546 383.445 272.864 383.384 278.372C383.323 283.881 381.134 289.152 377.275 293.083C373.416 297.014 368.185 299.3 362.679 299.462C357.173 299.624 351.817 297.649 347.733 293.952L286.293 233.6C282.283 229.611 276.309 224.192 270.101 218.667C266.204 215.229 261.186 213.332 255.989 213.332C250.793 213.332 245.775 215.229 241.877 218.667C235.691 224.171 229.717 229.589 225.835 233.451L164.267 293.952C162.299 296.047 159.928 297.722 157.296 298.878C154.665 300.033 151.827 300.645 148.953 300.677C146.079 300.709 143.229 300.159 140.572 299.062C137.916 297.964 135.509 296.341 133.495 294.29C131.482 292.24 129.904 289.803 128.855 287.127C127.807 284.451 127.31 281.591 127.394 278.718C127.479 275.845 128.143 273.019 129.347 270.409C130.551 267.799 132.27 265.46 134.4 263.531L195.819 203.179C200.085 198.912 206.635 192.939 213.589 186.773C225.275 176.389 240.367 170.657 256 170.667V170.667Z" />
                </g>
                <defs>
                  <clipPath id="clip0_222_2">
                    <rect width="512" height="512" fill="white" transform="translate(512 512) rotate(-180)" />
                  </clipPath>
                </defs>
              </svg>
              :
              <svg width="512" height="512" viewBox="0 0 512 512" fill="#7b61a8" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_207_2)">
                  <path d="M256 0C205.368 0 155.873 15.0141 113.774 43.1438C71.675 71.2734 38.8629 111.255 19.4868 158.033C0.110799 204.811 -4.95886 256.284 4.91896 305.943C14.7968 355.602 39.1784 401.217 74.9807 437.019C110.783 472.822 156.398 497.203 206.057 507.081C255.716 516.959 307.189 511.889 353.967 492.513C400.745 473.137 440.727 440.325 468.856 398.226C496.986 356.127 512 306.632 512 256C511.927 188.127 484.932 123.055 436.938 75.0618C388.945 27.0683 323.873 0.0734097 256 0V0ZM256 469.333C213.807 469.333 172.561 456.822 137.478 433.38C102.396 409.939 75.0524 376.621 58.9057 337.639C42.759 298.658 38.5343 255.763 46.7658 214.381C54.9973 172.998 75.3153 134.986 105.151 105.151C134.986 75.3154 172.998 54.9973 214.381 46.7658C255.763 38.5343 298.658 42.759 337.639 58.9057C376.621 75.0524 409.939 102.396 433.38 137.478C456.822 172.561 469.333 213.807 469.333 256C469.271 312.56 446.775 366.787 406.781 406.781C366.787 446.775 312.56 469.271 256 469.333V469.333Z" />
                  <path d="M256 341.333C240.384 341.349 225.303 335.642 213.611 325.291C206.635 319.083 200.021 313.045 195.691 308.715L134.4 248.469C130.628 244.454 128.555 239.136 128.616 233.628C128.677 228.119 130.866 222.848 134.725 218.917C138.584 214.986 143.815 212.7 149.321 212.538C154.827 212.376 160.183 214.351 164.267 218.048L225.707 278.4C229.717 282.389 235.691 287.808 241.899 293.333C245.796 296.771 250.814 298.668 256.011 298.668C261.207 298.668 266.225 296.771 270.123 293.333C276.309 287.829 282.283 282.411 286.165 278.549L347.733 218.048C349.701 215.953 352.072 214.278 354.704 213.122C357.335 211.967 360.173 211.355 363.047 211.323C365.921 211.291 368.771 211.841 371.428 212.938C374.084 214.036 376.491 215.659 378.505 217.71C380.518 219.76 382.096 222.197 383.145 224.873C384.193 227.549 384.69 230.409 384.606 233.282C384.521 236.155 383.857 238.981 382.653 241.591C381.449 244.201 379.73 246.54 377.6 248.469L316.181 308.821C311.915 313.088 305.365 319.061 298.411 325.227C286.725 335.611 271.633 341.343 256 341.333V341.333Z" />
                </g>
                <defs>
                  <clipPath id="clip0_207_2">
                    <rect width="512" height="512" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            }
            </span>}
          </div>
        </div>



      </div>

    </>
  );
}
