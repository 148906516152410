import { useDispatch } from "react-redux";
import { ActionAuth, useAppSelector } from "../../../core";
import { ActionUi } from '../../../core/Redux/Slices/sliceUi';
import { VRIAppStateType } from "../../../core";

export default function useFunctionalityMediaPermissions() {

    const localStream = useAppSelector((state: VRIAppStateType) => state.auth.localStream);
    const dispatch = useDispatch();
    const checkMediaPermissions = async () => {
        var constraints = {
            audio: true,
            video: {
                width: { ideal: 4096 },
                height: { ideal: 2160 }
            }
        };
        var permission = false
        stopBothVideoAndAudio();
        await navigator.mediaDevices.getUserMedia(constraints)
            .then(function (stream) {
                dispatch(
                    ActionAuth.setLocalStream(stream)
                );
                let stream_settings = stream.getVideoTracks()[0].getSettings();
                let stream_width = stream_settings.width;
                let stream_height = stream_settings.height;
                dispatch(
                    ActionUi.setCameraWidth(stream_width ?? 1280)
                );
                dispatch(
                    ActionUi.SetCameraheight(stream_height ?? 720)
                );
                if (stream.getVideoTracks().length > 0 && stream.getAudioTracks().length > 0) {
                    //code for when none of the devices are available
                    permission = true
                } else {
                    // code for when both devices are unavailable
                    // return false
                }
            })
            .catch(function (error) {
                // return false
                // code for when there is an error
            });

        return permission
    }

    const checkAudioPermission = async () => {
        var permissionAudio = false
        await navigator.mediaDevices.getUserMedia({ audio: true, video: false })
            .then(function (stream) {
                // localStream = stream
                dispatch(
                    ActionAuth.setLocalStream(stream)
                );

                if (stream.getAudioTracks().length > 0) {
                    //code for when none of the devices are available
                    permissionAudio = true
                } else {

                }
            })
            .catch(function (error) {
                console.log("I am error")
            });

        return permissionAudio
    }

    // stop both mic and camera
    function stopBothVideoAndAudio() {
        if (localStream?.active) {
            localStream?.getTracks().forEach(function (track) {
                track.stop();
            });
        }
    }


    // stop only camera
    function stopVideoOnly() {
        if (localStream) {
            localStream?.getTracks().forEach(function (track) {
                if (track.readyState == 'live' && track.kind === 'video') {
                    track.stop();
                }
            });
        }

    }

    // stop only mic
    function stopAudioOnly() {
        if (localStream) {
            localStream?.getTracks().forEach(function (track) {
                if (track.readyState == 'live' && track.kind === 'audio') {
                    track.stop();
                }
            });
        }

    }

    return {
        checkMediaPermissions,
        checkAudioPermission,
        stopBothVideoAndAudio,
        stopVideoOnly,
        stopAudioOnly,
    }
}

// const [isMediaAllowed, setIsMediaAllowed] = useState<boolean>(false)
//   const permission = async () => {
//     if (await checkMediaPermissions()) {
//       setIsMediaAllowed(true)
//     } else {
//       setIsMediaAllowed(true)
//     }
//   }


//   useEffect(() => {
//     permission()
//   }, [])