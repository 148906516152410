import React, { useEffect, useState } from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { shallowEqual } from 'react-redux';
import { ChatUI } from '.';
import { ConversationType, DefaultImageType } from '../../../configs';
import { FileBucketURL } from '../../../configs/appRoute';
import { ChannelContext, useAppSelector } from '../../../core';
import { ApiSchemaCreateConversation } from '../../../models/api/chat';
import {
  useFunctionalityChat,
  useFunctionalityImageView,
} from '../../../pages/hooks';
import { useFunctionalityUserGetNames } from '../../../pages/hooks/functionalityUser';
import { ContanctUserDetail } from './contactUserDetails';
import { LoadingOverlay } from '../../../components';
import { VRIAppStateType } from "../../../core";

interface contactType {
  searchValue: string;
}

export function AllContactList(props: contactType): JSX.Element {
  const { selectedChannel, setSelectedChannel, setCurrentConvo } =
    React.useContext(ChannelContext);
  const currentUserCompanyId = useAppSelector(
    (state: VRIAppStateType) => state.auth.profile?.companyId
  );
  const { userNames: contactList } = useFunctionalityUserGetNames({
    companyId: currentUserCompanyId,
  });
  const [isBusy, setIsBusy] = useState<boolean>(false);

  const { onCreateConversation } = useFunctionalityChat();
  const currentUser = useAppSelector(
    (state: VRIAppStateType) => ({
      uid: state.auth.profile?.id,
    }),
    shallowEqual
  );

  const showSearchedContacts = (substring: string) => {
    substring = substring.toLowerCase();

    let returningList: any[] = [];

    if (contactList.length > 0) {
      contactList?.map((contact) => {
        if (contact.value !== currentUser.uid) {
          let lowerLabel = contact.label?.toLocaleLowerCase();
          if (lowerLabel?.includes(substring)) {
            returningList.push(contact);
          }
        }
      });
    }

    return returningList;
  };

  useEffect(() => {
    setCurrentConvo(undefined);
    setSelectedChannel(undefined);
  }, []);

  const getChannel = (selectedUserId: string) => {
    setIsBusy(true);
    let contact = contactList.find((o) => o.value === selectedUserId);
    const data: ApiSchemaCreateConversation = {
      conversationName: contact?.label,
      participantIds: [selectedUserId],
      conversationType: ConversationType.OneOnOne,
    };
    onCreateConversation(data, contact?.label).then(() => {
      setIsBusy(false);
    });
  };
  const { defaultImageLoad, imageView } = useFunctionalityImageView();
  const [userId, setUserId] = useState('');

  const hoverHandler = (id: string) => {
    setUserId(id);
  };

  const popover = (
    <Popover id="button-tooltip">
      <ContanctUserDetail userId={userId} />
    </Popover>
  );

  return (
    <>
      <div className="col-12 contacts-chat-list contact-list-height py-2">
        <LoadingOverlay active={isBusy} animation="border">
          {showSearchedContacts(props.searchValue)?.map((user) => (
            <div
              key={user.value}
              className="row contacts-chat-list-item align-items-center pb-1 "
              onClick={() => {
                getChannel(user.value);
              }}
              onMouseOver={() => hoverHandler(user.value)}
            >
              {selectedChannel ? <ChatUI /> : null}
              <div className="header-profile-image incoming-chat-image col cursor-pointer ">
                <OverlayTrigger
                  placement="right"
                  delay={{ show: 250, hide: 400 }}
                  overlay={popover}
                >
                  <img
                    src={`${FileBucketURL?.Root}${user.value}?${imageView?.fileTimeStamp}`}
                    alt=""
                    onError={(ev) => {
                      defaultImageLoad(
                        ev,
                        DefaultImageType.UserProfile,
                        '?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260'
                      );
                    }}
                    className="img-fluid "
                  />
                </OverlayTrigger>
              </div>

              <div className="col col-contact-name">
                <div className="row">
                  <div className="col-12 pl-0">
                    <p className="mb-0 text-truncate cursor-pointer">
                      {user.label}
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-12">
                <div className="row">
                  <div className="empty-image-width"></div>
                  <div className="col">
                    <div className="border-bottom row mt-1"></div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </LoadingOverlay>
      </div>
    </>
  );
}
