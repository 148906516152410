import moment from "moment";
import React from "react";
import { DefaultImageType, FileBucketURL } from "../../../../configs";
import { useAppSelector } from "../../../../core";
import { useFunctionalityImageView } from "../../../../pages/hooks";
import { VRIAppStateType } from "../../../../core";

export function IncomingText(props: any): JSX.Element {
  const { defaultImageLoad, imageView, } = useFunctionalityImageView();
  const data = useAppSelector(
    (state: VRIAppStateType) => ({
      timeZone: state.auth.profile?.timeZone
    }),
  );
  return (
    <div className="">
      <div className="d-flex mb-2">
        <div className="incoming-chat-image mx-2 h-0" style={{width: '35px'}}/>
        <div className="chat-time text-muted">
          <span className="mr-1 b">
            {" "}
            <strong> {props.authorName} {" "}</strong>
          </span>
          <span className="mr-1">
            {/* {props?.message?.dateCreated.toString().split("GMT")[0]} */}
            {moment(props?.message?.dateCreated.toLocaleString("en-US", {timeZone: data.timeZone})).format('LL, HH:mm:ss')}
          </span>
        </div>
      </div>
      <div className=" d-flex  incoming-chat-bubble mt-2 mb-1">
        <div className="incoming-chat-image mx-2">
          <img
            src={`${FileBucketURL?.Root}${props.author}?${imageView?.fileTimeStamp}`}
            alt="user-image"
            onError={(ev) => {
              defaultImageLoad(
                ev,
                DefaultImageType.UserProfile,
                '?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260'
              );
            }}
            className="img-fluid"
          />
        </div>
        <div className="incoming-chat-bubble-message col mr-2">
          <div className="d-flex align-items-center h-100">
            <p className="mb-0">{props?.message?.body}</p>
          </div>
        </div>
      </div>
    </div>
  );
}
