import React, { useEffect, useState } from "react";
import { useLocation, Route } from 'react-router-dom';
import { useFunctionalitySessionMaps } from "../hooks/functionalityGetSessionMaps";
import VideoAttach from '../../features/video/video-attach';
import Video from '../../features/video/video';
import VideoSingle from '../../features/video/video-single';
import { useZoomVideo } from "../../components/VideoProvider/useZoomVideo/useZoomVideo";
import { getExploreName } from "../../utils/platform";
import { SessionMapsResponseModel } from "../../models/api/Call";

import ZoomMediaContext from '../../contexts/media-context';

import { useZoomCall } from '../../contexts/zoomCall-context';

export const VideoGuestPage = () => {

  //we are just checking if a parameter of type call has been passed. The actual API call is happening inside of zoomCall-context
  const searchParams = new URLSearchParams(useLocation().search);
  const guid = searchParams.get("call");

  //keep in mind that the loading passed by useZoomCall (which is zoomcallcontext) covers both the loading of the Zoom call setup itself, and the loading of the api request
  const { loading, loadingText, isSupportGalleryView, galleryViewWithAttach, mediaContext, sessionMapsData } = useZoomCall();



  return (
    <>
      {!guid || !sessionMapsData ? (
        <div className="site-call-page ">
          <div className="site-call-page-wrapper">
            <div className="container-fluid h-100">
              <div className="row  justify-content-center align-items-center h-100 Page-call overflow-auto">
                <div className="col-12 text-center d-flex flex-column align-items-center justify-content-center pt-3">
                  <h3 className="text-white">The call you are trying to access doesn't exist</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (<>
        {
          loading ? (
            <div className="site-call-page ">
              <div className="site-call-page-wrapper">
                <div className="container-fluid h-100">
                  <div className="row  justify-content-center align-items-center h-100 Page-call overflow-auto">
                    <div className="col-12 text-center d-flex flex-column align-items-center justify-content-center pt-3">
                      <p className="text-white">Connecting...</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <ZoomMediaContext.Provider value={mediaContext}>
              <Route
                path="/room/:id"
                component={isSupportGalleryView ? (galleryViewWithAttach ? VideoAttach : Video) : VideoSingle}
              // component={VideoSingle}
              />
            </ZoomMediaContext.Provider>
          )
        }
      </>
      )}

    </>
  );
};
