import React, { useState, useEffect, useRef } from 'react';
import { Client as ConversationsClient } from '@twilio/conversations';
import { useAppSelector } from '../Redux';
import { shallowEqual } from 'react-redux';
import { ConversationType, FileBucketURL } from '../../configs';
import { ApiResponseConversation } from '../../models';
import { AssetsAudio } from '../../assets';
import {
  useFunctionalityImageView,
  useFunctionalityUserDetailById,
} from '../../pages/hooks';
import { isElectron } from '../../utils/isElectron';
import { NotificationUtils, logger } from '../../utils';
import { VRIAppStateType } from "../../core";

export const ChannelContext = React.createContext<any>({});

export function ChannelContextProvider(
  props: React.PropsWithChildren<{}>
): JSX.Element {
  const [videoChatMessageCount, setVideoChatMessageCount] = useState(0);
  const [newChatMessageCount, setNewChatMessageCount] = useState(0);
  const [selectedChannel, setSelectedChannel] = React.useState<any>();
  const [lastMessages, setLastMessages] = useState<any>([]);
  const [channels, setChannels] = useState<any>([]);
  const [chatClient, setChatClient] = useState<any>();
  const [conversationsClient, setConversationsClient] =
    useState<ConversationsClient>();
  const [screenSharedTrack, setScreenSharedTrack] = useState<any>();
  const [currentScreenPublication, setCurrentScreenPublication] =
    useState<any>();
  const [chatId, setChatId] = useState<string>('');
  //@ts-ignore
  const [convoType, setConvoType] = useState<ConversationType>(0);
  const [currentConvo, setCurrentConvo] = useState<ApiResponseConversation>();
  const [isBusy, setIsBusy] = useState(true);
  const [messageAdded, setMsessageAdded] = useState<any>(0);
  const [chatType, setChatType] = React.useState<number>(0);
  var localStream: any;
  const channelRef = useRef<any>();
  const data = useAppSelector(
    (state: VRIAppStateType) => ({
      VRItoken: state.auth.VRItoken,
      callerId: state.auth.profile?.id,
    }),
    shallowEqual
  );
  const { getUser } = useFunctionalityUserDetailById();
  const { imageView } = useFunctionalityImageView();

  const loadMessagesFor = React.useCallback((thisChannel: any) => {
    thisChannel
      .getMessages()
      .then((messagePaginator: any) => {
        let lastMessage =
          messagePaginator.items[messagePaginator.items.length - 1];

        setLastMessages((lastMessages: any) => [
          ...lastMessages.filter((x: any) => x !== lastMessage),
          lastMessage,
        ]);

        if (
          lastMessage?.conversation?.channelState?.lastMessage?.index !==
            lastMessage?.conversation?.channelState?.lastReadMessageIndex &&
          lastMessage?.author !== data?.callerId
        ) {
          if (thisChannel === channelRef.current) {
            // setVideoChatMessageCount((count) => count + 1);
          } else {
            showNotif(lastMessage);
            setNewChatMessageCount((count) => count + 1);
          }
        }
      })
      .catch((err: any) => {
        console.error("Couldn't fetch messages IMPLEMENT RETRY", err);
      });
  }, []);

  const loadMessagesForRecentMsgs = React.useCallback((thisChannel: any) => {
    // thisChannel
    //   .getMessages()
    //   .then((messagePaginator: any) => {
    //     let lastMessage =
    //       messagePaginator.items[messagePaginator.items.length - 1];
    //       let lastmsgs =  messagePaginator.items
    //     setLastMessages((lastMessages: any) => [
    //       ...lastMessages.filter((x: any) => x !== lastMessage),
    //       lastMessage,
    //     ]);
    //     if (
    //       lastMessage?.conversation?.channelState?.lastMessage?.index !==
    //       lastMessage?.conversation?.channelState?.lastReadMessageIndex &&
    //       lastMessage?.author !== data?.callerId
    //     ) {
    //       if (thisChannel === channelRef.current) {
    //         setVideoChatMessageCount((count) => count + 1);
    //       } else {
    //       }
    //     }
    //   })
    //   .catch((err: any) => {
    //     console.error("Couldn't fetch messages IMPLEMENT RETRY", err);
    //   });
  }, []);

  //for chat
  useEffect(() => {
    async function CreateConversationClient() {
      if (!!data.VRItoken) {
        let _conversationsClient = await ConversationsClient.create(
          data.VRItoken,
          {
            logLevel: 'error',
          }
        );
        setConversationsClient(_conversationsClient);
      }
    }

    try {
      CreateConversationClient();
    } catch (error) {
      logger({ message: 'Conversation Client Error: ', data: error });
    }
  }, [data.VRItoken]);

  useEffect(() => {}, [conversationsClient]);

  function playSound() {
    new Audio(AssetsAudio.ChatNoti).play().catch((e) => {
      console.error(e);
    });
  }

  useEffect(() => {
    if (newChatMessageCount > 0) playSound();
  }, [newChatMessageCount]);

  const getUserNameAndShowNotif = (
    id: string,
    body: string,
    photoUrl: string
  ) => {
    getUser(id).then((res) => {
      spawnNotification(body, photoUrl, res?.name);
    });
  };

  const showNotif = (lastmessage: any) => {
    console.log('here is last man ', lastmessage);
    let id = lastmessage.state.author;

    let body = lastmessage.state.body;
    let photoUrl = `${FileBucketURL?.Root}${id}?`;
    spawnNotification(body, photoUrl,lastmessage.attributes.gtaSenderName );
    // getUserNameAndShowNotif(id, body, photoUrl);
  };

  function handleNotificationClick () {
    if (isElectron()) {
      let webUrl = window.location.origin;
      window.open(webUrl);
    } else {
      window.focus();
    }
  };

  function spawnNotification(body: string, photoUrl: any, title: any) {
    if (!('Notification' in window) || !Notification) {
      logger({ message: 'This browser does not support desktop notification' });
      return;
    }
    Notification.requestPermission();
    const options = {
      body: body,
      icon: photoUrl,
    };
    NotificationUtils.sendPushNotification({
      title,
      options,
      onClick: handleNotificationClick,
    });
  }



  useEffect(() => {
    const conversationJoined = (channel: any) => {
      // loadMessagesForRecentMsgs(channel);
      // loadMessagesFor(channel)
      setChannels((prevChannels: any) => [
        ...prevChannels.filter((x: any) => x !== channel),
        channel,
      ]);
    };
    const conversationLeft = (channel: any) => {
      setChannels((prevChannels: any) => [
        ...prevChannels.filter((x: any) => x !== channel),
      ]);
    };

    if (!!conversationsClient) {
      conversationsClient.on('conversationJoined', conversationJoined);
      conversationsClient.on('conversationLeft', conversationLeft);
    }
  }, [conversationsClient]);

  useEffect(() => {
    if (!!conversationsClient) {
      conversationsClient.on('messageAdded', function (message: any) {
        setMsessageAdded((count: any) => count + 1);
        loadMessagesFor(message.conversation);
      });

      return;
    }
  }, [conversationsClient]);

  useEffect(() => {
    channelRef.current = selectedChannel;
    // if(selectedChannel){

    // }
  }, [selectedChannel]);

  return (
    <ChannelContext.Provider
      value={{
        chatClient,
        channels,
        lastMessages,
        setLastMessages,
        setVideoChatMessageCount,
        videoChatMessageCount,
        conversationsClient,
        selectedChannel,
        setSelectedChannel,
        screenSharedTrack,
        setScreenSharedTrack,
        currentScreenPublication,
        setCurrentScreenPublication,
        chatId,
        setChatId,
        currentConvo,
        setCurrentConvo,
        setConvoType,
        convoType,
        isBusy,
        setIsBusy,
        setNewChatMessageCount,
        newChatMessageCount,
        messageAdded,
        localStream,
        chatType,
        setChatType,
      }}
    >
      {props.children}
    </ChannelContext.Provider>
  );
}
