import { DatesSetArg, EventClickArg } from "@fullcalendar/core";
import React from "react";
import { useState } from "react";
import LoadingBar from "react-top-loading-bar";
import { AppConstants, BookingSelectOptions, BookingStatusColor, BookingStatusType, topLoaderProgress } from "../../configs";
import { ActionCalender, useAppDispatch, useAppSelector } from "../../core";
import { Calendar, EventData, ModalCreateJob, ModalViewJob } from "../../features";
import { GetAllForCalenderData } from "../../models";
import { useFunctionalityGetAllForCalendar, useFunctionalityJobGetById } from "../hooks";
import Select from "react-select";
import { batch } from "react-redux";
import { VRIAppStateType } from "../../core";

export function PageCalendar(): JSX.Element {

  const userRole = useAppSelector((state: VRIAppStateType) => state.auth.profile?.userRole);
  const showJobModal = useAppSelector((state: VRIAppStateType) => state.calender.showJobModal);
  const selectedJob = useAppSelector((state: VRIAppStateType) => state.calender.selectedJob);
  const dispatch = useAppDispatch();

  const [startDate, setStartDate] = useState<string>()
  const [endDate, setEndDate] = useState<string>()
  const [statusType, setStatusType] = useState<number>(0)

  const [loaderProgress, setLoaderProgress] = useState(0);

  const [show, setShow] = React.useState(false);
  const [modalKey, setModalKey] = useState(Date.now() + '');


  const { getAllForCalendarData, mutateInGetAllForCalendar } = useFunctionalityGetAllForCalendar(startDate!, endDate, statusType)
  const { LoadByJobId } = useFunctionalityJobGetById({ setLoaderProgress });

  const handleChangeDatesSet = (dateInfo: DatesSetArg) => {
    setStartDate(dateInfo.start.toISOString())
    setEndDate(dateInfo.end.toISOString())
  }

  const handleDateClick = (info: any) => {

  };
  const handleCalendarSelectClick = (info: any) => {

  };

  const handleEventClick = (eventInfo: EventClickArg) => {
    if (setLoaderProgress)
      setLoaderProgress(topLoaderProgress.start);
    LoadByJobId(eventInfo.event.id).then((data) => {
      if (setLoaderProgress)
        setLoaderProgress(topLoaderProgress.complete);

      batch(() => {
        dispatch(ActionCalender.SetSelectedJob(data!))
        dispatch(ActionCalender.SetShowJobModal(true))
      })
    });
  }

  const conferenceList: EventData[] = getAllForCalendarData?.data.data.map((calendarData: GetAllForCalenderData) => {
    let backgroundColor;
    switch (calendarData.bookingStatusType) {
      case BookingStatusType.Open:
        backgroundColor = BookingStatusColor.Open
        break;
      case BookingStatusType.Closed:
        backgroundColor = BookingStatusColor.Closed
        break;
      case BookingStatusType.Billed:
        backgroundColor = BookingStatusColor.Billed
        break;
      case BookingStatusType.Cancelled:
        backgroundColor = BookingStatusColor.Cancelled
        break;
      case BookingStatusType.Confirmed:
        backgroundColor = BookingStatusColor.Confirmed
        break;
      case BookingStatusType.Error:
        backgroundColor = BookingStatusColor.Error
        break;
      case BookingStatusType.NoShow:
        backgroundColor = BookingStatusColor.NoShow
        break;
      case BookingStatusType.Offered:
        backgroundColor = BookingStatusColor.Offered
        break;
      case BookingStatusType.Unfulfilled:
        backgroundColor = BookingStatusColor.Unfulfilled
        break;
      case BookingStatusType.Paid:
        backgroundColor = BookingStatusColor.Paid
        break;
      default:
        backgroundColor = 'black'
        break;
    }

    return {
      id: calendarData.bookingId,
      start: calendarData.startTime,
      end: calendarData.endTime,
      title: calendarData.serialNo,
      extendedProps: {
        company: calendarData.company
      },
      backgroundColor: backgroundColor,
      borderColor: backgroundColor,
      display: 'block'
    }
  })

  return (
    <>
      <LoadingBar
        progress={loaderProgress}
        onLoaderFinished={() => setLoaderProgress(0)}
      />
      <h4 className="my-3 font-weight-light">
        Calendar
      </h4>
      <div className="row mb-2">
        <div className="col-4 d-flex mr-auto">
          <div className="flex-shrink-0">
            {
              (userRole !== AppConstants.UserRoles.ResellerFinance && userRole !== AppConstants.UserRoles.Finance && userRole !== AppConstants.UserRoles.Operator) ? (
                <button
                  className="btn btn-primary d-flex align-items-center mr-2"
                  onClick={() => setShow(true)}
                >
                  <span className="flex-shrink-0 mr-2">
                    <svg width="512" height="512" viewBox="0 0 512 512" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g clip-path="url(#clip0_140_2)">
                        <path d="M490.667 234.667H277.333V21.3333C277.333 15.6754 275.086 10.2492 271.085 6.24839C267.084 2.24761 261.658 0 256 0V0C250.342 0 244.916 2.24761 240.915 6.24839C236.914 10.2492 234.667 15.6754 234.667 21.3333V234.667H21.3333C15.6754 234.667 10.2492 236.914 6.24839 240.915C2.24761 244.916 0 250.342 0 256H0C0 261.658 2.24761 267.084 6.24839 271.085C10.2492 275.086 15.6754 277.333 21.3333 277.333H234.667V490.667C234.667 496.325 236.914 501.751 240.915 505.752C244.916 509.752 250.342 512 256 512C261.658 512 267.084 509.752 271.085 505.752C275.086 501.751 277.333 496.325 277.333 490.667V277.333H490.667C496.325 277.333 501.751 275.086 505.752 271.085C509.752 267.084 512 261.658 512 256C512 250.342 509.752 244.916 505.752 240.915C501.751 236.914 496.325 234.667 490.667 234.667Z" fill="black" />
                      </g>
                      <defs>
                        <clipPath id="clip0_140_2">
                          <rect width="512" height="512" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </span>
                  <span>Create Job</span>
                </button>
              ) : null
            }
          </div>
        </div>
        <div className="col-xxl-2 col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12 ml-sm-auto mt-3 mt-sm-0 calender-job-status">
          <Select
            //menuIsOpen={true}
            options={BookingSelectOptions}
            defaultValue={BookingSelectOptions[0]}
            onChange={(value) => setStatusType(value?.value ?? 0)}
            components={{
              IndicatorSeparator: () => null,
            }}
          />
        </div>
      </div>

      <div className="py-2 site-calendar">
        <Calendar
          calendarEvents={conferenceList}
          handleDateClick={handleDateClick}
          handleSelectClick={handleCalendarSelectClick}
          // initialDate={initialDate}
          handleChangeDatesSet={handleChangeDatesSet}
          handleEventClick={handleEventClick}
          whichPage='calendarPage'
        />
      </div>

      {
        selectedJob &&
        <ModalViewJob
          show={showJobModal}
          selectedJob={selectedJob} />
      }

      <ModalCreateJob
        show={show}
        handleShow={(value) => { setShow(value); setModalKey(Date.now() + '') }}
        key={modalKey}
        mutateInGetAllForCalendar={mutateInGetAllForCalendar} />
    </>
  );
}
