import React, { useEffect, useState } from "react";
import { faPlusCircle, } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAppSelector } from "../../core";
import { shallowEqual } from "react-redux";
import { useFunctionalityCallCenterInflow } from "../hooks";
import { ApiSchemaInflowSettings, ApiSchemaLanguagePackData, } from "../../models";
import { ModalInflowLanguagePack } from "../../features/modals/inflowLanguagePackModal";
import { PlatformType, topLoaderProgress } from "../../configs";
import { LanguagePackComponent } from "../../features/Common/Components/LanguagePackComponent";
import LoadingBar from "react-top-loading-bar";
import { VRIAppStateType } from "../../core";

export function PageCallInflow() {
  const [show, setShow] = useState(false);
  const [platformType, setPlatformType] = useState<PlatformType>(0);
  const [inflowStatus, setInflowStatus] = useState<boolean>(false);
  const [inflowSettings, setInflowSettings] = useState<ApiSchemaInflowSettings>(Object);
  const [dataToEdit, setDataToEdit] = React.useState<ApiSchemaLanguagePackData>(Object);
  const [loaderProgress, setLoaderProgress] = useState(0);

  const currentCallCenterInfo = useAppSelector(
    (state: VRIAppStateType) => ({
      callCenterId:
        state.auth.menuSettings?.callCenterSettings.callCenterId || "",
    }),
    shallowEqual
  );
  const onEditHandler = (pack: ApiSchemaLanguagePackData) => {
    setDataToEdit(pack);
    setShow?.(true);
  };

  const { LoadData, onEditCallInflowStatus } = useFunctionalityCallCenterInflow(
    {
      callCenterId: currentCallCenterInfo.callCenterId,
      setLoaderProgress,
    }
  );

  useEffect(() => {
    LoadData().then((d) => {
      if (d?.data) {
        setInflowSettings(d?.data);
        setInflowStatus(d?.data.enabled);
      }
      setLoaderProgress(topLoaderProgress.complete);
    });
  }, []);
  const onInflowStatusToggleHandler = (d: boolean) => {
    setInflowStatus((prev) => !prev);
    onEditCallInflowStatus(!inflowStatus).then(() => { });
  };
  const onCreateHandler = (pack: ApiSchemaLanguagePackData, platfrom: PlatformType) => {
    setDataToEdit(pack);
    setShow?.(true);
    setPlatformType(platfrom);
  };
  return (
    <>
      <LoadingBar
        progress={loaderProgress}
        onLoaderFinished={() => setLoaderProgress(0)}
      />
      <h4 className=" my-3 font-weight-light">
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb bg-transparent p-0">
            <li className="breadcrumb-item ">
              <a href="#" className="font-weight-light">
                Call Center
              </a>
            </li>
            <li
              className="breadcrumb-item font-weight-light active"
              aria-current="page"
            >
              Call Inflow
            </li>
          </ol>
        </nav>
      </h4>
      <div className="row mt-n1">
        <div className="col-12">
          <div className="">
            Enable call inflow to provide service to other call center's calls
          </div>
          <div className="mt-3">
            <button
              type="button"
              className={
                inflowStatus ? "btn btn-toggle active" : "btn btn-toggle"
              }
              onClick={() => {
                onInflowStatusToggleHandler(inflowStatus);
              }}
            >
              <div className="handle"></div>
            </button>
            <span className="toggle-button-label  text-uppercase ">
              Call Inflow
            </span>
          </div>
        </div>
      </div>
      {inflowStatus ? (
        <>
          <div className="row mt-3  pt-2 justify-content-md-between">
            <div className="col-12 col-sm-12 col-md-6 pr-md-2 mainVertical-separator mainHorizontal-separator">
              {inflowSettings.vriPack ? (
                <>
                  <LanguagePackComponent
                    editable={true}
                    editCallBack={onEditHandler}
                    languagePack={inflowSettings.vriPack ?? undefined}
                    languagePackName="Outsource Pack for VRI"
                  />
                </>
              ) : (
                <>
                  <button
                    className="btn btn-primary text-uppercase"
                    onClick={() =>
                      onCreateHandler(
                        {
                          flatRate: 0,
                          name: "Outsource pack for VRI",
                          id: "",
                          languageRates: [],
                        },
                        PlatformType.VRI
                      )
                    }
                  >
                    <FontAwesomeIcon icon={faPlusCircle} className="mr-1" />
                    create VRI pack
                  </button>
                </>
              )}
            </div>

            <div className="col-12 col-sm-12 col-md-6 pl-md-2">
              {inflowSettings.opiPack ? (
                <>
                  <LanguagePackComponent
                    editable={true}
                    editCallBack={onEditHandler}
                    languagePack={inflowSettings.opiPack ?? undefined}
                    languagePackName="Outsource Pack for OPI"
                  />
                </>
              ) : (
                <>
                  <button
                    className="btn btn-primary text-uppercase"
                    onClick={() =>
                      onCreateHandler(
                        {
                          flatRate: 0,
                          name: "Outsource pack for OPI",
                          id: "",
                          languageRates: [],
                        },
                        PlatformType.OPI
                      )
                    }
                  >
                    <FontAwesomeIcon icon={faPlusCircle} className="mr-1" />
                    create OPI pack
                  </button>
                </>
              )}
            </div>
          </div>
          {
            show ?
              <ModalInflowLanguagePack
                show={show}
                setLoaderProgress={setLoaderProgress}
                handleShow={setShow}
                setInflowStatus={setInflowStatus}
                setInflowSettings={setInflowSettings}
                dataToEdit={dataToEdit}
                callCenterId={currentCallCenterInfo.callCenterId}
                platformType={platformType}
              />
              : null
          }
        </>
      ) : (
        <></>
      )}
    </>
  );
}
