import { shallowEqual, useDispatch } from "react-redux";
import { AssetsPng } from "../../assets/png";
import { DefaultImageType } from "../../configs/appConstants";
import { ActionUi, useAppSelector } from "../../core";
import { VRIAppStateType } from "../../core";

export function useFunctionalityImageView() {
  const dispatch = useDispatch();
  const imageView = useAppSelector(
    (state: VRIAppStateType) => ({
      fileTimeStamp:state.ui.fileTimeStamp
    }),
    shallowEqual
  );
  const defaultImageLoad = (event: any,type:DefaultImageType,imageCondition?:string) => {
    imageCondition = imageCondition || '';
      let src="";
      if(type==DefaultImageType.UserProfile)
      {
        src=`${AssetsPng.GenericUser}${imageCondition}`;
      }
      else if(type==DefaultImageType.SideNavProfile)
      {
          src=AssetsPng.GenericUser;
      }
      else if(type==DefaultImageType.DefaultProfile)
      {
          src=`https://images.pexels.com/photos/771742/pexels-photo-771742.jpeg${imageCondition}`;
      }
      else if(type==DefaultImageType.CompanyProfile)
      {
          src=`${AssetsPng.CompanyImage}${imageCondition}`;
      }
      else if(type==DefaultImageType.DefaultCompanyProfile){

        src=`${AssetsPng.DefaultCompanyImage}${imageCondition}`;
      }
      else if(type==DefaultImageType.DefaultChat){
        src=`${AssetsPng.DeFaultChatImage}${imageCondition}`;
      }
    event.target.src =src;
      
  };
  const onUploadComplete = () => {
    dispatch(ActionUi.SetFileTimeStamp(Date.now()));
  };
  return{
    onUploadComplete,
      imageView,
      defaultImageLoad
  }
}
